.inputTypeSelect {
    width: 100%;
}

.ant-select {
    width: 100% !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff !important;
    border: 1px solid #d2d2d7 !important;
    border-radius: 6px !important;
    height: 36px !important;
    padding: 0 12px !important;
    transition: all 0.2s ease !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #86868b !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #0071e3 !important;
    box-shadow: 0 0 0 3px rgba(0, 113, 227, 0.1) !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
    line-height: 36px !important;
    font-size: 14px;
    color: #1d1d1f;
}

.ant-select-selection-placeholder {
    color: #86868b;
    font-size: 14px;
    line-height: 34px;
}

.ant-select-arrow {
    color: #86868b;
}

/* Dropdown styles */
.ant-select-dropdown {
    padding: 4px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.ant-select-item {
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    min-height: 36px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    color: #1d1d1f;
}

.ant-select-item-option-selected {
    color: #0071e3;
    font-weight: 500;
}

.ant-select-item-option-active {
    color: #0071e3;
}

/* Country Region specific styles */
.country-region-container {
    display: flex;
    gap: 16px;
}

.country-region-container .ant-select {
    width: 100%;
}

.country-region-label {
    font-size: 14px;
    color: #1d1d1f;
    margin-bottom: 4px;
    font-weight: 500;
}

/* Hide the default browser outline */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
}

/* Disabled state */
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f7 !important;
    border-color: #d2d2d7 !important;
    color: #86868b !important;
    cursor: not-allowed !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selection-placeholder {
    color: #86868b !important;
} 