@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaRegular";
    src: url("../../assets/fonts/Rambla-Regular.ttf");
}

@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaBold";
    src: url("../../assets/fonts/Rambla-Bold.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.contact-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem;
    font-family: 'ramblaRegular', sans-serif;
}

.contact-hero {
    text-align: center;
    padding: 2rem 0;
}

.contact-hero h1 {
    font-family: 'ramblaBold', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    background: linear-gradient(45deg, #2d3436, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-hero p {
    font-size: 1rem;
    color: #666;
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.contact-card {
    background: #fff;
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.contact-card:hover {
    transform: translateY(-3px);
}

.card-icon {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    color: #007AFF;
}

.contact-card h3 {
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #1d1d1f;
}

.contact-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.contact-info .info-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
}

.info-icon {
    color: #007AFF;
    font-size: 0.9rem;
}

.contact-form-section {
    background: #fff;
    border-radius: 15px;
    padding: 2rem;
    margin-top: 1.5rem;
    box-shadow: 0 10px 30px rgba(0,0,0,0.08), 
                0 5px 15px rgba(0,0,0,0.05);
    transform: translateZ(0);
    backface-visibility: hidden;
}

.contact-form-section h2 {
    font-family: 'ramblaBold', sans-serif;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #1d1d1f;
}

.contact-form-container {
    max-width: 700px;
    margin: 0 auto;
}

/* Form styling */
.contact-form .ant-form-item {
    margin-bottom: 16px;
}

.contact-form .ant-input,
.contact-form .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    transition: all 0.3s ease;
    font-family: 'ramblaRegular', sans-serif;
}

.contact-form .ant-input:focus,
.contact-form .ant-input-affix-wrapper:focus,
.contact-form .ant-input-affix-wrapper-focused {
    border-color: #007AFF;
    box-shadow: 0 0 0 2px rgba(0,122,255,0.1);
}

.contact-form .ant-input-affix-wrapper .ant-input {
    height: auto;
    border: none;
    box-shadow: none;
}

.contact-form textarea.ant-input {
    height: auto;
    min-height: 100px;
    padding: 8px 12px;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.input-icon {
    color: #007AFF;
    font-size: 14px;
}

.submit-button {
    width: 100%;
    height: 40px;
    background: #007AFF;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
    margin-top: 8px;
}

.submit-button:hover {
    background: #0056b3;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0,122,255,0.2);
}

.map-wrapper {
    border-radius: 0;
    margin-top: 0;
    height: 130px;
    padding-bottom: 0.5rem;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
}

.map-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(rgba(0,0,0,0.1), transparent);
}

.map-wrapper iframe {
    height: 100%;
    width: 100%;
    filter: saturate(0.8) contrast(1.1);
}

.contact-card.location {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.location .card-content {
    padding: 1.5rem;
}

.location .card-icon {
    color: #E55A5F;
}

.contact-card.location:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
}

.contact-card.location:hover .map-wrapper iframe {
    filter: saturate(1) contrast(1.1);
}

@media (max-width: 768px) {
    .contact-hero h1 {
        font-size: 2rem;
    }

    .contact-hero p {
        font-size: 0.9rem;
    }

    .form-row {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .contact-form-section {
        padding: 1.5rem 1rem;
    }

    .contact-card {
        padding: 1.25rem;
    }

    .contact-card.location {
        height: 350px;
    }
    
    .location .card-content {
        padding: 1.25rem;
    }
    
    .map-wrapper {
        height: 160px;
    }
}


