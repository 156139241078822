/* JobStatsCard.css */
.job-stats-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: "300px";
    padding: 30px 50px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 50px; 
   
  }
  

  
  .icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .count {
    font-size: 24px;
    color: #333;
  }
  
  /* GeneralTab.css */
  .general-tab {
    padding: 20px;
  }
  
  .job-stats-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .custom-card{
    height:180px
}

  .custom-card .ant-card-head {
    background-color: #0793d9;
    color: #ffffff;
    font-size: 18px;


}

