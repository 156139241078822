.viewJobOpeningListBox {
  width: 100%;
}

.viewJobOpeningListBox h4 {
  font-size: 17px;
  font-weight: bold;
  font-family: 'ramblaBold', sans-serif;
  color: #1d1d1f;
  margin-bottom: 1.5rem;
  letter-spacing: -0.025em;
}

/* Table Styles */
.job-posts-table .ant-table {
  background: transparent;
  font-family: 'ramblaRegular', sans-serif;
}

.job-posts-table .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.job-posts-table .ant-table-thead > tr > th {
  background: transparent;
  border-bottom: 1px solid #e5e5e7;
  color: #1d1d1f;
  font-family: 'ramblaBold', sans-serif;
  padding: 16px;
  font-size: 14px;
  transition: background 0.2s ease;
  white-space: nowrap;
}

.job-posts-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f5f5f7;
  padding: 16px;
  font-size: 14px;
  font-family: 'ramblaRegular', sans-serif;
  transition: background 0.2s ease;
}

.job-posts-table .ant-table-tbody > tr:hover > td {
  background: #f5f5f7;
}

.job-posts-table .ant-table-cell {
  white-space: nowrap;
}

/* Cell content styles */
.table-cell {
  font-size: 14px;
  font-family: 'ramblaRegular', sans-serif;
  color: #1d1d1f;
}

.table-cell.company {
  font-family: 'ramblaBold', sans-serif;
}

.table-cell.title {
  color: #1d1d1f;
}

.table-cell.deadline {
  color: #666;
}

/* Status indicators */
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-family: 'ramblaBold', sans-serif;
}

.status-badge.active {
  background: #e8f5e9;
  color: #1b5e20;
}

.status-badge.inactive {
  background: #fff3e0;
  color: #e65100;
}

/* Action buttons */
.action-button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'ramblaRegular', sans-serif;
}

.action-button:hover {
  background: #f5f5f7;
}

.action-button.review {
  color: #0066cc;
}

.action-button.edit {
  color: #0066cc;
}

.action-button.delete {
  color: #ff3b30;
}

.action-button span {
  margin-left: 4px;
  font-family: 'ramblaRegular', sans-serif;
}

/* Location cell */
.location-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Message styles */
.ant-message {
  font-family: 'ramblaRegular', sans-serif;
}

.ant-message-notice-content {
  font-family: 'ramblaRegular', sans-serif;
}

/* Modal styles */
.ant-modal-title {
  font-family: 'ramblaBold', sans-serif;
}

.ant-modal-content {
  font-family: 'ramblaRegular', sans-serif;
}

.ant-popconfirm {
  font-family: 'ramblaRegular', sans-serif;
}

/* Pagination */
.ant-pagination {
  font-family: 'ramblaRegular', sans-serif;
}

/* Empty state */
.adminAlert {
  font-family: 'ramblaBold', sans-serif;
  text-align: center;
  color: #86868b;
  font-size: 17px;
  line-height: 1.5;
  padding: 2rem;
  background: #f5f5f7;
  border-radius: 12px;
  margin: 1rem 0;
}

/* Responsive design */
@media (max-width: 1200px) {
  .job-posts-table .ant-table-thead > tr > th,
  .job-posts-table .ant-table-tbody > tr > td {
    padding: 12px;
    font-size: 13px;
  }
  
  .table-cell {
    font-size: 13px;
  }
  
  .status-badge {
    font-size: 12px;
    padding: 3px 6px;
  }
}

@media (max-width: 768px) {
  .viewJobOpeningListBox {
  
  }
  
  .viewJobOpeningListBox h4 {
    font-size: 15px;
    margin-bottom: 1rem;
  }
  
  .job-posts-table .ant-table-thead > tr > th,
  .job-posts-table .ant-table-tbody > tr > td {
    padding: 8px;
    font-size: 12px;
  }
  
  .table-cell {
    font-size: 12px;
  }
  
  .status-badge {
    font-size: 11px;
    padding: 2px 4px;
  }
  
  .action-button {
    padding: 6px;
  }
} 