/* Apple-inspired styling for Reports component */
.reports-container {
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 2rem;
}

.unauthorized-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.unauthorized-container h2,
.unauthorized-container h3 {
    color: #1d1d1f;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.unauthorized-container p {
    color: #86868b;
    font-size: 1.1rem;
    text-align: center;
}

.error-message {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
    border-radius: 18px;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.error-message p {
    color: #ff4d4f;
    margin: 0;
    font-size: 0.9rem;
}

.reports-content {
    max-width: 1200px;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.reports-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.reports-icon {
    font-size: 2.5rem;
    color: #0066cc;
}

.reports-header h1 {
    color: #1d1d1f;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
}

.filters-container {
    background: white;
    padding: 1.5rem;
    border-radius: 18px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.date-picker,
.job-select,
.status-select {
    min-width: 200px;
}

.reports-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.report-card {
    background: white;
    padding: 1.5rem;
    border-radius: 18px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.report-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.report-card h3 {
    color: #1d1d1f;
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
}

.report-card p {
    color: #86868b;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
    min-height: 2.7rem;
}

/* Ant Design component overrides */
.reports-content .ant-btn-primary {
    background: #0066cc;
    border-color: #0066cc;
    border-radius: 20px;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}

.reports-content .ant-btn-primary:hover {
    background: #0077ed;
    border-color: #0077ed;
}

.reports-content .ant-btn-primary[disabled],
.reports-content .ant-btn-primary[disabled]:hover {
    background: #f5f5f7;
    border-color: #d2d2d7;
    color: #86868b;
}

.reports-content .ant-select-selector {
    border-radius: 8px !important;
    height: 40px !important;
}

.reports-content .ant-select-selection-item {
    line-height: 38px !important;
}

.reports-content .ant-picker {
    border-radius: 8px;
    height: 40px;
}

.reports-content .table-container {
    background: white;
    padding: 1.5rem;
    border-radius: 18px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-top: 2rem;
}

.reports-content .ant-table {
    background: transparent;
}

.reports-content .ant-table-thead > tr > th {
    background: #f5f5f7;
    color: #1d1d1f;
    font-weight: 500;
}

.reports-content .ant-table-tbody > tr > td {
    color: #1d1d1f;
}

.reports-content .ant-table-tbody > tr:hover > td {
    background: #f5f5f7;
} 