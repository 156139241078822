/* Skills Container */
.skills-container {
    font-family: 'Rambla', sans-serif;
    padding: 1rem;
}

/* Skill Groups */
.skill-group {
    margin-bottom: 2rem;
}

.skill-group:last-child {
    margin-bottom: 0;
}

.skill-group-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.25rem;
}

.skill-group-header h3 {
    font-family: 'ramblaBold', sans-serif;
    color: #056899;
    font-size: 1.1rem;
    margin: 0;
}

.group-icon {
    color: #ecb81f;
    font-size: 1.1rem;
}

.group-count {
    color: #8c8c8c;
    font-size: 0.9rem;
    font-family: 'ramblaRegular', sans-serif;
}

/* Skills Grid */
.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
}

/* Skill Card */
.skill-card {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.skill-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
}

/* Skill Header */
.skill-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
}

.skill-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #f8fafc;
    border-radius: 8px;
    color: #056899;
}

.skill-info {
    flex: 1;
}

.skill-info h4 {
    font-family: 'ramblaBold', sans-serif;
    color: #262626;
    font-size: 0.95rem;
    margin: 0 0 0.25rem 0;
}

.experience-years {
    font-family: 'ramblaRegular', sans-serif;
    color: #8c8c8c;
    font-size: 0.85rem;
}

/* Skill Level */
.skill-level {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
    font-family: 'ramblaRegular', sans-serif;
    margin: 0.5rem 0;
}

/* Skill Description */
.skill-description {
    font-family: 'ramblaRegular', sans-serif;
    color: #4b5563;
    font-size: 0.9rem;
    margin: 0.5rem 0 0 0;
    line-height: 1.4;
}

/* No Data Message */
.no-data-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    color: #8c8c8c;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #ecb81f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .skills-container {
        padding: 0.5rem;
    }

    .skills-grid {
        grid-template-columns: 1fr;
    }

    .skill-group-header {
        margin-bottom: 1rem;
    }
}