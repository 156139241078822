.documents-container {
    font-family: 'ramblaRegular', sans-serif;
    background: #f8fafc;
    min-height: 200px;
}

.documents-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 0;
}

.document-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 140px;
}

.document-card:hover {
    transform: translateY(-2px);
}

.document-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.document-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.document-info {
    flex: 1;
}

.document-info h4 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
}

.document-type {
    color: #6B7280;
    font-size: 0.85rem;
    display: block;
    margin-top: 2px;
    text-transform: uppercase;
}

.document-description {
    color: #4B5563;
    font-size: 0.9rem;
    margin: 8px 0;
    line-height: 1.5;
}

.document-actions {
    display: flex;
    gap: 8px;
    margin-top: 12px;
}

.view-document,
.download-document {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    border-radius: 6px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: opacity 0.2s;
}

.view-document:hover,
.download-document:hover {
    opacity: 0.9;
    text-decoration: none;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.section-header h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.section-icon {
    color: #ecb81f;
}

.section-summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.total-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.no-data-message {
    text-align: center;
    padding: 48px 0;
    color: #6B7280;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #D1D5DB;
}

@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .section-summary {
        width: 100%;
        justify-content: flex-start;
    }

    .documents-grid {
        grid-template-columns: 1fr;
    }

    .document-actions {
        flex-direction: column;
    }
}

.documents-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.document-section {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.document-section-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E5E7EB;
}

.document-section-header h3 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.document-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.cv-icon {
    background: #FEF3C7;
    color: #92400E;
}

.cover-letter-icon {
    background: #E0F2FE;
    color: #0369A1;
}

.other-docs-icon {
    background: #F3F4F6;
    color: #374151;
}

.pdf-container {
    height: 800px;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    overflow: hidden;
}

.additional-documents {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.additional-document-item {
    display: flex;
    gap: 16px;
    padding: 16px;
    background: #F9FAFB;
    border-radius: 8px;
}

.document-preview {
    width: 200px;
    height: 150px;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    overflow: hidden;
}

.document-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.document-info h4 {
    margin: 0 0 12px 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
}

.document-actions {
    display: flex;
    gap: 8px;
    margin-top: auto;
}

.action-button {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: opacity 0.2s;
}

.view-button {
    background: #EFF6FF;
    color: #1D4ED8;
}

.download-button {
    background: #F0FDF4;
    color: #166534;
}

.action-button:hover {
    opacity: 0.9;
    text-decoration: none;
}

@media (max-width: 768px) {
    .additional-document-item {
        flex-direction: column;
    }

    .document-preview {
        width: 100%;
    }
}

.toggle-view-button {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    background: #EFF6FF;
    color: #1D4ED8;
    transition: opacity 0.2s;
}

.toggle-view-button:hover {
    opacity: 0.9;
}

.document-section-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E5E7EB;
}

.pdf-container {
    margin-top: 16px;
    transition: all 0.3s ease;
}

.additional-document-item {
    border: 1px solid #E5E7EB;
    padding: 20px;
    border-radius: 8px;
}

.document-actions {
    display: flex;
    gap: 8px;
    margin-top: 12px;
}

.action-button {
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .document-section-header {
        flex-wrap: wrap;
    }

    .toggle-view-button {
        width: 100%;
        justify-content: center;
        margin-top: 8px;
    }
} 