.notification-card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: start;
    gap: 16px;
    padding: 16px;
    background: #fff;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 12px;
    border: 1px solid rgba(0, 0, 0, 0.06);
}

.notification-card:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
}

.notification-card__icon-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: rgba(0, 113, 227, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.notification-card__icon {
    color: #0071e3;
    font-size: 20px;
}

.notification-card__content {
    min-width: 0;
}

.notification-card__title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 15px;
    line-height: 1.23536;
    font-weight: 600;
    letter-spacing: .007em;
    color: #1d1d1f;
    margin: 0 0 4px;
}

.notification-card__description {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    line-height: 1.42859;
    color: #424245;
    margin: 0 0 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.notification-card__date {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 12px;
    line-height: 1.33337;
    color: #86868b;
    margin: 0;
}

.notification-card__delete {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2px;
}

.notification-card__delete:hover {
    background: rgba(255, 59, 48, 0.08);
}

.notification-card__delete-icon {
    color: #ff3b30;
    font-size: 18px;
}

/* Popconfirm Styles */
.notification-card .ant-popconfirm-title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 15px;
    line-height: 1.23536;
    color: #1d1d1f;
}

.notification-card .ant-popconfirm-description {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    line-height: 1.384615385;
    color: #424245;
}

.notification-card .ant-popconfirm-buttons .ant-btn {
    border-radius: 980px;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    height: 32px;
    padding: 0 16px;
    transition: all 0.2s ease;
}

.notification-card .ant-popconfirm-buttons .ant-btn-primary {
    background: #ff3b30;
    border-color: #ff3b30;
}

.notification-card .ant-popconfirm-buttons .ant-btn-primary:hover {
    background: #ff453a;
    border-color: #ff453a;
    transform: translateY(-1px);
}

.notification-card .ant-popconfirm-buttons .ant-btn-default {
    border-color: rgba(0, 0, 0, 0.1);
}

.notification-card .ant-popconfirm-buttons .ant-btn-default:hover {
    border-color: rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 734px) {
    .notification-card {
        padding: 12px;
        gap: 12px;
    }

    .notification-card__icon-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 16px;
    }

    .notification-card__icon {
        font-size: 18px;
    }

    .notification-card__title {
        font-size: 14px;
    }

    .notification-card__description {
        font-size: 13px;
    }

    .notification-card__date {
        font-size: 11px;
    }
} 