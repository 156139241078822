/* Apple.com inspired styling */
.addJobButton {
    background-color: #0071e3;
    border: none;
    border-radius: 980px;
    color: #fff;
    cursor: pointer;
    font-family: "ramblaBold";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.4;
    min-width: 120px;
    padding: 8px 20px;
    text-align: center;
    transition: all 0.2s ease;
}

.addJobButton:hover {
    background-color: #0077ed;
    transform: scale(1.02);
}

.job-post-modal {
    font-family: "ramblaBold";
    margin-bottom: 2rem;
}

.job-post-modal .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.12);
    max-height: 90vh;
    overflow: hidden;
    padding: 0;
}

.job-post-modal .ant-modal-header {
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 24px;
    position: sticky;
    top: 0;
    z-index: 10;
}

.job-post-modal .ant-modal-title {
    color: #1d1d1f;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.002em;
    line-height: 1.2;
}

.job-post-modal .ant-modal-body {
    background: #fff;
    max-height: calc(90vh - 120px);
    overflow-y: auto;
    padding: 24px;
}

.addJobOpeningBox {
    padding: 0;
}

.addJobOpeningInfoBox {
    max-width: 100%;
}

.form-section {
    margin-bottom: 32px;
    background-color: white;
}

.section-title {
    color: #1d1d1f;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.002em;
    line-height: 1.25;
    margin-bottom: 16px;
}

.section-content {
    background: #f5f5f7;
    border-radius: 8px;
    padding: 20px;
}

.section-content:hover {
    background: #f5f5f7;
}

.compact-form-item {
    margin-bottom: 16px;
}

.compact-form-item:last-child {
    margin-bottom: 0;
}

.ant-form-item-label {
    padding-bottom: 4px;
}

.ant-form-item-label > label {
    color: #1d1d1f;
    font-size: 13px;
    font-weight: 500;
    height: auto;
    letter-spacing: -0.01em;
    line-height: 1.3;
}

/* Input styling */
.ant-input,
.ant-select-selector,
.ant-picker {
    background: #fff !important;
    border: 1px solid #d2d2d7 !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    height: 36px !important;
    letter-spacing: -0.01em !important;
    line-height: 1.4 !important;
    padding: 8px 12px !important;
    transition: all 0.2s ease !important;
}

.ant-select-selector {
    height: 36px !important;
}

.ant-select-selection-search-input {
    height: 34px !important;
}

.ant-select-selection-item {
    line-height: 34px !important;
}

.ant-input:hover,
.ant-select-selector:hover,
.ant-picker:hover {
    border-color: #86868b !important;
}

.ant-input:focus,
.ant-select-selector:focus,
.ant-picker-focused {
    border-color: #0071e3 !important;
    box-shadow: 0 0 0 3px rgba(0, 113, 227, 0.1) !important;
}

/* Textarea specific styling */
.ant-input-textarea {
    border-radius: 6px !important;
}

.ant-input-textarea textarea {
    font-size: 14px !important;
    letter-spacing: -0.01em !important;
    line-height: 1.5 !important;
    min-height: 150px !important;
    padding: 12px !important;
    resize: vertical !important;
}

.ant-input-textarea textarea::placeholder {
    color: #86868b;
}

.form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.form-row:last-child {
    margin-bottom: 0;
}

.form-hint {
    color: #86868b;
    font-size: 12px;
    letter-spacing: -0.01em;
    line-height: 1.33;
    margin-top: 4px;
}

.form-actions {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: saturate(180%) blur(20px);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    left: 0;
    margin-top: 32px;
    padding: 20px 32px;
    position: sticky;
    right: 0;
    width: 100%;
    z-index: 10;
}

.form-actions .addJobButton {
    min-width: 100px;
    padding: 8px 24px;
}

.form-actions .addJobButton:first-child {
    background-color: transparent;
    border: 1px solid #d2d2d7;
    color: #1d1d1f;
}

.form-actions .addJobButton:first-child:hover {
    background-color: #f5f5f7;
    border-color: #86868b;
    transform: none;
}

/* Spinner styling */
.form-actions .css-0 {
    margin: 4px 12px;
}

/* Custom file input styling */
.file-input-wrapper {
    position: relative;
    width: 100%;
}

input[type="file"] {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
}

.file-input-label {
    background: #fff;
    border: 1px solid #d2d2d7;
    border-radius: 6px;
    color: #1d1d1f;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: -0.01em;
    line-height: 1.4;
    padding: 8px 12px;
    transition: all 0.2s ease;
    width: 100%;
}

.file-input-label:hover {
    border-color: #86868b;
}

.file-input-label span {
    margin-left: 8px;
}

/* Modal close button */
.ant-modal-close {
    color: #1d1d1f;
    opacity: 0.5;
    right: 20px;
    top: 20px;
    transition: opacity 0.2s ease;
}

.ant-modal-close:hover {
    color: #1d1d1f;
    opacity: 1;
    transform: none;
}

/* Select dropdown styling */
.ant-select-dropdown {
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(255, 255, 255, 0.98);
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    padding: 4px;
}

.ant-select-item {
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: -0.01em;
    line-height: 1.4;
    margin: 2px;
    padding: 8px 12px !important;
}

.ant-select-item:hover {
    background-color: rgba(0, 113, 227, 0.06) !important;
}

.ant-select-item-option-selected {
    background-color: rgba(0, 113, 227, 0.1) !important;
    color: #0071e3 !important;
    font-weight: 500 !important;
}

/* Date picker styling */
.ant-picker-dropdown {
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(255, 255, 255, 0.98);
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #0071e3 !important;
    border-radius: 4px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #0071e3;
    border-radius: 4px;
}

/* Message styling */
.ant-message-notice-content {
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(255, 255, 255, 0.98);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    font-size: 14px;
    letter-spacing: -0.01em;
    line-height: 1.4;
    padding: 12px 20px;
}

/* Scrollbar styling */
.job-post-modal .ant-modal-body::-webkit-scrollbar {
    width: 8px;
}

.job-post-modal .ant-modal-body::-webkit-scrollbar-track {
    background: transparent;
}

.job-post-modal .ant-modal-body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.job-post-modal .ant-modal-body::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
}

/* Modal footer styling */
.modal-footer {
    align-items: center;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 3px 24px;
}

/* Button styling */
.ant-btn {
    border-radius: 980px;
    font-size: 14px;
    font-family: "ramblaBold";
    font-weight: 500;
    height: 36px;
    letter-spacing: -0.01em;
    line-height: 1.4;
    min-width: 100px;
    padding: 0 20px;
    transition: all 0.2s ease;
}

.ant-btn-primary {
    background: #0071e3;
    border: none;
}

.ant-btn-primary:hover {
    background: #0077ed;
}

.ant-btn-primary:active {
    background: #006edb;
}

.ant-btn-default {
    background: transparent;
    border: 1px solid #d2d2d7;
    color: #1d1d1f;
}

.ant-btn-default:hover {
    background: #f5f5f7;
    border-color: #86868b;
    color: #1d1d1f;
}

.ant-btn-default:active {
    background: #f0f0f3;
    border-color: #86868b;
    color: #1d1d1f;
}

/* Loading state */
.modal-footer .css-0 {
    margin: 0 12px;
}

/* Remove old button styles */
.addJobButton,
.form-actions {
    display: none;
}

/* Button Styles */
.nafasi-job-post__add-button.ant-btn-primary {
    height: 32px;
    padding: 0 14px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    border-radius: 980px;
    background: #0071e3;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    min-width: auto;
}

.nafasi-job-post__add-button.ant-btn-primary:hover {
    background: #0077ED;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nafasi-job-post__add-button.ant-btn-primary:active {
    background: #0062C1;
    transform: translateY(0);
}

/* Modal Footer Styles */
.nafasi-job-post__modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 12px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background: #fff;
    margin: 0;
}

.nafasi-job-post__cancel-button {
    height: 32px;
    padding: 0 14px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #1d1d1f;
    background: #f5f5f7;
    border: none;
    border-radius: 980px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: auto;
}

.nafasi-job-post__cancel-button:hover {
    background: #e5e5e7;
    transform: translateY(-1px);
}

.nafasi-job-post__cancel-button:active {
    background: #d5d5d7;
    transform: translateY(0);
}

.nafasi-job-post__save-button.ant-btn-primary {
    height: 32px;
    padding: 0 14px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    border-radius: 980px;
    background: #0071e3;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    min-width: auto;
}

.nafasi-job-post__save-button.ant-btn-primary:hover {
    background: #0077ED;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nafasi-job-post__save-button.ant-btn-primary:active {
    background: #0062C1;
    transform: translateY(0);
}

.nafasi-job-post__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 14px;
}

/* Modal Styles */
.nafasi-job-post__modal {
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-job-post__modal .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-job-post__modal .ant-modal-header {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin: 0;
}

.nafasi-job-post__modal .ant-modal-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-job-post__modal .ant-modal-body {
    padding: 16px;
}

/* Button Base Styles */
.nafasi-job-post__button {
    height: 32px;
    padding: 0 14px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    border-radius: 980px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

/* Primary Button */
.nafasi-job-post__button--primary {
    background: #0071e3;
    color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.nafasi-job-post__button--primary:hover {
    background: #0077ED;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nafasi-job-post__button--primary:active {
    background: #0062C1;
    transform: translateY(0);
}

/* Secondary Button */
.nafasi-job-post__button--secondary {
    background: #f5f5f7;
    color: #1d1d1f;
}

.nafasi-job-post__button--secondary:hover {
    background: #e5e5e7;
    transform: translateY(-1px);
}

.nafasi-job-post__button--secondary:active {
    background: #d5d5d7;
    transform: translateY(0);
}

/* Modal Footer Styles */
.nafasi-job-post__modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 12px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background: #fff;
    margin: 0;
}

/* Loader */
.nafasi-job-post__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 14px;
}

/* Modal Styles */
.nafasi-job-post__modal {
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-job-post__modal .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-job-post__modal .ant-modal-header {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin: 0;
}

.nafasi-job-post__modal .ant-modal-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-job-post__modal .ant-modal-body {
    padding: 16px;
}

/* Responsive Design */
@media (max-width: 734px) {
    .nafasi-job-post__button {
        height: 30px;
        font-size: 12px;
        padding: 0 10px;
    }

    .nafasi-job-post__modal-footer {
        padding: 10px 12px;
        gap: 6px;
    }

    .nafasi-job-post__modal .ant-modal-header {
        padding: 10px 12px;
    }

    .nafasi-job-post__modal .ant-modal-title {
        font-size: 14px;
    }

    .nafasi-job-post__modal .ant-modal-body {
        padding: 12px;
    }
} 