.nafasi-recruiter-request {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    padding: 0px;
    width: 100%;
    overflow-x: auto;
}

.nafasi-recruiter-request__table {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
    width: 100%;
    min-width: 800px;
}

/* Table Header */
.nafasi-recruiter-request__table :global(.ant-table-thead > tr > th) {
    background-color: #f5f5f7;
    border-bottom: none;
    color: #1d1d1f;
    font-weight: 600;
    padding: 12px 8px;
    white-space: nowrap;
    font-size: 13px;
}

/* Table Rows */
.nafasi-recruiter-request__table :global(.ant-table-tbody > tr > td) {
    border-bottom: 1px solid #f5f5f7;
    padding: 12px 8px;
    transition: background-color 0.2s ease;
}

.nafasi-recruiter-request__table :global(.ant-table-tbody > tr:hover > td) {
    background-color: #f5f5f7;
}

/* Common cell styles */
.nafasi-recruiter-request__company-name,
.nafasi-recruiter-request__location,
.nafasi-recruiter-request__admin-name,
.nafasi-recruiter-request__email,
.nafasi-recruiter-request__phone,
.nafasi-recruiter-request__time {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    color: #1d1d1f;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
    max-width: 100%;
    display: block;
    line-height: 1.4;
}

.nafasi-recruiter-request__company-name {
    font-weight: 500;
    color: #06c;
    font-family: "ramblaBold";
}

.nafasi-recruiter-request__location {
    color: #424245;
}

.nafasi-recruiter-request__admin-name {
    font-weight: 500;
}

.nafasi-recruiter-request__email,
.nafasi-recruiter-request__phone {
    color: #424245;
}

.nafasi-recruiter-request__time {
    color: #86868b;
}

.nafasi-recruiter-request__view {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nafasi-recruiter-request__verified,
.nafasi-recruiter-request__paid {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .nafasi-recruiter-request__table {
        min-width: 700px;
    }
}

@media (max-width: 768px) {
    .nafasi-recruiter-request {
        padding: 0;
        margin: 0;
        border-radius: 8px;
    }

    .nafasi-recruiter-request__table {
        min-width: 600px;
    }

    .nafasi-recruiter-request__table :global(.ant-table-thead > tr > th),
    .nafasi-recruiter-request__table :global(.ant-table-tbody > tr > td) {
        padding: 8px 6px;
    }

    .nafasi-recruiter-request__company-name,
    .nafasi-recruiter-request__location,
    .nafasi-recruiter-request__admin-name,
    .nafasi-recruiter-request__email,
    .nafasi-recruiter-request__phone,
    .nafasi-recruiter-request__time {
        font-size: 12px;
    }
}

/* Pagination */
.nafasi-recruiter-request__pagination {
    margin-top: 20px;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-item) {
    border-radius: 6px;
    border: 1px solid #d2d2d7;
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-item-active) {
    background-color: #06c;
    border-color: #06c;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-item-active a) {
    color: #ffffff;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-prev),
.nafasi-recruiter-request__pagination :global(.ant-pagination-next) {
    border-radius: 6px;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-prev button),
.nafasi-recruiter-request__pagination :global(.ant-pagination-next button) {
    border-radius: 6px;
    border: 1px solid #d2d2d7;
    color: #1d1d1f;
    transition: all 0.2s ease;
}

.nafasi-recruiter-request__pagination :global(.ant-pagination-prev:hover button),
.nafasi-recruiter-request__pagination :global(.ant-pagination-next:hover button) {
    border-color: #06c;
    color: #06c;
} 