.upcoming-interviews-container {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 28px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.upcoming-interviews-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.upcoming-interviews-header h2 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
}

.view-all-button {
    background: none;
    border: none;
    color: #06c;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 980px;
    transition: background-color 0.2s ease;
}

.view-all-button:hover {
    background-color: rgba(0, 102, 204, 0.1);
}

.interviews-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    color: #86868b;
    text-align: center;
}

.empty-icon-wrapper {
    background: rgba(0, 102, 204, 0.1);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.empty-icon-wrapper .calendar-icon {
    color: #06c;
}

.interviews-empty h3 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #1d1d1f;
    margin: 0 0 8px 0;
    font-size: 20px;
}

.interviews-empty p {
    font-size: 15px;
    margin: 0;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.interview-group {
    margin-bottom: 24px;
}

.date-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 8px;
}

.date-label {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    font-weight: 600;
}

.interview-count {
    font-size: 13px;
    color: #86868b;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.interview-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 14px;
    transition: all 0.2s ease;
    cursor: pointer;
    margin: 2px 0;
}

.interview-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.interview-item-icon {
    color: #06c;
    margin-right: 16px;
    width: 32px;
    height: 32px;
    background: rgba(0, 102, 204, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.interview-item-content {
    flex: 1;
}

.interview-item-primary {
    color: #1d1d1f;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.today-badge {
    background-color: #06c;
    color: white;
    padding: 2px 8px;
    border-radius: 980px;
    font-size: 12px;
    font-weight: 500;
}

.interview-item-secondary {
    color: #86868b;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.job-title {
    color: #06c;
}

.time {
    color: #86868b;
}

.dot {
    font-size: 4px;
    line-height: 1;
    margin-top: 2px;
}

.chevron-icon {
    color: #86868b;
    opacity: 0;
    transition: opacity 0.2s ease;
    margin-left: 8px;
}

.interview-item:hover .chevron-icon {
    opacity: 1;
} 