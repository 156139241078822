.pricingContainer {
    margin: 0 auto;
    max-width: 100%;
}

.hero-section {
    position: relative;
    overflow: hidden;
    padding: 4rem 0;
    background: linear-gradient(to bottom, rgba(5, 104, 153, 0.05), transparent);
}

.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.hero-dots {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 180px;
    height: 180px;
    opacity: 0.1;
}

.wave-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0.1;
}

.pricingBox {
    position: relative;
    z-index: 1;
}

.highlight-badge {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: rgba(143, 188, 68, 0.1);
    color: #8FBC44;
    border-radius: 20px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.hero-title {
    font-size: 3.2rem !important;
    color: #056899;
    margin-bottom: 1.5rem;
    font-family: 'ramblaRegular', sans-serif;
    line-height: 1.2;
    letter-spacing: -0.5px;
}

.hero-title .highlight {
    color: #8FBC44;
    position: relative;
    display: inline-block;
}

.hero-title .highlight::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 8px;
    background: rgba(143, 188, 68, 0.2);
    z-index: -1;
    border-radius: 4px;
}

.hero-description {
    font-size: 1.1rem !important;
    line-height: 1.6;
    color: #555;
    margin-bottom: 2rem;
    font-family: 'ramblaRegular', sans-serif;
}

.highlight-number {
    color: #056899;
    font-weight: bold;
    font-size: 1.2rem;
    font-family: 'ramblaRegular', sans-serif;
}

.cta-button {
    background-color: #8FBC44 !important;
    border: none;
    font-family: 'ramblaRegular', sans-serif;
    height: 48px !important;
    padding: 0 2.5rem !important;
    font-size: 1rem !important;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.cta-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255,255,255,0.2), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s ease;
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(143, 188, 68, 0.3);
}

.cta-button:hover::after {
    transform: translateX(100%);
}

.priceContainerDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;
    margin-bottom: 5rem;
    padding-top: 1rem;
}

.pricingBox {
    padding: 2rem;
}

.pricingBox h1 {
    font-size: 2.2rem;
    color: #056899;
    margin-bottom: 1rem;
    font-family: 'ramblaRegular', sans-serif;
    line-height: 1.2;
}

.pricingBox p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 5rem;
}

.pricingImagesBox {
    display: grid;
    gap: 2rem;
    position: relative;
    justify-items: center;
    align-items: center;
    padding: 0 0.5rem
}

.pricingImagesBox img {
    width: 100%;
    max-width: 560px;
    border-radius: 16px;
    transition: all 0.4s ease;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.problem-image {
    animation: floatAnimation 4s ease-in-out infinite;
    transform-origin: center;
}

.solution-image {
    animation: floatAnimation 4s ease-in-out infinite;
    animation-delay: 2s;
    transform-origin: center;
}

.pricingImagesBox img:hover {
    transform: scale(1.05) translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-15px) scale(1.02);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}

.pricingCardsBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin: 2rem 2rem;
    padding: 0 1rem;
}

.pricingCard {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    min-width: 260px;
    max-width: 100%;
}

.pricingCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.pricingCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #056899, #8FBC44);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.pricingCard:hover::before {
    opacity: 1;
}

.card-header {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.card-header h3 {
    font-size: 1.4rem;
    color: #056899;
    margin-bottom: 1rem;
}

.card-header img {
    width: 60px;
    height: 60px;
    margin: 0.5rem 0;
    transition: transform 0.5s ease;
    border-radius: 10px;
    padding: 8px;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.pricingCard:hover .card-header img {
    transform: rotate(360deg);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
}

/* Add a subtle background to the image container */
.card-header::after {
    content: '';
    position: absolute;
    top: 70px;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background: rgba(5, 104, 153, 0.05);
    z-index: 0;
}

.card-header img {
    position: relative;
    z-index: 1;
}

.price-section {
    margin: 1rem 0;
}

.price-section h4 {
    font-size: 2rem;
    color: #056899;
    margin: 0;
}

.price-section h6 {
    font-size: 0.9rem;
    color: #666;
}

.features-list {
    text-align: left;
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

.features-list p {
    display: flex;
    align-items: center;
    gap: 8px;
}

.features-section {
    text-align: center;
    margin: 1rem 0;
}

.features-section h3 {
    font-size: 1.2rem;
    color: #056899;
    margin-bottom: 1rem;
}

.common-features {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: #555;
    background: #f8f9fa;
    padding: 0.5rem 1rem;
    border-radius: 20px;
}

.feature-item svg {
    font-size: 1rem !important;
}

.benefits-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
    border-radius: 12px;
    margin: 3rem auto;
    max-width: 1200px;
}

.benefits-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: 'ramblaBold', sans-serif;
}

.benefits-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 1rem;
}

.benefit-item {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
}

.benefit-item:hover {
    transform: translateY(-5px);
}

.benefit-icon {
    background: rgba(143, 188, 68, 0.1);
    padding: 1rem;
    border-radius: 50%;
    margin: 0 auto 1rem auto;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefit-content {
    flex: 1;
}

.benefit-title {
    color: #2c3e50;
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    font-family: 'ramblaBold', sans-serif;
}

.benefit-description {
    color: #666;
    margin: 0;
    line-height: 1.5;
    font-size: 0.9rem;
    text-align: center;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-n {
    color: #056899;
    font-size: 1.2rem;
}

.nafasi-text {
    color: #ffb400;
    font-size: 1.2rem;
}

.pricing-intro {
    text-align: center;
    padding: 40px 20px 0.5rem 20px;
    margin-bottom: 20px;
}

.pricing-intro h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.003em;
}

.pricing-subtitle {
    font-size: 1.2rem;
    color: #86868b;
    font-family: 'ramblaRegular', sans-serif;
}

.features-section {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 20px;
}

.features-grid {
    display: flex;
    justify-content: center;
}

.feature-column {
    width: 100%;
    max-width: 680px;
}

.feature-column h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
}

.common-features {
    display: grid;
    gap: 16px;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 17px;
}

.feature-icon {
    color: #8FBC44;
}

@media (max-width: 1200px) {
    .pricingCardsBox {
        grid-template-columns: repeat(2, 1fr);
        max-width: 900px;
        margin: 3rem auto;
    }
    .pricingImagesBox img {
        max-width: 500px;
    }
    .benefits-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .priceContainerDetails {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding-top: 0;
    }
    
    .pricingCardsBox {
        grid-template-columns: 1fr;
        max-width: 320px;
        margin: 2rem auto;
    }
    
    .common-features {
        flex-direction: column;
        align-items: center;
    }

    .pricingBox h1 {
        font-size: 1.8rem;
    }

    .benefits-section {
        padding: 1.5rem;
    }

    .pricingImagesBox img {
        max-width: 400px;
    }
    
    .pricingImagesBox {
        gap: 1.5rem;
    }

    .hero-title {
        font-size: 2.4rem !important;
    }

    .hero-description {
        font-size: 1rem !important;
    }

    .hero-section {
        padding: 2rem 0;
    }

    .hero-dots {
        width: 120px;
        height: 120px;
    }

    .benefits-list {
        grid-template-columns: 1fr;
    }
}

.feature-row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 16px;
}

.feature-check {
    padding-top: 0;
    flex-shrink: 0;
}

.feature-text {
    flex: 1;
    line-height: 1.25;
    font-size: 0.9rem;
    color: #4a4a4a;
    font-family: 'ramblaRegular', sans-serif;
    padding-top: 0.5px;
}