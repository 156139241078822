/* Add these at the top of the file to override any global styles */
.nav, 
.navListContainer,
.bodyContainerTopBar,
.adjustedBodyContainerTopBar,
.bodyContainerTopBarLog {
  display: none !important;
}

.main-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f7f9fc;
  position: relative;
  z-index: 1;
}

.sidebar {
  width: 240px;
  background: #ffffff;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.2s ease;
  z-index: 1000;
  border-right: 1px solid #e5e9f2;
  display: flex;
  flex-direction: column;
}

.adjustedSidebar {
  width: 64px;
}

.sidebar-header {
  padding: 16px;
  display: flex;
  align-items: center;
  height: 64px;
}

.sidebar-header img {
  height: 32px;
  width: auto;
  transition: all 0.2s ease;
}

.adjustedSidebar .sidebar-header img {
  transform: scale(0.8);
}

.sidebar-sections {
  flex: 1;
  overflow-y: auto;
  padding: 8px 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sidebar-section {
  margin-bottom: 2px;
}

.section-title {
  font-family: 'ramblaBold', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  padding: 4px 8px;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  border-top: 1px solid #e5e9f2;
  padding-top: 10px;
  min-height: 2px;
}

.adjustedSidebar .section-title {
  color: transparent;
  border-top: 1px solid #e5e9f2;
  margin: 1px 0;
  min-height: 2px;
}

.nav-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 2px;
}

.adjustedSidebar .nav-item {
  margin-bottom: 2px;
}

.nav-link {
  font-family: 'ramblaRegular', sans-serif;
  display: flex;
  align-items: center;
  padding: 6px;
  color: #4b5563;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
}

.nav-link:hover {
  background-color: #f3f4f6;
}

.nav-link.active {
  background-color: #eef2ff;
  color: #4f46e5;
}

.nav-link.active .nav-icon {
  color: #4f46e5;
}

.nav-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  color: #6b7280;
  flex-shrink: 0;
}

.adjustedSidebar .nav-icon {
  margin-right: 0;
}

.nav-text {
  font-family: 'ramblaRegular', sans-serif;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.adjustedSidebar .nav-text {
  display: none;
}

/* Tooltip for collapsed sidebar */
.adjustedSidebar .nav-link {
  position: relative;
  justify-content: center;
}

.adjustedSidebar .nav-link:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  left: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
  background: #1f2937;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'ramblaRegular', sans-serif;
  white-space: nowrap;
  z-index: 1000;
}

.toggle-button {
  position: absolute;
  right: -12px;
  top: 24px;
  width: 24px;
  height: 24px;
  background: white;
  border: 1px solid #e5e9f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1001;
  transition: transform 0.2s ease;
}

.toggle-button:hover {
  transform: scale(1.05);
}

.sidebar-footer {
  padding: 16px;
  border-top: 1px solid #e5e9f2;
  margin-top: auto;
}

.logout-button {
  font-family: 'ramblaRegular', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  border: none;
  background: transparent;
  color: #dc2626;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.logout-button:hover {
  background-color: #fee2e2;
}

.logout-button .nav-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  color: #dc2626;
}

.adjustedSidebar .logout-button {
  justify-content: center;
  padding: 10px;
}

.adjustedSidebar .logout-button .nav-text {
  display: none;
}

/* Add tooltip for collapsed state */
.adjustedSidebar .logout-button:hover::after {
  content: "Sign Out";
  position: absolute;
  left: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
  background: #1f2937;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'ramblaRegular', sans-serif;
  white-space: nowrap;
  z-index: 1000;
}

.profile-button {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.profile-button:hover {
  background-color: #f3f4f6;
}

.profile-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #e5e9f2;
  margin-right: 12px;
  flex-shrink: 0;
}

.adjustedSidebar .profile-info {
  display: none;
}

.profile-info {
  text-align: left;
  flex: 1;
}

.profile-name {
  font-family: 'ramblaBold', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #1f2937;
}

.profile-role {
  font-family: 'ramblaRegular', sans-serif;
  font-size: 12px;
  color: #6b7280;
}

/* Add these styles */
.content-area {
  flex: 1;
  margin-left: 225px;
  padding: 0px 32px 24px 32px;
  transition: margin-left 0.2s ease;
  background: #f7f9fc;
}

.content-area.adjusted {
  margin-left: 45px;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(229, 231, 235, 0.8);
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.top-bar__left {
  flex: 1;
}

.top-bar__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'ramblaRegular', sans-serif;
}

.top-bar__breadcrumb-item {
  color: #1f2937;
  font-size: 0.813rem;
  opacity: 0.8;
}

.top-bar__breadcrumb img {
  position: absolute;
  width: 60px;
  height: 60px;
}

.top-bar__breadcrumb-separator {
  color: #6b7280;
  opacity: 0.5;
}

.top-bar__center {
  flex: 2;
  max-width: 680px;
  padding: 0 1rem;
}

.top-bar__search {
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.top-bar__search:hover {
  transform: scale(1.01);
}

.top-bar__search-input {
  width: 100%;
  padding: 0.625rem 1rem 0.625rem 2.25rem;
  border-radius: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.05);
  font-family: 'ramblaRegular', sans-serif;
  font-size: 0.875rem;
  color: #1f2937;
  transition: all 0.2s ease;
  cursor: pointer;
}

.top-bar__search-input::placeholder {
  color: #6b7280;
  opacity: 0.7;
}

.top-bar__search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
  width: 14px;
  height: 14px;
  opacity: 0.7;
}

.top-bar__right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.top-bar__actions {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.top-bar__action-btn {
  position: relative;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.top-bar__action-btn:hover {
  background: rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
}

.top-bar__icon {
  width: 18px;
  height: 18px;
  color: #1f2937;
  opacity: 0.8;
}

.top-bar__notification-badge {
  position: absolute;
  top: -2px;
  right: -2px;
  background: #ff3b30;
  color: white;
  font-size: 0.688rem;
  padding: 0.125rem 0.375rem;
  border-radius: 15px;
  font-family: 'ramblaBold', sans-serif;
  border: 2px solid white;
}

.top-bar__profile {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.top-bar__profile:hover {
  background: rgba(0, 0, 0, 0.05);
}

.top-bar__profile h4 {
  margin: 0;
  font-family: 'ramblaBold', sans-serif;
  font-size: 0.813rem;
  color: #1f2937;
}

.top-bar__status {
  display: flex;
  align-items: center;
}

.top-bar__status-icon {
  color: #34c759;
  width: 12px;
  height: 12px;
  opacity: 0.9;
}

/* Media Queries */
@media screen and (max-width: 1068px) {
  .top-bar {
    padding: 0.625rem 1.25rem;
  }

  .top-bar__center {
    flex: 1.5;
    max-width: 480px;
  }
}

@media screen and (max-width: 734px) {
  .top-bar {
    padding: 0.5rem 1rem;
  }

  .top-bar__breadcrumb {
    display: none;
  }

  .top-bar__search-input {
    font-size: 0.813rem;
    padding: 0.5rem 1rem 0.5rem 2rem;
  }

  .top-bar__center {
    max-width: none;
  }
} 