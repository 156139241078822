.home {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.1rem 2rem 2rem 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.home--loaded {
    opacity: 1;
    transform: translateY(0);
}

.home__section {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 1.5rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home__section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
}

.home__section--recent {
    grid-column: 1 / 2;
    animation: fadeIn 0.8s 0.1s both;
}

.home__section--announcements {
    grid-column: 2 / 3;
    animation: fadeIn 0.8s 0.2s both;
}

.home__section--posts {
    grid-column: 1 / 2;
    animation: fadeIn 0.8s 0.3s both;
}

.home__section--interviews {
    grid-column: 2 / 3;
    animation: fadeIn 0.8s 0.4s both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .home {
        grid-template-columns: 1fr;
        padding: 1rem;
    }

    .home__section--recent,
    .home__section--announcements,
    .home__section--posts,
    .home__section--interviews {
        grid-column: 1 / 2;
    }
}

/* Add system font stack for Apple-like typography */
* {
    font-family: "ramblaRegular",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, 
                 Helvetica, Arial, sans-serif, "Apple Color Emoji";
}