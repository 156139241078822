@font-face {
    font-family: "ramblaRegular";
    src: url("../../assets/fonts/Rambla-Regular.ttf");
}

@font-face {
    font-family: "ramblaBold";
    src: url("../../assets/fonts/Rambla-Bold.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 5rem 20px;
    min-height: 100vh;
    background-color: #ffffff;
    background-attachment: fixed;
    background-size: cover;
}

.mobile-logo {
    max-width: 120px;
    margin-bottom: 24px;
}

.faq-content {
    max-width: 800px;
    margin: 0 auto;
}

.faq-intro {
    text-align: center;
    margin-bottom: 48px;
}

.faq-intro h2 {
    font-family: "ramblaBold", sans-serif;
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 16px;
}

.faq-intro p {
    font-family: "ramblaRegular", sans-serif;
    font-size: 1.1rem;
    color: #000;
}

.faq-questions {
    margin-bottom: 48px;
}

.collapse-custom {
    background: transparent;
}

.ant-collapse-item {
    margin-bottom: 16px;
    border-radius: 8px !important;
    overflow: hidden;
}

.faqCollapsePanel {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.ant-collapse-header {
    font-family: "ramblaBold", sans-serif;
    font-size: 1.1rem;
    padding: 20px !important;
    color: #2d3436 !important;
}

.faqAnswerBox {
    padding: 0 20px 20px;
}

.faqAnswerBox p {
    font-family: "ramblaRegular", sans-serif;
    color: #4a4a4a;
    line-height: 1.6;
    margin: 0;
}

.faq-icon {
    color: #3294ff;
    transition: all 0.3s ease;
}

.faq-icon.active {
    transform: rotate(180deg);
}

.faq-contact {
    text-align: center;
    padding: 32px;
    background: rgba(50, 148, 255, 0.9);
    border-radius: 12px;
    color: white;
}

.faq-contact h4 {
    font-family: "ramblaBold", sans-serif;
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: white;
}

.faq-contact p {
    font-family: "ramblaRegular", sans-serif;
}

.contact-link {
    color: #ffb400;
    font-family: "ramblaBold", sans-serif;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #ffd700;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .faq-container {
        padding: 24px 16px;
    }

    .faq-intro h2 {
        font-size: 2rem;
    }

    .ant-collapse-header {
        font-size: 1rem;
        padding: 16px !important;
    }

    .faqAnswerBox {
        padding: 0 16px 16px;
    }
}

@media (max-width: 480px) {
    .faq-intro h2 {
        font-size: 1.75rem;
    }

    .faq-contact {
        padding: 24px 16px;
    }
}