/* APIDocumentation.css */
@import url('https://fonts.googleapis.com/css2?family=Rambla:wght@400;700&display=swap');

@font-face {
  /*Myriad Set Pro Text*/
  font-family: "ramblaRegular";
  src: url("../../assets/fonts/Rambla-Regular.ttf");
}

@font-face {
  /*Myriad Set Pro Text*/
  font-family: "ramblaBold";
  src: url("../../assets/fonts/Rambla-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
}

.developerContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
}

.api-header {
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 3rem;
}

.api-header-content {
  max-width: 800px;
  margin: 0 auto;
}

.api-title {
  font-family: 'Rambla', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
}

.api-subtitle {
  font-family: 'Rambla', sans-serif;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.api-section {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.api-heading {
  color: #2d3748;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.api-code-block {
  background: #1a202c;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
  overflow-x: auto;
}

.api-code-text {
  color: #e2e8f0;
  font-family: 'Courier New', monospace;
  line-height: 1.5;
}

.api-tabs {
  border-bottom: 2px solid #e2e8f0;
  margin-bottom: 1rem;
}

.api-tab {
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  color: #4a5568;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.api-tab.active {
  color: #3294ff;
  border-bottom: 2px solid #3294ff;
}

.api-string { color: #9ae6b4; }
.api-number { color: #90cdf4; }
.api-keyword { color: #ed64a6; }
.api-url { color: #fbd38d; }

.section-icon {
    margin-right: 0.5rem;
    color: #3294ff;
}

/* Update the auth buttons styling */
.auth-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.api-key-alert {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .api-header {
    padding: 3rem 1rem;
  }
  
  .api-title {
    font-size: 2.5rem;
  }
  
  .api-subtitle {
    font-size: 1.1rem;
  }
}
  