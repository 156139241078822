.nafasi-add-assets__container {
    width: 100%;
}

.nafasi-add-assets {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.nafasi-add-assets__form {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    padding: 24px;
}

.nafasi-add-assets__form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 480px;
}

.nafasi-add-assets__form-group {
    width: 100%;
}

.nafasi-add-assets__button {
    margin-top: 8px;
}

.nafasi-add-assets__loader {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.nafasi-add-assets__table {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    padding: 24px;
}

/* Table Styles */
.nafasi-add-assets__table .ant-table {
    background: transparent;
}

.nafasi-add-assets__table .ant-table-thead > tr > th {
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #1d1d1f;
    padding: 16px;
}

.nafasi-add-assets__table .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px;
    transition: background 0.2s ease;
}

.nafasi-add-assets__table .ant-table-tbody > tr:hover > td {
    background: rgba(0, 0, 0, 0.02);
}

/* Delete Button */
.nafasi-add-assets__table .ant-popconfirm-buttons {
    display: flex;
    gap: 8px;
}

.nafasi-add-assets__table .ant-btn-primary {
    background: #ff3b30;
    border-color: #ff3b30;
}

.nafasi-add-assets__table .ant-btn-primary:hover {
    background: #d70015;
    border-color: #d70015;
}

/* Message Styles */
.ant-message {
    font-family: 'ramblaRegular', sans-serif;
}

/* Media Queries */
@media screen and (max-width: 1068px) {
    .nafasi-add-assets__form,
    .nafasi-add-assets__table {
        padding: 20px;
    }
    
    .nafasi-add-assets__form-content {
        gap: 10px;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-add-assets {
        gap: 24px;
    }
    
    .nafasi-add-assets__form,
    .nafasi-add-assets__table {
        padding: 16px;
    }
    
    .nafasi-add-assets__form-content {
        gap: 0px;
    }
    
    .nafasi-add-assets__table .ant-table-thead > tr > th,
    .nafasi-add-assets__table .ant-table-tbody > tr > td {
        padding: 12px;
        font-size: 12px;
    }
} 