.benefit-container {
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background: linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'ramblaRegular', sans-serif;
}

.benefit-section-title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.1rem;
    background: linear-gradient(135deg, #2a2a2a 0%, #0056b3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'ramblaBold', sans-serif;
}

.benefit-tabs-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 1100px;
}

.benefit-tab-buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.benefit-tab-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 12px;
    background: white;
    color: #666;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    font-family: 'ramblaBold', sans-serif;
}

.benefit-tab-button.active {
    background: #0056b3;
    color: white;
}

.benefit-tab-content {
    position: relative;
}

.benefit-content-wrapper {
    display: flex;
    gap: 5rem;
    align-items: center;
    padding: 2rem;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.benefit-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 1vw;
    margin-bottom: 2rem;
    border: 0.3rem solid #0056b3;
    padding: 1rem;
    border-radius: 12px;
}

.benefit-item {
    flex: 1;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: 150px;
}

.benefit-icon-wrapper {
    font-size: 20px;
    color: #0056b3;
    margin-right: 1.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    position: relative;
}

.benefit-icon-wrapper .anticon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.benefit-item-text {
    font-size: 0.95rem;
    font-weight: 500;
    color: #2a2a2a;
    font-family: 'ramblaBold', sans-serif;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
}

.benefit-illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: 0;
}

.benefit-image {
    width: 100%;
    height: auto;
    max-width: 400px;
    filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.1));
    border-radius: 12px;
}

@media (max-width: 968px) {
    .benefit-container {
        padding: 3rem 1.5rem;
    }
    
    .benefit-content-wrapper {
        flex-direction: column-reverse;
        gap: 3rem;
        padding: 1rem;
    }
    
    .benefit-list {
        width: 100%;
        max-width: 520px;
        margin: 0 auto;
    }
    
    .benefit-illustration {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .benefit-list {
        flex-direction: column;
        gap: 1rem;
    }

    .benefit-item {
        width: 100%;
    }
}

.benefit-tab-button span {
    font-family: 'ramblaBold', sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.benefit-tab-button .anticon {
    font-size: 20px;
}




