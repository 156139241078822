.support-container {
    width: 100%;
    min-height: calc(100vh - 120px);
    background: #fbfbfd;
    border-radius: 20px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
}

.support-header {
    padding: 32px 48px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 100;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 16px;
}

.header-content h1 {
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 28px;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;
    color: #1d1d1f;
    margin: 0;
}

.tickets-header {
    padding: 24px 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.filters {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

/* Table Styles */
.support-container .ant-table-wrapper {
    padding: 0 48px 48px;
}

.support-container .ant-table {
    background: transparent !important;
}

.support-container .ant-table-thead > tr > th {
    background: #f5f5f7 !important;
    font-weight: 600 !important;
    color: #1d1d1f !important;
    padding: 16px !important;
}

.support-container .ant-table-tbody > tr > td {
    padding: 16px !important;
}

.support-container .ant-table-tbody > tr:hover > td {
    background: #f5f5f7 !important;
}

/* Status and Priority Tags */
.support-container .ant-tag {
    margin: 0;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1.4;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
}

/* Priority Tags */
.support-container .ant-tag-red {
    background: rgba(255, 59, 48, 0.1);
    color: #ff3b30;
}

.support-container .ant-tag-orange {
    background: rgba(255, 149, 0, 0.1);
    color: #ff9500;
}

.support-container .ant-tag-green {
    background: rgba(52, 199, 89, 0.1);
    color: #34c759;
}

/* Status Tags */
.support-container .ant-tag-blue {
    background: rgba(0, 122, 255, 0.1);
    color: #007aff;
}

/* Modal and Drawer Styles */
.support-container .ant-modal-content,
.support-container .ant-drawer-content {
    border-radius: 20px;
    overflow: hidden;
}

.support-container .ant-modal-header,
.support-container .ant-drawer-header {
    padding: 24px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.support-container .ant-modal-body,
.support-container .ant-drawer-body {
    padding: 24px !important;
}

/* Ticket Details */
.ticket-details {
    padding: 0;
}

.ticket-header {
    margin-bottom: 24px;
}

.ticket-header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 16px;
}

.ticket-meta {
    display: flex;
    gap: 8px;
}

.ticket-description {
    background: #f5f5f7;
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 24px;
}

.ticket-description h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 12px;
}

.responses {
    margin-top: 24px;
}

.response {
    background: #f5f5f7;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 16px;
}

.response-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.response-meta {
    font-size: 13px;
    color: #86868b;
}

/* Form Controls */
.ant-form-item-label > label {
    font-weight: 500;
    color: #1d1d1f;
}

.support-container .ant-input,
.support-container .ant-input-textarea {
    border-radius: 8px;
}

.support-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
}

.support-container .ant-upload-drag {
    border-radius: 12px;
}

/* Timeline */
.support-container.ant-timeline {
    padding: 24px;
}

.support-container .ant-timeline-item-content {
    padding-bottom: 24px;
}

.support-container .ant-timeline-item-content p {
    margin: 0;
    color: #1d1d1f;
}

.support-container .ant-timeline-item-content small {
    color: #86868b;
    display: block;
    margin-top: 4px;
}

/* Tabs */
.support-container .ant-tabs-nav {
    margin-bottom: 24px !important;
}

.support-container .ant-tabs-tab {
    padding: 12px 24px !important;
}

.support-container .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    gap: 8px;
}

.support-container .ant-tabs-tab.ant-tabs-tab-active {
    background: #f5f5f7;
    border-radius: 20px;
}

/* Attachments */
.attachments {
    margin-top: 16px;
}

.attachments h4 {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 8px;
}

.attachments a {
    display: inline-block;
    color: #0066cc;
    text-decoration: none;
    margin-right: 16px;
    font-size: 14px;
}

.attachments a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 1068px) {
    .support-header,
    .tickets-header {
        padding: 24px;
    }

    .ant-table-wrapper {
        padding: 0 24px 24px;
    }
}

@media (max-width: 734px) {
    .support-header,
    .tickets-header {
        padding: 16px;
    }

    .support-container .header-content h1 {
        font-size: 24px;
    }

    .support-container .filters {
        width: 100%;
        justify-content: space-between;
    }

    .support-container .ant-table-wrapper {
        padding: 0 16px 16px;
    }

    .support-container .ant-select {
        width: calc(33.33% - 8px) !important;
    }
} 