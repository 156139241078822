.military-container {
    font-family: 'ramblaRegular', sans-serif;
    background: #f8fafc;
    min-height: 200px;
}

.military-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 0;
}

.military-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
}

.military-card:hover {
    transform: translateY(-2px);
}

.military-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    position: relative;
}

.military-icon {
    width: 32px;
    height: 32px;
    background: #E0F2FE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0369A1;
}

.military-info {
    flex: 1;
}

.military-info h4 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
}

.military-rank {
    color: #6B7280;
    font-size: 0.85rem;
    display: block;
    margin-top: 2px;
    font-family: "ramblaRegular";
}

.active-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #DCFCE7;
    color: #166534;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "ramblaRegular";
}

.service-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 12px 0;
}

.service-period, .service-location {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6B7280;
    font-size: 0.85rem;
}

.period-icon, .location-icon {
    color: #3B82F6;
    width: 16px;
}

.service-description {
    color: #4B5563;
    font-size: 0.9rem;
    margin: 8px 0;
    line-height: 1.5;
    font-family: "ramblaRegular";
}

.commendations {
    margin-top: 12px;
}

.commendations h5 {
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.commendations-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.commendation-badge {
    background: #F3F4F6;
    color: #374151;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.section-header h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.section-icon {
    color: #ecb81f;
}

.section-summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.total-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.military-timeline {
    position: relative;
    padding: 20px 0;
}

.no-data-message {
    text-align: center;
    padding: 48px 0;
    color: #6B7280;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #D1D5DB;
}

@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .section-summary {
        width: 100%;
        justify-content: flex-start;
    }

    .military-grid {
        grid-template-columns: 1fr;
    }
} 