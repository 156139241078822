.nafasi-promo__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
    border-radius: 12px;
}

.nafasi-promo {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-promo__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-promo__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-promo__icon {
    color: #0066cc;
}

.nafasi-promo__title {
    margin: 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
}

.nafasi-promo__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-promo__form {
    padding: 1rem;
}

.nafasi-promo__form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
}

.nafasi-promo__form-item {
    margin-bottom: 0 !important;
}

.nafasi-promo__form-item .ant-form-item-label > label {
    font-family: 'ramblaBold', sans-serif;
    color: #1d1d1f;
    height: 28px;
}

.nafasi-promo__form-item .ant-input {
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-promo__form-item .ant-input::placeholder {
    color: #86868b;
}

.nafasi-promo__form-submit {
    margin-top: 24px !important;
    text-align: right;
}

.nafasi-promo__submit-btn {
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
    background: #0066cc;
    border-color: #0066cc;
    transition: all 0.2s ease;
}

.nafasi-promo__submit-btn:hover {
    background: #0077ed !important;
    border-color: #0077ed !important;
}

.nafasi-promo__submit-btn:active {
    background: #005bb8 !important;
    border-color: #005bb8 !important;
}

/* Responsive styles */
@media screen and (max-width: 1068px) {
    .nafasi-promo__container {
        padding: 1.5rem;
    }
    
    .nafasi-promo__form {
        padding: 0.75rem;
    }
    
    .nafasi-promo__form-grid {
        gap: 12px;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-promo__container {
        padding: 1rem;
    }
    
    .nafasi-promo__title {
        font-size: 1.1rem !important;
    }
    
    .nafasi-promo__form {
        padding: 0.5rem;
    }
    
    .nafasi-promo__form-grid {
        grid-template-columns: 1fr;
        gap: 8px;
    }
} 