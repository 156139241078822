.interviews-container {
    font-family: 'ramblaRegular', sans-serif;
    background: #f8fafc;
    min-height: 200px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.interview-assessment {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.assessment-header {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E5E7EB;
}

.assessment-type {
    display: flex;
    align-items: center;
    gap: 12px;
}

.assessment-icon {
    width: 32px;
    height: 32px;
    background: #EFF6FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D4ED8;
    font-size: 1.2rem;
    padding: 8px;
}

.assessment-type h3 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.company-name {
    color: #2563EB;
    font-weight: 500;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 16px;
}

@media (max-width: 1600px) {
    .skills-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1200px) {
    .skills-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 900px) {
    .skills-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 600px) {
    .skills-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 400px) {
    .skills-grid {
        grid-template-columns: 1fr;
    }
}

.skill-card {
    background: #F9FAFB;
    border-radius: 6px;
    padding: 6px 12px;
}

.skill-rating {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.skill-label {
    color: #4B5563;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "ramblaBold";
}

.rating-bar {
    height: 8px;
    background: #E5E7EB;
    border-radius: 4px;
    overflow: hidden;
}

.rating-fill {
    height: 100%;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.rating-value {
    color: #6B7280;
    font-size: 0.85rem;
    text-align: right;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
}

.section-header h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.section-icon {
    color: #ecb81f;
}

.section-summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.total-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.no-data-message {
    text-align: center;
    padding: 48px 0;
    color: #6B7280;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #D1D5DB;
}

@media (max-width: 1200px) {
    .section-container {
        padding: 16px;
    }
}

@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .section-summary {
        width: 100%;
        justify-content: flex-start;
    }

    .skills-grid {
        grid-template-columns: 1fr;
    }

    .perform-interview-button {
        max-width: 100%;
    }
}

.perform-interview-button {
    width: 100%;
    max-width: 480px;
    margin: 1rem auto;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #ffb400;
    border: none;
    border-radius: 6px;
    color: #000;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.95rem;
    cursor: pointer;
    transition: opacity 0.2s;
}

.perform-interview-button:hover {
    opacity: 0.9;
}

.interviews-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.interview-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.no-general-interview {
    text-align: center;
    padding: 32px;
    background: #F9FAFB;
    border-radius: 8px;
    color: #6B7280;
}

.section-divider {
    position: relative;
    text-align: center;
    margin: 24px 0;
}

.section-divider span {
    background: white;
    padding: 0 16px;
    color: #6B7280;
    font-size: 0.9rem;
    position: relative;
    z-index: 1;
}

.section-divider:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: #E5E7EB;
} 