.nafasi-result {
    width: 100%;
}

.nafasi-result__container {
    width: 100%;
}

/* Table Styles */
.nafasi-result .ant-table-wrapper {
    width: 100%;
}

.nafasi-result .ant-table {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
}

.nafasi-result .ant-table-container {
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-result .ant-table-thead > tr > th {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    background: #f5f5f7;
    color: #1d1d1f;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-result .ant-table-tbody > tr > td {
    font-size: 13px;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-result .ant-table-tbody > tr:hover > td {
    background: rgba(0, 0, 0, 0.02);
}

.nafasi-result .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

/* Candidate Card Styles */
.nafasi-result__candidate {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.nafasi-result__candidate-info {
    min-width: 0;
}

.nafasi-result__candidate-name {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    line-height: 1.2;
    color: #1d1d1f;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nafasi-result__candidate-details {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    color: #424245;
    margin-bottom: 2px;
}

.nafasi-result__candidate-highlight {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 11px;
    color: #0071e3;
    background: rgba(0, 113, 227, 0.08);
    padding: 1px 6px;
    border-radius: 980px;
    display: inline-block;
}

/* Location Styles */
.nafasi-result__location {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    color: #424245;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nafasi-result__location-icon {
    color: #86868b;
    font-size: 12px;
    flex-shrink: 0;
}

/* Stats Badge Styles */
.nafasi-result__stats-badge {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    padding: 1px 6px;
    border-radius: 980px;
    background: #f5f5f7;
    color: #86868b;
    display: inline-block;
    min-width: 40px;
    text-align: center;
}

.nafasi-result__stats-badge--high {
    background: rgba(52, 199, 89, 0.1);
    color: #28a745;
}

.nafasi-result__stats-badge--medium {
    background: rgba(255, 149, 0, 0.1);
    color: #ff9500;
}

/* Action Buttons */
.nafasi-result__actions {
    display: flex;
    gap: 4px;
    align-items: center;
}

.nafasi-result__action-button {
    background: transparent;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    width: 36px;
    height: 36px;
}

.nafasi-result__action-button:hover {
    background: rgba(0, 0, 0, 0.03);
    transform: translateY(-1px);
}

.nafasi-result__action-button:active {
    transform: translateY(0);
}

/* Modal Styles */
.nafasi-result__modal {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
}

.nafasi-result__modal .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-result__modal .ant-modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin: 0;
}

.nafasi-result__modal .ant-modal-title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 17px;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-result__modal-content {
    padding: 20px;
}

.nafasi-result__modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Pagination Styles */
.nafasi-result .ant-pagination {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    padding: 16px 0 0;
    margin: 0;
}

.nafasi-result .ant-pagination-item {
    border-radius: 6px;
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0 4px;
}

.nafasi-result .ant-pagination-item-active {
    border-color: #0071e3;
    background: #0071e3;
}

.nafasi-result .ant-pagination-item-active a {
    color: #fff;
}

/* Truncated Text */
.nafasi-result__truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Responsive Design */
@media (max-width: 734px) {
    .nafasi-result .ant-table-thead > tr > th,
    .nafasi-result .ant-table-tbody > tr > td {
        padding: 8px 12px;
    }

    .nafasi-result__candidate-name {
        font-size: 13px;
    }

    .nafasi-result__candidate-details {
        font-size: 12px;
    }

    .nafasi-result__location {
        font-size: 12px;
    }

    .nafasi-result__stats-badge {
        font-size: 12px;
        padding: 1px 4px;
        min-width: 36px;
    }

    .nafasi-result__action-button {
        padding: 4px;
        width: 32px;
        height: 32px;
    }
} 