.nafasi-feedback__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
}

.nafasi-feedback {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-feedback__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-feedback__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-feedback__icon {
    color: #0066cc;
}

.nafasi-feedback__title {
    margin: 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
}

.nafasi-feedback__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 24px;
}

.nafasi-feedback__loading {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 8px;
}

.nafasi-feedback__skeleton {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 16px;
}

.nafasi-feedback__empty {
    text-align: center;
    padding: 48px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-feedback__empty-text {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
}

/* Responsive Design */
@media screen and (max-width: 1068px) {
    .nafasi-feedback__container {
        padding: 1.5rem;
    }
    
    .nafasi-feedback__content {
        padding: 1.25rem;
    }
    
    .nafasi-feedback__loading {
        gap: 12px;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-feedback__container {
        padding: 1rem;
    }
    
    .nafasi-feedback__title {
        font-size: 1.1rem !important;
    }
    
    .nafasi-feedback__content {
        padding: 1rem;
    }
    
    .nafasi-feedback__loading {
        grid-template-columns: 1fr;
        gap: 8px;
    }
    
    .nafasi-feedback__empty {
        padding: 32px;
    }
} 