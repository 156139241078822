/* Container styles */
.post-review-table {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  margin: 1rem 0;
}

/* Table Styles */
.post-review-table .ant-table {
  background: transparent;
  font-family: 'ramblaRegular', sans-serif;
}

.post-review-table .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.post-review-table .ant-table-thead > tr > th {
  background: #f5f5f7;
  border-bottom: none;
  color: #1d1d1f;
  font-family: 'ramblaBold', sans-serif;
  padding: 16px;
  font-size: 14px;
  transition: background 0.2s ease;
  white-space: nowrap;
}

.post-review-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f5f5f7;
  padding: 16px;
  font-size: 14px;
  font-family: 'ramblaRegular', sans-serif;
  transition: all 0.2s ease;
}

.post-review-table .ant-table-tbody > tr:hover > td {
  background: rgba(0, 102, 204, 0.04);
}

.post-review-table .ant-table-cell {
  white-space: nowrap;
}

/* Cell content styles */
.table-cell {
  font-size: 14px;
  font-family: 'ramblaRegular', sans-serif;
  color: #1d1d1f;
  padding: 6px 0;
}

.table-cell.name {
  font-family: 'ramblaBold', sans-serif;
  color: #0066cc;
}

/* Status indicators */
.status-indicator {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-family: 'ramblaBold', sans-serif;
  transition: all 0.2s ease;
}

.status-done {
  background: rgba(52, 199, 89, 0.1);
  color: #34c759;
}

.status-pending {
  background: rgba(255, 149, 0, 0.1);
  color: #ff9500;
}

/* Interview date link */
.interview-date {
  color: #0066cc;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'ramblaRegular', sans-serif;
  padding: 6px 12px;
  border-radius: 6px;
  display: inline-block;
}

.interview-date:hover {
  background: rgba(0, 102, 204, 0.1);
  color: #0066cc;
}

/* Action buttons */
.action-button {
  background: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'ramblaRegular', sans-serif;
}

.action-button:hover {
  background: #f5f5f7;
  transform: translateY(-1px);
}

.action-button.edit {
  color: #0066cc;
}

.action-button.edit:hover {
  background: rgba(0, 102, 204, 0.1);
}

.action-button.delete {
  color: #ff3b30;
}

.action-button.delete:hover {
  background: rgba(255, 59, 48, 0.1);
}

/* Modal styles */
.ant-modal {
  padding-bottom: 0;
}

.ant-modal-content {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.ant-modal-header {
  padding: 24px;
  border-bottom: 1px solid #f5f5f7;
  background: #ffffff;
}

.ant-modal-title {
  font-family: 'ramblaBold', sans-serif;
  font-size: 18px;
  color: #1d1d1f;
}

.ant-modal-body {
  padding: 24px;
  background: #ffffff;
}

.ant-modal-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.ant-modal-close:hover {
  background: #f5f5f7;
}

/* Popconfirm styles */
.ant-popconfirm {
  font-family: 'ramblaRegular', sans-serif;
}

.ant-popover-content {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.ant-popover-inner {
  border-radius: 12px;
}

.ant-popover-message {
  padding: 16px;
}

.ant-popover-buttons {
  padding: 12px 16px;
  border-top: 1px solid #f5f5f7;
}

/* Pagination */
.ant-pagination {
  font-family: 'ramblaRegular', sans-serif;
  margin-top: 24px;
  padding: 16px 0;
  border-top: 1px solid #f5f5f7;
}

.ant-pagination-item {
  border-radius: 8px;
  font-family: 'ramblaBold', sans-serif;
  border: none;
  background: transparent;
  transition: all 0.2s ease;
}

.ant-pagination-item:hover {
  background: #f5f5f7;
}

.ant-pagination-item-active {
  background: #0066cc !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  border-radius: 8px;
  transition: all 0.2s ease;
}

.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  background: #f5f5f7;
}

/* Empty state */
.adminAlert {
  font-family: 'ramblaBold', sans-serif;
  text-align: center;
  color: #86868b;
  font-size: 17px;
  line-height: 1.5;
  padding: 48px 24px;
  background: #f5f5f7;
  border-radius: 16px;
  margin: 24px 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

/* Responsive design */
@media (max-width: 1200px) {
  .post-review-table .ant-table-thead > tr > th,
  .post-review-table .ant-table-tbody > tr > td {
    padding: 12px;
    font-size: 13px;
  }
  
  .table-cell {
    font-size: 13px;
  }
  
  .status-indicator {
    font-size: 12px;
    padding: 4px 10px;
  }
  
  .action-button {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 768px) {
  .post-review-table {
    margin: 0.5rem 0;
    border-radius: 12px;
  }

  .post-review-table .ant-table-thead > tr > th,
  .post-review-table .ant-table-tbody > tr > td {
    padding: 10px;
    font-size: 12px;
  }
  
  .table-cell {
    font-size: 12px;
    padding: 4px 0;
  }
  
  .status-indicator {
    font-size: 11px;
    padding: 3px 8px;
  }
  
  .action-button {
    width: 28px;
    height: 28px;
    border-radius: 6px;
  }
  
  .ant-modal-header {
    padding: 16px;
  }
  
  .ant-modal-body {
    padding: 16px;
  }
  
  .ant-modal-title {
    font-size: 16px;
  }
  
  .adminAlert {
    padding: 32px 16px;
    font-size: 15px;
    border-radius: 12px;
    margin: 16px 0;
  }
} 