.nafasi-cv {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.nafasi-cv-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #f5f5f7;
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    color: #1d1d1f;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.nafasi-cv-button:hover:not(:disabled) {
    background-color: #e8e8ed;
    transform: translateY(-1px);
}

.nafasi-cv-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.nafasi-cv-button__icon {
    font-size: 16px;
    color: #06c;
}

.nafasi-cv-modal {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-cv-modal :global(.ant-modal-content) {
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    padding: 0;
}

.nafasi-cv-modal :global(.ant-modal-header) {
    padding: 16px 24px;
    border-bottom: 1px solid #d2d2d7;
    border-radius: 12px 12px 0 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
}

.nafasi-cv-modal :global(.ant-modal-title) {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
}

.nafasi-cv-modal :global(.ant-modal-body) {
    padding: 24px;
    background-color: #fff;
}

.nafasi-cv-modal :global(.ant-modal-footer) {
    padding: 16px 24px;
    border-top: 1px solid #d2d2d7;
    border-radius: 0 0 12px 12px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.nafasi-cv-preview {
    min-height: 400px;
    border-radius: 8px;
    background-color: #f5f5f7;
    padding: 24px;
    overflow: auto;
}

.nafasi-cv-download {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #06c;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
}

.nafasi-cv-download:hover {
    background-color: #0055b3;
    transform: translateY(-1px);
    color: #fff;
    text-decoration: none;
}

.nafasi-cv-download:disabled {
    background-color: #d2d2d7;
    cursor: not-allowed;
    transform: none;
}

.nafasi-cv-download__icon {
    font-size: 16px;
} 