.nafasi-payments__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
    border-radius: 12px;
}

.nafasi-payments {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-payments__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-payments__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-payments__icon {
    color: #0066cc;
}

.nafasi-payments__title {
    margin: 0;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.25rem;
    color: #1d1d1f;
}

.nafasi-payments__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-payments__unauthorized {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.nafasi-payments__unauthorized h2 {
    font-family: 'ramblaRegular', sans-serif;
    color: #86868b;
    font-size: 1.5rem;
}

/* Responsive styles */
@media screen and (max-width: 1068px) {
    .nafasi-payments__container {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-payments__container {
        padding: 1rem;
    }
    
    .nafasi-payments__title {
        font-size: 1.1rem;
    }
} 