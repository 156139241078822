.education-container {
    font-family: 'ramblaRegular', sans-serif;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    margin-bottom: 20px;
}

.section-header h4 {
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.section-icon {
    color: #ecb81f;
}

.education-table {
    margin-top: 16px;
}

.education-table .ant-table-thead > tr > th {
    background: #f8fafc;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    padding: 12px 16px;
}

.education-table .ant-table-tbody > tr > td {
    padding: 12px 16px;
    color: #4b5563;
}

.education-table .ant-table-tbody > tr:hover > td {
    background: #f3f4f6;
}

@media (max-width: 768px) {
    .section-container {
        padding: 16px;
    }

    .education-table {
        overflow-x: auto;
    }
}

.education-card {
    background: #fff;
    border-radius: 12px;
    padding: 1rem;
}

.education-table-container {
    overflow-x: auto;
}

.education-table {
    width: 100%;
    font-family: 'Rambla', sans-serif;
}

/* Table Header */
.education-table .ant-table-thead > tr > th {
    background: #f8f9fa;
    padding: 16px;
    font-weight: 600;
    border-bottom: 2px solid #f0f0f0;
}

/* Table Rows */
.education-table .ant-table-tbody > tr > td {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.education-table__row:hover {
    background-color: #fafafa;
}

/* Status Badge */
.education-status {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
    font-family: 'Rambla', sans-serif;
}

.education-status--active {
    background-color: #e6f7ff;
    color: #1890ff;
}

.education-status--completed {
    background-color: #f6ffed;
    color: #52c41a;
}

/* Cell Content */
.education-cell {
    font-family: 'Rambla', sans-serif;
    color: #262626;
}

.education-cell__title {
    font-size: 0.95rem;
    font-weight: 500;
    color: #262626;
    margin-bottom: 4px;
    font-family: 'ramblaBold', sans-serif;
}

.education-cell__subtitle {
    font-size: 0.85rem;
    color: #8c8c8c;
    font-family: 'ramblaRegular', sans-serif;
}

/* Duration Display */
.education-duration {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.95rem;
    color: #262626;
}

.education-duration__separator {
    color: #bfbfbf;
    font-size: 0.85rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .education-table {
        min-width: 900px;
    }
}

@media (max-width: 768px) {
    .education-card {
        padding: 0.5rem;
    }

    .education-table .ant-table-thead > tr > th,
    .education-table .ant-table-tbody > tr > td {
        padding: 12px;
    }
}