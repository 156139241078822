.nafasi-terms__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
    border-radius: 12px;
}

.nafasi-terms {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-terms__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-terms__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-terms__icon {
    color: #0066cc;
}

.nafasi-terms__title {
    margin: 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
}

.nafasi-terms__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-terms__add {
    margin-bottom: 24px;
}

.nafasi-terms__section {
    padding: 0 1rem;
}

.nafasi-terms__subtitle {
    font-family: 'ramblaBold', sans-serif !important;
    color: #86868b !important;
    margin-bottom: 16px !important;
}

.nafasi-terms__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nafasi-terms__item {
    border: 1px solid rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease;
}

.nafasi-terms__item:hover {
    border-color: #0066cc;
    box-shadow: 0 2px 8px rgba(0, 102, 204, 0.1);
}

.nafasi-terms__item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.nafasi-terms__item-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-terms__item-icon {
    color: #0066cc;
}

.nafasi-terms__item-text {
    display: flex;
    flex-direction: column;
}

.nafasi-terms__item-title {
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
    margin: 0 !important;
}

.nafasi-terms__item-date {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.nafasi-terms__view-btn {
    color: #0066cc !important;
    transition: all 0.2s ease;
}

.nafasi-terms__view-btn:hover {
    background-color: rgba(0, 102, 204, 0.05) !important;
}

.nafasi-terms__empty {
    text-align: center;
    padding: 24px;
}

.nafasi-terms__footer {
    margin-top: 24px;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    text-align: center;
}

.nafasi-terms__disclaimer {
    font-family: 'ramblaBold', sans-serif !important;
    color: #86868b !important;
    font-size: 0.9rem !important;
}

/* Responsive styles */
@media screen and (max-width: 1068px) {
    .nafasi-terms__container {
        padding: 1.5rem;
    }
    
    .nafasi-terms__section {
        padding: 0 0.75rem;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-terms__container {
        padding: 1rem;
    }
    
    .nafasi-terms__title {
        font-size: 1.1rem !important;
    }
    
    .nafasi-terms__section {
        padding: 0 0.5rem;
    }
    
    .nafasi-terms__item-content {
        padding: 6px;
    }
    
    .nafasi-terms__item-title {
        font-size: 0.9rem !important;
    }
    
    .nafasi-terms__item-date {
        font-size: 0.8rem !important;
    }
}
