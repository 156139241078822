@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaRegular";
    src: url("../../assets/fonts/Rambla-Regular.ttf");
}

@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaBold";
    src: url("../../assets/fonts/Rambla-Bold.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.signin-container {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 15px;
    position: relative;
    overflow: hidden;
}

/* Add animated background elements */
.signin-container::before,
.signin-container::after {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: rgba(7, 147, 217, 0.05);
    animation: float 8s infinite ease-in-out;
    opacity: 0.7;
}

.signin-container::before {
    top: -100px;
    right: -100px;
}

.signin-container::after {
    bottom: -100px;
    left: -100px;
    animation-delay: -4s;
}

@keyframes float {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(-20px, 20px); }
}

.signin-form {
    background: white;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 360px;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.95);
}

.signin-form img {
    display: block;
    margin: 0 auto 16px;
    max-width: 140px;
}

.signin-form .ant-input,
.signin-form .ant-input-password {
    height: 36px;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 13px;
    padding: 0 11px;
}

.signin-form .ant-form-item-label > label {
    font-size: 12px;
    font-family: 'ramblaBold', sans-serif;
    color: #666;
    height: 20px;
}

.signin-form .ant-btn {
    height: 36px;
    border-radius: 6px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    width: 100%;
    margin-bottom: 6px;
    transition: all 0.3s ease;
}

.signin-form .ant-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(7, 147, 217, 0.2);
}

.signin-form .ant-btn-primary {
    background: #0793d9;
    border-color: #0793d9;
}

.signin-form .ant-btn-default {
    border-color: #0793d9;
    color: #0793d9;
}

.section-title {
    font-family: 'ramblaBold', sans-serif;
    color: #333;
    margin-bottom: 16px;
    font-size: 18px;
}

.signin-divider {
    display: flex;
    align-items: center;
    margin: 12px 0;
    color: #666;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 12px;
}

.signin-divider::before,
.signin-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e8e8e8;
}

.signin-divider span {
    padding: 0 8px;
}

.app-badges {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
}

.app-badges img {
    height: 36px;
    width: auto;
    transition: transform 0.3s ease;
}

.app-badges img:hover {
    transform: scale(1.05);
}

/* Decorative elements */
.background-decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
}

.decoration-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(7, 147, 217, 0.2);
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 0.6; }
    50% { transform: scale(1.5); opacity: 0.3; }
    100% { transform: scale(1); opacity: 0.6; }
}

/* Add compact styling for password rules */
.password-rules {
    font-size: 11px;
    color: #666;
    margin-bottom: 6px;
    line-height: 1.2;
    margin-top: -4px;
}

/* Adjust the "Register here" link spacing */
.register-link {
    font-size: 12px;
    margin: 4px 0;
    text-align: center;
    color: #666;
}

/* Mobile view adjustments */
.mobile-view {
    text-align: center;
    padding: 20px;
    background: #1a1a1a;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-view .section-title {
    font-size: 16px;
    margin-bottom: 12px;
}

/* Add these styles to reduce gaps between form items */
.signin-form .ant-form-item {
    margin-bottom: 8px;
}

/* Adjust spacing for the last form item */
.signin-form .ant-form-item:last-child {
    margin-bottom: 4px;
}

/* Reduce space between form items and other elements */
.signin-form .ant-form-item-explain {
    min-height: 16px;
    font-size: 11px;
    margin-top: 2px;
}

/* Adjust error message spacing */
.signin-form .ant-form-item-has-error .ant-form-item-explain {
    margin-bottom: 4px;
}

/* Adjust ForgotPassword component spacing */
.forgot-password {
    margin: 4px 0 8px;
    font-size: 12px;
}

/* Adjust spacing for buttons container */
.signin-form .ant-form-item:has(button) {
    margin-top: 8px;
    margin-bottom: 4px;
}


