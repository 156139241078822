/*---------------------------------------FONTS-------------------------------------*/
@font-face {
  /*Myriad Set Pro Text*/
  font-family: "myriadText";
  src: url("./assets/fonts/myriadText.ttf");
}

@font-face {
  font-family: "myriadThin";
  src: url("./assets/fonts/myriadThin.ttf");
}

@font-face {
  font-family: "MyriadSetProUltralight";
  src: url("./assets/fonts/MyriadSetProUltralight.ttf");
}

@font-face {
  font-family: "myriadBold";
  src: url("./assets/fonts/myriadBold.ttf");
}

@font-face {
  font-family: "museoSlab";
  src: url("./assets/fonts/museoSlab.otf");
}

@font-face {
  font-family: "agencyGothic";
  src: url("./assets/fonts/agencyGothic.otf");
}

@font-face {
  font-family: "mavenPro";
  src: url("./assets/fonts/MavenProVFBeta.ttf");
}

@font-face {
  font-family: "emirates";
  src: url("./assets/fonts/emirates.otf");
}
@font-face {
  font-family: "emiratesBold";
  src: url("./assets/fonts/emiratesBold.ttf");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "Ubuntu-bold";
  src: url("./assets/fonts/Ubuntu-Bold.ttf");
}

@font-face {
  font-family: "Rockwell";
  src: url("./assets/fonts/rock.ttf");
}

@font-face {
  font-family: "avenirBlack";
  src: url("./assets/fonts/avenirBlack.otf");
}

@font-face {
  font-family: "avenirBook";
  src: url("./assets/fonts/avenirBook.otf");
}

@font-face {
  font-family: "avenirRoman";
  src: url("./assets/fonts/avenirRoman.otf");
}
@font-face {
  font-family: "economicaBold";
  src: url("./assets/fonts/Economica-Bold.ttf");
}
@font-face {
  font-family: "economicaBoldItalic";
  src: url("./assets/fonts/Economica-BoldItalic.ttf");
}
@font-face {
  font-family: "economicaItalic";
  src: url("./assets/fonts/Economica-Italic.ttf");
}
@font-face {
  font-family: "economicaRegular";
  src: url("./assets/fonts/Economica-Regular.ttf");
}

@font-face {
  /*Myriad Set Pro Text*/
  font-family: "ramblaRegular";
  src: url("./assets/fonts/Rambla-Regular.ttf");
}

@font-face {
  /*Myriad Set Pro Text*/
  font-family: "ramblaBold";
  src: url("./assets/fonts/Rambla-Bold.ttf");
}


* {
  margin: 0;
}

.mainContainer {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.adjustedMainContainer {

  width: 100%;
  margin-top: -2.3rem;
  padding-top: 5.5vh;
  padding-left: 4rem;
  padding-right: 5rem;
  min-height: 100vh;
  overflow-y: hidden;
  /* background-color: #2b2b2b; */
  background-color: white;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav {
  display: grid;
  grid-template: 1fr / 8fr 2fr;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #2b2b2b;
  height: 8.5%;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav .navListContainer {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav .iconContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav ul {
  list-style-type: none;
  display: flex;
  padding-top: 0.5rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav li a {
  display: block;
  color: gray;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  font-size: 0.95rem;
  font-family: "ramblaBold", sans-serif;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.nav li a:hover {
  color: white;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav li .active {
  border-bottom: 0.05rem solid #0793d9;
  color: #ffb400;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}



.navLogo {
  background-color: transparent;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.navLogo img {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
  margin-left: -2.2rem;
  margin-right: 1rem;
  margin-top: 0.1rem;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  /*padding: 1rem;*/
  width: 100%;
  background-color: #2b2b2b;
  /*height: 8.3%;*/
  /* background: -webkit-linear-gradient(62deg, rgba(142, 197, 252, 0.35) 0%, #e0c3fc 100%);  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(62deg, rgba(142, 197, 252, 0.36) 0%, #e0c3fc 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 .navLogo {
  margin-right: 1.5rem;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 ul {
  list-style-type: none;
  display: flex;
  /*flex-direction: row;*/
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0 0 0;
  margin-top: 0.2rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 li a {
  display: block;
  color: gray;
  padding: 0.1rem 1.5rem 0 1.5rem;
  text-decoration: none;
  font-size: 0.95rem;
  font-family: ramblaRegular, sans-serif;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.nav2 li .active {
  /*border-bottom: 0.05rem solid rgb(35, 45, 182);*/
  color: #ffb400;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 li a:hover {
  color: white;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 ul li:last-child {
  margin: 0.1rem 0 0 10rem;
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 1.5rem;
  border: 0.25rem solid #e8e8ff;
  padding: 0 0.5rem 0.1rem 0.5rem;
  text-align: center;
  width: 10rem;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 ul li:last-child a {
  color: #f8f8f8;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.nav2 ul li:last-child a:hover {
  color: rgb(36, 36, 40);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

/*burger nenu*/

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 14px;
  right: 12px;
  top: 16px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgb(94, 90, 90);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffb400;
}

@media (max-width: 768px) {
    /* .nav2 {
        display: none;
    } */
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 22px;
  width: 22px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(255, 255, 255, 0.98) !important;
  padding: 48px 24px 24px !important; /* Adjust padding */
}

.bm-item {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  color: #1d1d1f !important;
}

.bm-item-list {
  padding: 0 !important;
  color: #1d1d1f !important;
}

/* Style active menu items */
.menu-item {
    display: block !important;
    padding: 16px 0 !important;
    margin: 0.1px 0 !important; /* Add vertical spacing between items */
    color: #1d1d1f !important;
    text-decoration: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    transition: all 0.3s ease !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important; /* Subtle separator */
}

/* Remove border from last item */
.menu-item:last-child {
    border-bottom: none !important;
}

/* Add more space before the Sign Up button */
.menu-item.console-button {
    margin-top: 24px !important;
    border-bottom: none !important;
}

.menu-item:hover {
    color: #0071e3 !important;
    padding-left: 8px !important;
    background-color: rgba(0, 113, 227, 0.05) !important; /* Subtle background on hover */
    border-radius: 8px !important;
}

/* Container for menu items */
.bm-item-list > div {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    visibility: visible !important;
    padding: 16px 0 !important; /* Add padding to the container */
}

/* Menu background */
.bm-menu {
    background: rgba(255, 255, 255, 0.98) !important;
    padding: 48px 24px 24px !important; /* Adjust padding */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #ffffff;
  padding: 1em;
  font-size: 1.6rem;
  font-family: ramblaRegular, avenirBlack, sans-serif;
}

.menu-item {
  color: #ffffff;
}
/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/*-------------------------------------------------------------------*/
.homeContainer,
.peopleContainer,
.searchContainer,
.hiringContainer,
.filesContainer,
.paymentsRenewalsContainer,
.ticketsContainer,
.notificationContainer,
.settingsContainer,
.profileContainer,
.manageLeavesContainer,
.jobOpeningReviewContainer,
.supportTicketsContainer,
.payrollContainer,
.allTrainingsContainer,
.allCelebrationsContainer,
.invoiceContainer,
.subscriptionContainer,
.addAccountToPackageContainer,
.printInvoiceContainer,
.clientViewTermsContainer,
.registerEmployeeContainer,
.reportsContainer,
.employeeToolContainer,
.container-fluid{
  padding-top: 2.6rem;
  padding-bottom: 2rem;
  min-height: 100vh;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.homeContainer,
.container-fluid {
  padding: 4rem 0 0 0;
}

.editProfileContainer {
  background-color: white;
  padding: 2rem;
  width: 50%;
  margin: 1rem auto;
}

.logoutButton {
  display: block;
 
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.logoutButton:hover {
  transform: scale(1.2);
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  color: #ff7e75;
}

.buttonBox {
  width: auto;
  padding: 0.16rem 0.13rem 0.15rem 0.13rem;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  -webkit-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  border-radius: 0.1rem;
  background: -webkit-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -o-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -ms-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -moz-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
}

.buttonBox2 {

  padding: 0.5rem 1rem;
}

/* --------------------------------------SIGN IN & SIGN UP----------------------------------------- */
.loginLogoutContainer {
  width: 100%;
  height: 90vh;
  overflow: scroll;
}

.loginLogoutContainer label, option {
  font-family: ramblaBold, sans-serif;
}

.topLogoContainer img {
  width: 10rem;
}

.loginBackgroundImageContainer {
  position: absolute;
  z-index: 1;
  width: 25%;
  height: 40%;
}

.loginLogoutBox {
  width: 35%;
  height: auto;
  margin: 0 auto 2rem auto;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  border-radius: 0.1rem;
  padding: 2rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 18px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;*/
}

.loginButton {
  margin-top: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.logoArea {
  text-align: center;
}

.logoArea h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.4rem;
  color: #5a5a5a;
}

.logoArea img {
  width: 7rem;
  object-fit: contain;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.loginLogoutBox button {
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0.2rem;
  padding: 0.2rem;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;
  font-size: 0.9rem;
  font-family: avenirBlack, ramblaRegular, sans-serif;
  cursor: pointer;
}

.loginLogoutBox button:hover {
  cursor: pointer;
}

.loginLogoutBox button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.loginLogoutBox button span:after {
  content: "\21E2";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.loginLogoutBox button:hover {
  background-color: #e2e3ff;
  color: #2d7cbd;
}

.loginLogoutBox button:hover span {
  padding-right: 20px;
}

.loginLogoutBox button:hover span:after {
  opacity: 1;
  right: 0;
}

.loginLogoutBox h5 {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #2d7cbd;
  margin-top: 1.5rem;
}

.passwordBox {
  position: relative;
}

.showPasswordBox {
  position: absolute;
  top: 50%;
  right: 2%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Logout tooltip feature */

[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: ramblaRegular, sans-serif;
  text-align: center;

  /*
      Let the content set the size of the tooltips
      but this will also keep them from being obnoxious
      */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #ea3224;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::after {
  top: calc(100% + 10px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 90%;
  transform: translate(-30%, 0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 40%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(40%, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

/* --------------------------------------HOME--------------------------------------------- */
.homeContainer {
  width: 100%;
  position: relative;
}

.greyBgDiv {
  height: 13vh;
  background-color: #ffb400;
}

.homeContentsBox {
  padding: 1rem;
  margin-top: -3rem;
}

.bereavementIconx {
  width: 2.5rem;
  object-fit: contain;
  margin-top: -1rem;
  -webkit-filter: invert(60%);
  filter: invert(60%);
}

.employeeSummaryContainer {
  margin-top: 1rem;
  background-color: #ffffff;
  border-top-left-radius: 1rem;
  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.034),
    0 1px 2px rgba(24, 24, 24, 0.034), 0 2px 2px rgba(10, 10, 10, 0.06),
    0 1px 2px rgba(19, 19, 19, 0.072), 0 1px 2px rgba(31, 31, 31, 0.086),
    0 2px 2px rgba(56, 56, 56, 0.12);
}

.employeeInfoBox {
  /* border: 0.01rem solid lightgray; */
  border-top-left-radius: 1rem;
}

.employeeNameBox {
  display: flex;
  background-color: #0793d9;
  padding: 0.7rem 1rem 0.1rem 1rem;
  border-top-left-radius: 0rem;
  /* border: 0.1rem solid lightgray; */
}

.employeeNameBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 16px;
  color: #ffff;
  padding-left: 1rem;
}

.employeeDaysSummaryBox {
  padding: 2rem;
}

.summarySliderContainer1,
.summarySliderContainer2 {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-column-gap: 0.2rem;
  background-color: lightgray;
}

.summarySliderContainer3 {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-column-gap: 0.2rem;
  background-color: lightgray;
}

.summarySliderContainer3:last-child {
  background-color: rgb(255, 255, 255);
}

.summaryCarouselBox {
  padding: 0.5rem 0;
  border: 1px dashed black;
  margin: 0.5rem;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.summaryCarouselBox h3 {
  color: black;
  font-family: ramblaBold, ramblaBold, sans-serif;
  font-size: 0.9rem;
  text-align: center;
  border-radius: 1rem;
}

.summaryCarouselBox h4 {
  text-align: center;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 0.9rem;
}

.summaryCarouselBox p {
  text-align: center;
  font-family: Menlo, ramblaBold, sans-serif;
  font-size: 0.9rem;
  color: #2f3adb;
}


.requestCard button {
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;
  font-size: 0.9rem;
  font-family: avenirBlack, ramblaRegular, sans-serif;
  border-radius: 0.1rem;
  cursor: pointer;
}

.requestCard button:hover {
  cursor: pointer;
}

.requestCard button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.requestCard button span:after {
  content: "\23f1";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.balance button span:after {
  content: "\231b";
}

.requestCard button:hover {
  background-color: #e2e3ff;
  color: #2d7cbd;
}

.requestCard button:hover span {
  padding-right: 20px;
}

.requestCard button:hover span:after {
  opacity: 1;
  right: 0;
}

.daysOffRequestBalance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.parentContainer {
  margin-top: 1rem;
  background-color: #ffff;
  border-radius: 0.3rem;
  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.034),
    0 1px 2px rgba(24, 24, 24, 0.034), 0 2px 2px rgba(10, 10, 10, 0.06),
    0 1px 2px rgba(19, 19, 19, 0.072), 0 1px 2px rgba(31, 31, 31, 0.086),
    0 2px 2px rgba(56, 56, 56, 0.12);
  padding-bottom: 2rem;
}

.notificationHeaderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0793d9;
  padding: 0.4rem 1rem 0.3rem 1rem;
}

.notificationHeaderBoxInside {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0793d9;
}

.notificationHeaderBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.notificationHeaderBox h5 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.notificationInfo {
  display: grid;
  grid-template: 1fr/ 0.1fr 1fr 0.1fr;
  grid-gap: 0.1rem;
  padding: 1rem 1rem 0 1rem;
  border-bottom: 0.1rem solid lightgray;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.notificationInfo h4 {
  font-size: 1rem;
  font-family: ramblaRegular, ramblaRegular, sans-serif;
}

.notificationInfo p {
  margin-top: -0.6rem;
  font-family: ramblaBold, ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: gray;
}

#cardDates {
  font-size: 0.8rem;
  color: #344ba8;
}

.notificationClearButton {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.notificationClearButton:hover {
  -webkit-transform: rotate(90deg);
  cursor: pointer;
  transform: rotate(90deg);
}

.olderAnnouncementsTrigger {
  padding: 1rem;
  display: grid;
  grid-template: 1fr / 1.5fr 20fr;
  align-items: center;
}

.announcementBoxOne {
  display: grid;
  justify-content: right;
}

.announcementBoxOne h3 {
  color: #ffb400;
  font-family: museoSlab, ramblaBold, sans-serif;
  font-size: 1.4rem;
}

.announcementBoxTwo {
  display: grid;
  grid-template: 1fr / 1fr 20fr;
  padding: 0 0 0 0.4rem;
}

.announcementBoxTwo h4{
  color: rgb(41, 41, 41);
}

.olderAnnouncementsTrigger h4 {
  font-size: 0.9rem;
  font-family: ramblaBold, ramblaRegular, sans-serif;
  padding-right: 0.4rem;
}

.olderNotificationTriggerButton {
  font-size: 0.8rem;
   color: rgb(41, 41, 41);
}

.gridBox {
  display: grid;
  grid-template: 1fr / 1fr 1.5fr;
  grid-column-gap: 2rem;
}

.scroll-list {
  overflow-y: scroll;
  position: relative;
}

.scroll-icons {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.scroll-up,
.scroll-down {
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-up:hover,
.scroll-down:hover {

}

.employeesOutHeaderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2f3adb;
  padding: 0.3rem;
}

.employeesOutHeaderBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.employeesOutBox {
  padding: 1rem;
  height: 35vh;
  overflow-y: auto;
}

.employeesOutInfoBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 0.9rem;
  text-transform: uppercase;
}

.employeesOutInfo {
  display: flex;
}

.employeesOutInfo p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 0.9rem;
  padding-right: 0.5rem;
}

.employeesOutInfo h5 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 0.7rem;
  text-transform: uppercase;
  background-color: #13cf22;
  padding: 0.2rem;
  border-radius: 0.1rem;
  /* margin-top: -0.7rem; */
  height: 1.3rem;
}

.employeesOutCircleIcon {
  font-size: 0.5rem;
}

.trainingListContainer {
  margin-top: 1rem;
}

.trainingListHeader {
  display: flex;
  justify-content: space-between;
  background-color: #0793d9;
}

.trainingListHeader h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem 0 1rem;
}

.trainingListHeader p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #2f3adb;
  font-size: 1rem;
  padding-right: 1rem;
}

.trainingListHome {
  border-bottom: 0.1rem solid lightgray;
  margin-bottom: 0.5rem;
  padding: 0 1rem 0 1rem;
}

.trainingListHome h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1.2rem;
}

.trainingListHome p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.9rem;
}

.myHomeLink {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0.5rem 1rem 0 1rem;
}

.myHomeLink p {
  color: white;
}

.myHomeLink:hover {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.trainingListStatus {
  display: flex;
}

.trainingListStatus p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  padding: 0 1rem 0 0.5rem;
}

.calendarIcon {
  font-size: 1rem;
}

.trainingListStatus h5 {
  /* background-color: #00d300; */
  color: #00d300;
  /* border: 0.01rem solid #13cf22; */
  border-radius: 1rem;
  text-transform: uppercase;
  /* height: 1.2rem; */
  /* padding: 10.6 4.5rem;
  padding-top:0.4rem ;
  padding-bottom:1.7rem ; */
}

.celebrationsBoxHeader {
  display: flex;
  justify-content: space-between;
  background-color: #e55a5f;
  padding: 0 0 0.3rem 0;
}

.celebrationsBoxHeader h4,
.allCelebrationsBoxHeader h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 1rem;
  padding: 1rem 0 0 1rem;
}
.viewAllCelebrations {
  padding: 0.4rem 1.5rem 0 1rem;
}

.allCelebrationsBoxHeader {
  background-color: #2f3adb;
}

.allCelebrationsBoxHeader h4 {
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: #ffff;
  font-size: 1.1rem;
}

.celebrationList {
  display: grid;
  grid-template: 1fr / 0.2fr 1fr 0.2fr;
  border-bottom: 0.1rem solid lightgray;
  margin-bottom: 0.5rem;
  padding: 0 1rem 0 1rem;
}

.celebrationListToday {
  display: grid;
  grid-template: 1fr / 0.2fr 1fr 0.2fr;
  border-bottom: 0.1rem solid lightgray;
  margin-bottom: 0.5rem;
  padding: 0 1rem 0 1rem;
  color: #2f3adb;
}

.celebrationInfo > div {
  align-items: center;
}

.celebrationInfo h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #2f3adb;
  font-size: 1rem;
}

.celebrationInfo p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 0.7rem;
  margin-top: -0.5rem;
  text-transform: uppercase;
}

.companyLinksContainer {
  margin-top: 1rem;
}

.companyLinksHeader {
  display: flex;
  background-color: #2b2b2b;
  padding: 1rem 0 0.3rem 1rem;
}

.companyLinksHeader h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: white;
  font-size: 1rem;
  padding-left: 1rem;
}

.companyLinksBox {
  background-color: #ffff;
  border-radius: 0.3rem;
  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.034),
    0 1px 2px rgba(24, 24, 24, 0.034), 0 2px 2px rgba(10, 10, 10, 0.06),
    0 1px 2px rgba(19, 19, 19, 0.072), 0 1px 2px rgba(31, 31, 31, 0.086),
    0 2px 2px rgba(56, 56, 56, 0.12);
}

.companyLink {
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem 0 1rem;
  border-bottom: 0.1rem solid lightgray;
}

.companyLink h4 {
  font-family: Avenir, ramblaRegular, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 1rem;
}

.companyLink p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: #2f3adb;
  font-size: 0.9rem;
  margin-top: -0.6rem;
}

.allCelebrationsCardBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.allCelebrationsCardBox > div {
  height: 3.5rem;
  padding: 0.5rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.allCelebrationsButtonBox {
  margin: 0 0.5rem 0 0.5rem;
}

.allTrainingsCardBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.allTrainingsCardBox > div {
  padding: 0.5rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

/* Modals CSS */
.modalContainer {
  padding: 0.5rem;
  margin-top: 1rem;
  border: 0.01rem solid lightgray;
}

.modalHeader {
  display: flex;
  margin-top: 1rem;
  border-bottom: 0.01rem solid lightgray;
}

.modalHeader h4 {
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 1rem;
  padding-left: 1rem;
}

/* grid areas for request days off modal */
.dateRange {
  grid-area: date;
}
.selectDayType {
  grid-area: dayType;
}
.selectDaysAmount {
  grid-area: daysAmount;
}
.textAreaNotes {
  grid-area: notesTextArea;
}

/* grid-areas for calculate days off balance modal */
.selectDayOffType {
  grid-area: selectDayOffType;
}
.pickDate {
  grid-area: pickDate;
}
.balanceOfDays {
  grid-area: balanceOfDays;
}

.selectDaysAmount p,
.balanceOfDays p {
  font-size: 0.95rem;
  font-family: ramblaRegular, sans-serif;
}

.modalDetails {
  margin-top: 1rem;
  display: grid;
  grid-template-areas:
    "date dayType"
    "daysAmount daysAmount"
    "notesTextArea notesTextArea";
  grid-gap: 0.3rem;
}

.modalBalanceDetails {
  margin-top: 1rem;
  display: grid;
  grid-template-areas:
    "selectDayOffType pickDate"
    "balanceOfDays balanceOfDays";
  grid-gap: 0.3rem;
}

.addHolidayModalDetails {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.3rem;
  margin-top: 1rem;
}

.codeGeneratorModalDetails {
  margin-top: 1rem;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.3rem;
}

.codeGeneratedBox {
  padding: 0.3rem 0 0.3rem 0;
}

.codeGeneratedBox h3 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #ea3224;
}

.codeGeneratedBox h2 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  color: rgb(78, 78, 78);
}

.modalDetails > div,
.modalBalanceDetails > div,
.codeGeneratorModalDetails > div,
.addHolidayModalDetails > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.modalDetails textarea {
  width: 100%;
  height: 5rem;
  border: 0.01rem solid lightgray;
  font-family: ramblaBold, ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #2f3adb;
}

.modalButton {
  padding: 10px;
  background-color: #2d7cbd;
  border-radius: 10px;
  border: none;

}

.modalButton button {
  width: 25%;
  background-color: #2d7cbd; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  outline: none;
  padding: 0.2rem;
  font-family: avenirBlack, ramblaRegular, sans-serif;
  border-radius: 0.1rem;
  cursor: pointer;
}

.modalButton button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.modalButton button span:after {
  content: "\21e2";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.daysBalance button span:after {
  content: "\231b";
}

.generateCodeBtn button {
  height: 2rem;
  border: none;
  outline: none;
  border-radius: 0.1rem;
}

.generateCodeBtn button span:after {
  content: "\21e2";
}

.modalButton button:hover {
  background-color: #e2e3ff;
  color: #2d7cbd;
  transition: all 0.5s;
}

.modalButton button:hover span {
  padding-right: 25px;
}

.modalButton button:hover span:after {
  opacity: 1;
  right: 0;
}

/* Input type css */
.inputType input {
  border: 0.01rem solid #b1b1b1;
  font-size: 0.9rem;
  width: 100%;
  padding-left: 0.5rem;
  height: 2rem;
  border-radius: 0.05rem;
  color: #323233;
  outline: #2f3adb;
  font-family: ramblaRegular, sans-serif;
  background-color: #fcfcfc;
}

.inputType input:focus,
.inputTypeTextArea textarea:focus {
  transition: all 0.5s;
  border: 0.01rem solid #2f3adb;
}

.inputType p,
.inputTypeTextArea p,
.inputTypePickDate p {
  font-family: ramblaRegular, sans-serif;
}

.inputTypeTextArea textarea {
  width: 100%;
  height: 5rem;
  border: 0.01rem solid lightgray;
  font-family: ramblaBold, ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #1a27db;
  padding-left: 0.5rem;
  outline: #2f3adb;
}

/* Admin Alert message css */
.adminAlert {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #ea3224;
  text-align: center;
  margin: 0.5rem;
}

.adminAlertError {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #ea3224;
  text-align: center;
  margin: 0.5rem;
}

/* ------------------------------------------PROFILE--------------------------------------- */
.profileContainer {
  width: 100%;
}

.profileContact p {
  color: #000000;
}

.profileHeader {
  display: grid;
  grid-template: 1fr / 1fr 4fr;
  background-color: #ffb400;
  padding: 0.3rem;
}

.profileProfilePhoto {
  position: relative;
}

.profileDP {
  position: absolute;
  top: 25%;
  left: 35%;
}

.profileEditBox {
  position: absolute;
  bottom: -25%;
  right: 30%;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  display: grid;
  place-items: center;
  transition: all 0.5s;
}

.profileEdit {
  font-size: 1.2rem;
  color: #ffffff;
}

.profileEditBox:hover {
  cursor: pointer;
  background-color: #e2e3ff;
  transition: all 0.5s;
  color: #2d7cbd;
}

.profileName h4 {
  font-size: 2rem;
  color: #ffff;
  font-family: avenirBlack, ramblaRegular, sans-serif;
  margin-top: 1rem;
}

.profileContent {
  /* display: grid; */
  /* grid-template: 1fr / 1fr 4fr; */
}

.profileInfoBox {
  display: grid;
  grid-template: 2.5rem / 1fr;
  min-height: 100vh;
  background-color: #ececec;
}

.topColorBar {
  background-color: #0793d9;
}

.profileInfoArea {
  padding: 0.5rem;
}

.profileInfoList {
  border-bottom: 0.01rem solid lightgray;
  padding: 2rem;
}

.profileInfoList p {
  color: #181515;
}

.profileContact {
  display: flex;
}

.profileContact p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  padding-left: 0.5rem;
}

.profileHireInfo h4 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #0793d9;
}

.profileHireInfo p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  text-transform: capitalize;
  color: #ffb400;
}

.profileAddressInfo {
  display: flex;
}

.profileAddressInfo p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  color: rgb(78, 78, 78);
}

.profileAddressInfo p a {
  text-decoration: underline;
}

.profileAddressInfo p a:hover {
  cursor: pointer;
}

.tabInfoContainer {
  padding: 0.5rem;
}

.tabInfoHeaderBox {
  display: flex;
  border-bottom: 0.02rem solid lightgray;
}

.tabInfoHeaderBox h4 {
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1.2rem;
  padding-left: 1rem;
}

.addDoctorCertificate {
  font-family: ramblaRegular, sans-serif;
}

.infoBox {
  margin-top: 0.5rem;
    margin-right: 0.3rem;
}

.infoHeader {
  display: flex;
  justify-content: space-between;
}

.infoHeader h4 {
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(78, 78, 78);
  font-size: 1rem;
}

/* Personal tab to start here */
.empID {
  grid-area: empID;
}
.empStatus {
  grid-area: empStatus;
}
.empName {
  grid-area: empName;
}
.empDept {
  grid-area: empDept;
}
.empTitle {
  grid-area: empTitle;
}
.empDOB {
  grid-area: empDOB;
}
.empGender {
  grid-area: empGender;
}
.empMarital {
  grid-area: empMarital;
}
.empNIDA {
  grid-area: empNIDA;
}
.empSSN {
  grid-area: empSSN;
}
.empEmail {
  grid-area: empEmail;
}
.empAddress {
  grid-area: empAddress;
}

.empPersonalEmail {
  grid-area: empPersonalEmail;
}
.empPhone {
  grid-area: empPhone;
}

.empEmergencyName {
  grid-area: empEmergencyName;
}
.empEmergencyPhone {
  grid-area: empEmergencyPhone;
}
.empEmergencyAddress {
  grid-area: empEmergencyAddress;
}

.personalBasicInfoBox {
  display: grid;
  grid-template-areas:
    "empID empStatus"
    "empName empName"
    "empDept empTitle"
    "empDOB empDOB"
    "empGender empMarital"
    "empNIDA empSSN"
    "empEmail empAddress";
  grid-gap: 0.5rem;
  width: 60%;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.3rem 0 0.3rem 0;
  margin-bottom: 1rem;
}

.personalBasicInfoBox p,
.personalEmergencyContactInfoBox p,
.personalOtherInfoBox p {
  font-size: 0.9rem;
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(82, 82, 82);
}

.personalBasicInfoBox h4,
.personalEmergencyContactInfoBox h4,
.personalOtherInfoBox h4 {
  /* border: 0.01rem solid lightgray; */
  border-radius: 0.1rem;
  padding: 0.2rem 0.1rem 0.2rem 0.2rem;
  /* background-color: #ebecff; */
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  margin-top: -0.5rem;
  font-size: 1rem;
  font-family: ramblaBold, ramblaRegular, sans-serif;
  /* text-transform: capitalize; */
}

.personalOtherInfoBox {
  display: grid;
  grid-template-areas: "empPersonalEmail empPhone";
  grid-gap: 0.5rem;
  width: 60%;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.3rem 0 0.3rem 0;
  margin-bottom: 1rem;
}

.personalEmergencyContactInfoBox {
  display: grid;
  grid-template-areas:
    "empEmergencyName empEmergencyName"
    "empEmergencyPhone empEmergencyAddress";
  grid-gap: 0.5rem;
  width: 60%;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.3rem 0 0.3rem 0;
  margin-bottom: 1rem;
}

/* Job tab to start here */

.compensationDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 0.05fr 0.5fr 1fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.compensationDetailsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.compensationDetailsValuesBox {
  display: grid;
  grid-template: 1fr / 0.05fr 0.5fr 1fr 0.5fr 0.5fr;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  margin-top: 0.05rem;
}

.compensationDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding-top: 0.5rem;
}

/* Days Off tab to start here */
.daysOffSliderContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  background-color: transparent;
}

.daysOffSliderContainer2 {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-column-gap: 2rem;
  background-color: transparent;
}

.daysOffSliderBox {
  padding: 1rem;
  padding-right: 1rem;
  background-color: #ececec;
  border-radius: 0.3rem;
}

.daysOffSliderBox h5 {
  text-align: center;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 0.9rem;
  color: rgb(78, 78, 78);
}

.daysOffSliderBox h4 {
  text-align: center;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1.2rem;
}

.daysOffSliderBox h3 {
  color: #2f3adb;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 2rem;
  text-align: center;
}

.daysOffSliderBox p {
  text-align: center;
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1.2rem;
  color: #2f3adb;
}

.upcomingDaysOffList {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border-bottom: 0.01rem solid lightgray;
}

.upcomingDaysOffInfoBox {
  padding-left: 1rem;
}

.upcomingDaysOffInfoBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1rem;
}

.daysOffInfo {
  padding-left: 1rem;
  display: flex;
}

.daysOffInfo p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  color: rgb(78, 78, 78);
  padding-left: 0.5rem;
}

.filterBox {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0.5rem 0 1rem 0;
  grid-gap: 0.3rem;
}

.filterBox1 {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1.5fr 0.5fr;
  margin: 0.5rem 0 1rem 0;
  grid-gap: 0.3rem;
  padding: 1em 0.8em;
  border: grey solid 2px;
  border-radius: 0.2em;
}

.filterBox2 {
  display: grid;
  grid-template: 1fr / 0.5fr 0.5fr 1.5fr 1fr 1fr 1fr;
  margin: 0.5rem 0 1rem 0;
  grid-gap: 0.3rem;
  padding: 1em 0.8em;
  border: grey solid 2px;
  border-radius: 0.2em;
}

.filterBox3 {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.7fr 0.5fr 1fr;
  margin: 0.5rem 0 1rem 0;
  grid-gap: 0.3rem;
  padding: 1em 0.8em;
  border: grey solid 2px;
  border-radius: 0.2em;
}

.filterBox4 {
  display: grid;
  grid-template: 1fr / 1.5fr 1.5fr 2fr 1fr 1fr;
  margin: 0.5rem 0 1rem 0;
  grid-gap: 0.3rem;
  padding: 1em 0.8em;
  border: grey solid 2px;
  border-radius: 0.2em;
}

.historyInfoBox {
  margin-top: 2rem;
}

.daysOffHistoryDetailsHeader {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 0.5fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.daysOffHistoryDetailsHeader h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.daysOffHistoryDetailsValues {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 0.5fr 1fr;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  margin-top: 0.05rem;
}

.daysOffHistoryDetailsValues p {
  font-family: ramblaRegular, avenirBlack, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding-top: 0.5rem;
}

/* Documents tab starts here */
.documentListBox {
  margin-top: 1rem;
}

.documentList {
  display: flex;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.5rem 0 0.5rem 0;
}

.documentList p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1rem;
}

.documentList p a {
  color: rgb(41, 41, 41);
}

/* Benefits starts here */
.benefitsDetailsBox {
  margin-bottom: 1rem;
}

.viewEmployeeBenefitsDependentInfo {
  border: 0.15rem solid #15adee;
  /*padding: 0.1rem;*/
  margin-bottom: 0.3rem;
  position: relative;
  margin-top: 0.5rem;
  border-radius: 0.1rem;
}

.benefitsDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.benefitsDetailsHeaderBox > div {
  border-right: 0.1rem solid #8b91ff;
}

.benefitsDetailsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  margin-left: 0.2rem;
  text-align: center;
}

/*.benefitName {*/
/*  grid-area: benefitName;*/
/*}*/
/*.benefitProvider {*/
/*  grid-area: benefitProvider;*/
/*}*/
/*.dateEffective {*/
/*  grid-area: dateEffective;*/
/*}*/
/*.coverage {*/
/*  grid-area: coverage;*/
/*}*/
/*.empePays {*/
/*  grid-area: empePays;*/
/*}*/
/*.emprPays {*/
/*  grid-area: emprPays;*/
/*}*/
/*.empremprDeletePays {*/
/*  grid-area: emprDelete;*/
/*}*/

/*.emptyDiv {*/
/*  grid-area: emptyDiv;*/
/*}*/

.benefitsDetailsValuesBox {
  display: grid;
  /*grid-template-areas:*/
  /*  "benefitName benefitName benefitName benefitName benefitName benefitName benefitName"*/
  /*  "benefitProvider dateEffective coverage empePays emprPays emptyDiv emprDelete";*/
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;

}

.benefitsDetailsValuesBox > div,
.benefitsDependentDetailsValuesBox > div,
.dependentsDetailsHeaderBox > div,
.dependentDetailsValuesBox > div {
  border-right: 0.1rem solid #8b91ff;
  border-bottom: 0.1rem solid #8b91ff;
  /*height: 3rem;*/
}

.benefitsDetailsValuesBox > div:nth-child(-n+2),
.benefitsDependentDetailsValuesBox > div:nth-child(-n+2){
  background-color: #f0f0ff;
}

.benefitsDetailsValuesBox p,
.benefitsDependentDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding: 1rem 0 0.5rem 0;
  /*text-align: left;*/
  text-align: center;
}

.benefitTrashBox {
    width: 35px;
    height: 35px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #fcfcfc;
    border: 0.2rem solid #3294FF;
    float: right;
    display: grid;
    place-items: center;
    /*margin-top: 1rem;*/
    position: absolute;
    right: 1%;
    top: 1.5%;
}

.benefitsDependentDetailsValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
}

.dependentsDetailsBox {
  margin-top: 1rem;
}

.dependentsDetailsBox > div:first-child h4 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1.2rem;
  text-align: center;
}

.dependentsDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.dependentsDetailsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.dependentDetailsValuesBox {
  display: grid;
  grid-template: 2.2rem / 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.dependentDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding: 0.3rem 0 0.3rem 1rem;
}

/* Training starts here */
.trainingsDetailsBox {
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  border-top: 0.5rem solid #2f3adb;
  padding: 1rem;
}

.trainingCardContainer {
  border-top: 1px dashed silver;
  margin: 1rem 0 0 0;
  padding: 1rem 0 0 0;
}

.trainingCardContainer h4 {
  font-family: Avenir, ramblaRegular, sans-serif;
  font-size: 1rem;
  color: black;
}

.trainingCardContainer p {
  font-family: ramblaBold, ramblaBold, sans-serif;
  font-size: 1rem;
  color: darkgrey;
}

.trainingListBox,
.trainingListCompletedBox {
  /*display: flex;*/
  /*align-items: center;*/
  /*height: 2.3rem;*/
  /*margin-bottom: 1rem;*/
  background-color: #fcfcfc;
  padding: 0.4rem;
  border-bottom: 0.01rem solid lightgray;
}

.trainingCompletedBox,
.trainingOngoingBox,
.trainingUpcomingBox {
  display: flex;
  /*align-items: center;*/
}

.trainingScheduleBox {
  display: flex;
  margin-left: 2rem;
}

.trainingScheduleBox p {
  margin: 0 0.5rem 0 0;
  background-color: #f0f0f0;
  padding: 0.2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
}

.trainingListBox h4,
.trainingListCompletedBox h4 {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  color: rgb(41, 41, 41);
  padding: 0 1rem 0 1rem;
}

.trainingListBox p,
.trainingListCompletedBox p {
  font-family: ramblaRegular, ramblaBold, sans-serif;
  font-size: 1rem;
  color: rgb(78, 78, 78);
}

.scheduleBold {
  font-weight: bold;
}

.trainingListCompletedBox h4 {
  text-decoration: line-through;
}

.trainingsScheduledDetailsBox {
  padding-bottom: 1rem;
}

.settingsTrainingDetails {
  margin-top: 1rem;
}

.settingsTrainingDetails h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: #1d2329;
  text-align: center;
}

.trainingsDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.trainingsSettingsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.trainingsDetailsValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.trainingsSettingsDetailsValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.3rem 0 0.3rem 0;
}

.trainingsDetailsValuesBox p,
.trainingsSettingsDetailsValuesBox p,
.viewLoansValuesBox p,
.viewTraineesDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  margin-top: 0.2rem;
}

.viewTraineesHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.viewTraineesDetailsValuesBox {
  display: grid;
  grid-template: 2.2rem / 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.3rem 0 0.3rem 0;
}

.viewLoansBox {
  margin-top: 1rem;
}

.viewLoansBox > div:first-child {
  margin-bottom: 1rem;
}

.viewLoansHeadersBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.viewLoansHeadersBox h5,
.viewTraineesHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.viewLoansValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

/* Assets starts here */
.assetsDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.assetsSettingsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.feedbackSettingsHeaderBox {
  display: grid;
  grid-template: 1fr / 1.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.feedbackSettingsHeaderBox h5,
.assetsDetailsHeaderBox h5,
.trainingsDetailsHeaderBox h5,
.trainingsSettingsHeaderBox h5,
.assetsSettingsHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.assetsDetailsValuesBox {
  display: grid;
  grid-template: 0.5fr / 0.5fr 1fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.assetsDetailsValuesBoxOG {
  display: grid;
  grid-template: 0.5fr / 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.feedbackDetailsValuesBox {
  display: grid;
  grid-template: 0.5fr / 1.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.assetsSettingsValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 0.7fr 0.7fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.assetsDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

/* peoples' asset, here it comes */
.dateReturnedViewBox p {
  /*width: 80%;*/
  /*padding: 0.1rem 0.12rem 0.8rem 0.12rem;*/
  /*-webkit-border-radius: 0.1rem;*/
  /*-moz-border-radius: 0.1rem;*/
  /*border-radius: 0.1rem;*/
  /*margin: 0.1rem auto;*/
  color: rgba(47, 145, 255, 0.95);
  /*background: -webkit-linear-gradient(338deg, rgba(47, 145, 255, 0.59) 0%, rgba(50, 148, 255, 0.85) 1%, rgba(76, 110, 254, 0.85) 100%);*/
  /*background: -o-linear-gradient(338deg, rgba(47, 145, 255, 0.59) 0%, rgba(50, 148, 255, 0.85) 1%, rgba(76, 110, 254, 0.85) 100%);*/
  /*background: -ms-linear-gradient(338deg, rgba(47, 145, 255, 0.59) 0%, rgba(50, 148, 255, 0.85) 1%, rgba(76, 110, 254, 0.85) 100%);*/
  /*background: -moz-linear-gradient(338deg, rgba(47, 145, 255, 0.59) 0%, rgba(50, 148, 255, 0.85) 1%, rgba(76, 110, 254, 0.85) 100%);*/
  /*background: linear-gradient(112deg, rgba(47, 145, 255, 0.59) 0%, rgba(50, 148, 255, 0.85) 1%, rgba(76, 110, 254, 0.85) 100%);*/
}

.dateReturnedViewBox p:hover {
  cursor: pointer;
}

.employmentHeaderBox {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.employmentHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.employmentValuesBox {
  display: grid;
  grid-template: 0.5fr / 0.5fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.employmentValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}


.skillsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.skillsHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.skillsValuesBox {
  display: grid;
  grid-template: 0.5fr / 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.skillsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

.languagesHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.languagesHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.languagesValuesBox {
  display: grid;
  grid-template: 0.5fr / 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.languagesValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

.awardsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.awardsHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.awardsValuesBox {
  display: grid;
  grid-template: 0.5fr / 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.awardsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

.linksHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.linksHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.linksValuesBox {
  display: grid;
  grid-template: 0.5fr / 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.linksValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}


.militaryHeaderBox {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.militaryHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.militaryValuesBox {
  display: grid;
  grid-template: 0.5fr / 0.5fr 1fr 1fr 1fr 2fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
}

.militaryValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}


.interviewsHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.interviewsHeaderBox h5 {
  font-family: ramblaBold, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.interviewsValuesBox {
  display: grid;
  grid-template: 0.1fr 1fr 0.1fr / 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 1rem;
  margin-bottom: 3rem;
}

.interviewsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

.iasRatingsBox {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  border-bottom: 0.01rem solid lightgray;
  height: 10vh;
  
}

.iasRatingsBox h5, p {
  text-align: center;
  padding: 0.5rem 0.2rem 0 0.2rem
}

.iasRatingsBoxHeader {
  background-color: #1D2A5E;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.iasRatingsBoxHeader h5 {
  color: #ffffff;
  font-size: smaller;
}

.iasRatingsBoxValues {
  background-color: black;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.iasRatingsBoxValues p {
  color: #ffb400
}

.generalHeaderBox {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.performInterContainer {
  margin: 1rem;
}

.performInterContainer ol {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem 0;
}

.performInterContainer li {
  list-style: decimal;
  color: #0793d9;
  font-family: ramblaBold, sans-serif;
}

.performInterContainer ol span {
  color: #ffffff;
}

.performInterviewBox {
  display: grid;
  grid-template: 1fr / 5fr 1fr 5fr;
}

.performInterviewBox h5 {
  color: #000000;
  font-family: ramblaBold, sans-serif;
  font-size: 1rem;
  border: 1px dashed black;
  background-color: white;
  text-align: right;
  padding: 0.2rem 2rem;
}

.performInterviewBox h3 {
  color: #ffffff;
  font-family: ramblaBold, sans-serif;
  font-size: 1.2rem;
  border-bottom: 1px dashed white;
  text-align: right;
  padding: 0.2rem 2rem;
}

.performInterviewBox p {
  color: #000000;
  font-family: ramblaBold, sans-serif;
  font-size: 1rem;
  border: 1px dashed black;
  background-color: white;
  text-align: left;
  padding: 0.2rem 2rem;
}

/* --------------------------------------PEOPLE--------------------------------------------- */
.managePeopleBox {
  width: 15%;
  margin: 0.3rem auto;
  text-align: center;
  background: -webkit-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -o-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -ms-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -moz-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
}

.managePeopleBox h2 a {
  text-align: right;
  font-size: 1.1rem;
  text-decoration: underline;
  color: #fcfcfc;
  font-family: ramblaRegular, sans-serif;
}

.managePeopleBox h2 a:hover {
  text-decoration: initial;
}

.peopleBox {
  margin: 0 0.5rem;
}

/* Universal top bar blueish box */
.topBarBox {

  padding: 0.2rem;
  width: 100%;
}

.topBarBox a {
  text-decoration: underline;
  font-family: avenirBlack, sans-serif;
  color: #e4e6ff;
}

.topBarBox h2 {
  font-family: ramblaBold, sans-serif;
  font-size: 1rem;
  color: #f5f5f5;
  text-align: center;
  margin-top: 0.9rem;
  margin-left: 0.4rem;
}

.topBarBox h3 {
  font-family: ramblaBold, sans-serif;
  color: #000000;
  text-align: left;
  margin-top: 0.9rem;
  margin-left: 0.4rem;
}

.peopleRegistryBox {

}

.peopleIconHeadingBox {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}

.peopleIconHeadingBox h4 {
  padding: 0 0.5rem 0 1rem;
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1.2rem;
}

.peopleIconHeadingBox h5 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  /*margin-top: 0.3rem;*/
}

.peopleInfoRegistryBox {
  margin-top: 0.5rem;
  width: 50%;
  margin: 0 auto;
}

.peopleAddEmployeeBox h3 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
}

.peopleAddEmployeeBox button,
.peopleExistingEmployeeBox button {
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.1rem;
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;
  font-size: 0.9rem;
  font-family: avenirBlack, ramblaRegular, sans-serif;
}

.peopleAddEmployeeBox button span,
.peopleExistingEmployeeBox button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.peopleAddEmployeeBox button span:after,
.peopleExistingEmployeeBox button span:after {
  content: "\21e2";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.peopleAddEmployeeBox button:hover,
.peopleExistingEmployeeBox button:hover {
  background-color: #e2e3ff;
  color: #2d7cbd;
  cursor: pointer;
  transition: all 0.5s;
}

.peopleAddEmployeeBox button:hover span,
.peopleExistingEmployeeBox button:hover span {
  padding-right: 20px;
}

.peopleAddEmployeeBox button:hover span:after,
.peopleExistingEmployeeBox button:hover span:after {
  opacity: 1;
  right: 0;
}

.peopleExistingEmployeeBox {
  margin-top: 2rem;
}

.peopleExistingEmployeeHeading h4 {
  font-family: ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1rem;
}

.peopleExistingEmployeeHeading h5 {
  font-family: ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1rem;
}

.peopleSelectUser {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  margin-bottom: 0.5rem;
}

.inputTypeSelect p {
  font-family: ramblaRegular, sans-serif;
  color: black;
}

.peopleInfoBox {
  padding-bottom: 2rem;
}

.peopleInfoBox h4 {
  font-family: avenirBlack, ramblaBold, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 1.2rem;
  text-align: center;
}

.peopleDetailsHeaderBox {
  display: grid;
  grid-template: 1fr / 0.1fr 1fr 1.5fr 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.peopleDetailsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.peopleDetailsValuesBox {
  display: grid;
  grid-template: 1fr / 0.1fr 1fr 1.5fr 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.1rem;
}

.peopleDetailsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding-top: 1rem;
}

.peopleDetailsValuesBox:hover {
  cursor: pointer;
  background: -webkit-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -o-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -ms-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -moz-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
}

.registerEmployeeContainer {
  padding: 1rem;
}

.newEmployeeHeader {
  border-bottom: 0.01rem solid lightgray;
  padding: 0.2rem 0 0.2rem 0;
  text-align: center;
}

.employeeIconHeader {
  display: flex;
  justify-content: center;
}

.employeeEditIconHeader {
  margin-top: 1rem;
}

.employeeEditIconHeader p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
}

.employeeIconHeader h3 {
  font-family: ramblaBold, sans-serif;
  font-size: 1.2rem;
  color: #ffb400;
  margin-left: 1rem;
}

.newEmployeeHeader p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #ffffff;
}

/* grid-areas for new employee */
.newEmpId {
  grid-area: newEmpId;
}
.newEmpDOB {
  grid-area: newEmpDOB;
}
.newEmpGender {
  grid-area: newEmpGender;
}
.newEmpMarital {
  grid-area: newEmpMarital;
}
.newEmpMaritalDate {
  grid-area: newEmpMaritalDate;
}
.newEmpNida {
  grid-area: newEmpNida;
}
.newEmpSSN {
  grid-area: newEmpSSN;
}

.newEmpExpat  {
    grid-area: newEmpExpat;
}
.newEmpExpatInputs {
    grid-area: newEmpExpatInputs;
}

.newEmpAddress {
  grid-area: newEmpAddress;
}
.newEmpPayRate {
  grid-area: newEmpPayRate;
}
.newEmpPer {
  grid-area: newEmpPer;
}
.newEmpPayType {
  grid-area: newEmpPayType;
}
.newEmpPaySchedule {
  grid-area: newEmpPaySchedule;
}
.newEmpEffectiveDate {
  grid-area: newEmpEffectiveDate;
}
.newEmpPhone {
  grid-area: newEmpPhone;
}
.newEmpHomePhone {
  grid-area: newEmpHomePhone;
}
.newEmpWorkEmail {
  grid-area: newEmpWorkEmail;
}
.newEmpPersonalEmail {
  grid-area: newEmpPersonalEmail;
}
.newEmpLinkedinUrl {
  grid-area: newEmpLinkedinUrl;
}
.newEmpHireDate {
  grid-area: newEmpHireDate;
}
.newEmpStatus {
  grid-area: newEmpStatus;
}
.newEmpJobTitle {
  grid-area: newEmpJobTitle;
}
.newEmpDepartment {
  grid-area: newEmpDepartment;
}
.newEmpVacation {
  grid-area: newEmpVacation;
}
.newEmpSick {
  grid-area: newEmpSick;
}
.newEmpBereavement {
  grid-area: newEmpBereavement;
}
.newEmpFMLA {
  grid-area: newEmpFMLA;
}
.newEmpMaternityPaternity {
  grid-area: newEmpMaternityPaternity;
}
.emergencyName {
  grid-area: emergencyName;
}
.emergencyPhoneNumber {
  grid-area: emergencyPhoneNumber;
}
.emergencyAddress {
  grid-area: emergencyAddress;
}
.employeeRole {
  grid-area: employeeRole;
}
.newEmpBankName {
  grid-area: newEmpBankName;
}
.newEmpAccountNumber {
  grid-area: newEmpAccountNumber;
}
.newEmpAccountName {
  grid-area: newEmpAccountName;
}

.newEmployeeInfo {
  padding: 0 0 1rem 0;
  width: 50%;
  margin: 0 auto;
}

.newEmployeeInfo h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: rgb(41, 41, 41);
  text-align: center;
  padding: 1rem 0 0.5rem 0;
}

.newEmpPersonalInfo {
  display: grid;
  grid-template-areas:
    "newEmpId newEmpId"
    "newEmpDOB newEmpGender"
    "newEmpMarital newEmpMaritalDate"
    "newEmpNida newEmpSSN"
    "newEmpExpat newEmpExpat"
    "newEmpExpatInputs newEmpExpatInputs"
    "newEmpAddress newEmpAddress"
    "employeeRole employeeRole"
    "newEmpBankName newEmpBankName"
    "newEmpAccountNumber newEmpAccountNumber"
    "newEmpAccountName newEmpAccountName";
  grid-gap: 0.3rem;
}

.newEmpCompensationInfo {
  display: grid;
  grid-template-areas:
    "newEmpPayRate newEmpPayRate"
    "newEmpPer newEmpPayType"
    "newEmpPaySchedule newEmpEffectiveDate";
  grid-gap: 0.3rem;
}

.newEmpContactsInfo {
  display: grid;
  grid-template-areas:
    "newEmpPhone newEmpHomePhone"
    "newEmpWorkEmail newEmpPersonalEmail"
    "newEmpLinkedinUrl newEmpLinkedinUrl";
  grid-gap: 0.3rem;
}

.emergencyContactsInfo {
  display: grid;
  grid-template-areas:
    "emergencyName emergencyName"
    "emergencyPhoneNumber emergencyAddress";
  grid-gap: 0.3rem;
}

.newEmpJobInfo {
  display: grid;
  grid-template-areas:
    "newEmpHireDate newEmpStatus"
    "newEmpJobTitle newEmpDepartment";
  grid-gap: 0.3rem;
}

.newEmpLeaveInfo {
  display: grid;
  grid-template-areas:
    "newEmpVacation newEmpSick"
    "newEmpBereavement newEmpFMLA"
    "newEmpMaternityPaternity newEmpMaternityPaternity";
  grid-gap: 0.3rem;
}

.newEmpPersonalInfo > div,
.newEmpCompensationInfo > div,
.newEmpContactsInfo > div,
.newEmpJobInfo > div,
.newEmpLeaveInfo > div,
.emergencyContactsInfo > div,
.newEmpBankDetails > div,
.employeeRole {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.newEmpContactsInfo p {
  color: #000000;
}
.newEmpCompensationInfo p {
  color: #000000;
}
.newEmpPersonalInfo p {
  color: #000000;
}

/* manage leaves css starts here */
.manageLeavesContainer {
  padding-bottom: 2rem;
}

.manageLeavesContainer h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.leaveRequestsBox {
  padding: 1rem;
}

.leaveRequestsBox p {
  font-size: 0.9rem;
}

.allLeaveRequestsButtonBox {
  margin-top: 1rem;
}

.manageDaysOffRequestDetailsBox {
  margin-top: 1rem;
}

.manageDaysOffRequestDetailsBox > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.manageLeaveRequestsHeaderBox {
  display: grid;
  grid-template: 1fr / 0.5fr 2fr 0.5fr 0.5fr 2fr 2fr 2fr 1fr 0.65fr;
  background-color: #dadada;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
}

.manageLeaveRequestsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.manageLeaveRequestsValuesBox {
  display: grid;
  grid-template: 3.5rem / 0.5fr 2fr 0.5fr 0.5fr 2fr 2fr 2fr 1fr 0.65fr;
 
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  border-bottom: 1px solid #ccc;
 
}

.manageLeaveRequestsValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
  padding-top: 0.5rem;
}

.leaveDecisionButtonBox {
  margin-bottom: 0.4rem;
}

.leaveDecisionButtonBox button {
  width: 12.3%;
  padding: 0.3rem;
  border-radius: 0.1rem;
  background: transparent;
  border: 0.15rem solid #2d7cbd;
  color: #363636;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  outline: none;
  margin-right: 0.2rem;
  font-family: avenirBlack, ramblaRegular, sans-serif;
}

.leaveDecisionButtonBox button:hover {
  cursor: pointer;
  background-color: #e2e3ff;
  transition: all 0.5s;
  color: #2d7cbd;
}

.viewCodesHeaderBox {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr 1fr 1fr;
  background-image: linear-gradient(
          to right,
          rgba(139, 145, 255, 0.2),
          rgba(139, 145, 255, 0.26),
          rgba(139, 145, 255, 0.31)
  );
  border-radius: 0.2rem;
}

.viewCodesHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.viewCodesHeaderBox > div:first-child,
.codesValuesContainer > div:first-child {
  border-left: 0.1rem solid #8b91ff;
}

.viewCodesHeaderBox > div,
.codesValuesContainer > div {
  border-right: 0.1rem solid #8b91ff;
}

.codesValuesContainer {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr 1fr 1fr;
  border-bottom: 0.1rem solid lightgray;
  background-color: white;
}

.codesValuesContainer p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #212121;
  text-align: center;
  margin: 0.5rem 0 0 0;
}

/* ------------------------------------------------------ FILES --------------------------------------- */
.filesContainer {
  padding-bottom: 2rem;
}

.addCompanyFilesBox {
  width: 50%;
  margin: 0 auto;
  padding-top: 0.5rem;
}

.addFilesBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

/*add company files grid-areas */
.addFileInput {
  grid-area: addFileInput;
}
.addFileSelect {
  grid-area: addFileSelect;
}
.addFileTextArea {
  grid-area: addFileTextArea;
}
.addFileInputFile {
  grid-area: addFileInputFile;
}

.addFilesInfoBox {
  display: grid;
  grid-template-areas:
    "addFileInput addFileInput"
    "addFileSelect addFileTextArea"
    "addFileInputFile addFileInputFile";
  grid-gap: 0.2rem;
}

.addFilesInfoBox > div:nth-child(-n + 4) {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.addFileInputFile {
  text-align: center;
}

.addFileInputFile input[type="file"] {
  font-family: avenirBlack, sans-serif;
  color: rgb(41, 41, 41);
}

.registerButtonContainer {
  margin: 1rem 0 0 0;
}

/* add / save buttons css / job list review button  */
.addFilesBox button,
.addJobOpeningBox button,
.addNotificationBox button,
.newEmployeeInfo button,
.dayOffRequestBox button,
.addHolidaySettingsBox button,
.addTrainingInfoBox button,
.addAssetsInfoBox button,
.peopleAddAssetBox button,
.peopleAddBenefitBox button,
.statusManage button,
.ticketView button,
.popOver button,
.addSupportTicket button,
.assetDateReturnedBox button,
.addAccountInfoBox button,
.trainingsSettingsDetailsValuesBox button,
.allCelebrationsButtonBox button,
.allLeaveRequestsButtonBox button,
.addCandidate button,
.addCandidateReviewBox button,
.clearFilterButton button,
.viewLoansValuesBox button,
.invoicePaymentProceedBox button,
.subscriptionCard button,
.registerButtonContainer button,
.termsButtonBox button,
.addAccountToPackageInfoBox button,
.termsAgreementBox button,
.payrollEmployeesListValuesButton button,
.settingsAddLocation button,
.daysOffHistoryDetailsValues button,
.addDoctorCertificate button{
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.1rem;
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  outline: none;
  font-family: avenirBlack, ramblaRegular, sans-serif;
}

.addFilesBox button:hover,
.addNotificationBox button:hover,
.addJobOpeningBox button:hover,
.newEmployeeInfo button:hover,
.jobValueButton button:hover,
.dayOffRequestBox button:hover,
.addHolidaySettingsBox button:hover,
.addTrainingInfoBox button:hover,
.addAssetsInfoBox button:hover,
.peopleAddAssetBox button:hover,
.peopleAddBenefitBox button:hover,
.statusManage button:hover,
.ticketView button:hover,
.popOver button:hover,
.addSupportTicket button:hover,
.assetDateReturnedBox button:hover,
.addAccountInfoBox button:hover,
.trainingsSettingsDetailsValuesBox button:hover,
.allCelebrationsButtonBox button:hover,
.allLeaveRequestsButtonBox button:hover,
.addCandidate button:hover,
.addCandidateReviewBox button:hover,
.clearFilterButton button:hover,
.viewLoansValuesBox button:hover,
.invoicePaymentProceedBox button:hover,
.subscriptionCard button:hover,
.registerButtonContainer button:hover,
.termsButtonBox button:hover,
.addAccountToPackageInfoBox button:hover,
.termsAgreementBox button:hover,
.payrollEmployeesListValuesButton button:hover,
.settingsAddLocation button:hover,
.daysOffHistoryDetailsValues button:hover,
.addDoctorCertificate button:hover{
  cursor: pointer;
  background-color: #e2e3ff;
  transition: all 0.5s;
  color: #2d7cbd;
}

.addFilesBox button span,
.addNotificationBox button span,
.addJobOpeningBox button span,
.newEmployeeInfo button span,
.jobValueButton button span,
.dayOffRequestBox button span,
.addHolidaySettingsBox button span,
.addTrainingInfoBox button span,
.addAssetsInfoBox button span,
.peopleAddAssetBox button span,
.peopleAddBenefitBox button span,
.statusManage button span,
.ticketView button span,
.popOver button span,
.addSupportTicket button span,
.assetDateReturnedBox button span,
.addAccountInfoBox button span,
.trainingsSettingsDetailsValuesBox button span,
.allCelebrationsButtonBox button span,
.allLeaveRequestsButtonBox button span,
.addCandidate button span,
.addCandidateReviewBox button span,
.clearFilterButton button span,
.viewLoansValuesBox button span,
.invoicePaymentProceedBox button span,
.subscriptionCard button span,
.registerButtonContainer button span,
.termsButtonBox button span,
.addAccountToPackageInfoBox button span,
.termsAgreementBox button span,
.payrollEmployeesListValuesButton button span,
.settingsAddLocation button span,
.daysOffHistoryDetailsValues button span,
.addDoctorCertificate button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.5s;
}

.addFilesBox button span:after,
.addNotificationBox button span:after,
.addJobOpeningBox button span:after,
.newEmployeeInfo button span:after,
.jobValueButton button span:after,
.dayOffRequestBox button span:after,
.addHolidaySettingsBox button span:after,
.addTrainingInfoBox button span:after,
.addAssetsInfoBox button span:after,
.peopleAddAssetBox button span:after,
.peopleAddBenefitBox button span:after,
.statusManage button span:after,
.ticketView button span:after,
.popOver button span:after,
.addSupportTicket button span:after,
.assetDateReturnedBox button span:after,
.addAccountInfoBox button span:after,
.trainingsSettingsDetailsValuesBox button span:after,
.allCelebrationsButtonBox button span:after,
.allLeaveRequestsButtonBox button span:after,
.addCandidate button span:after,
.addCandidateReviewBox button span:after,
.clearFilterButton button span:after,
.viewLoansValuesBox button span:after,
.invoicePaymentProceedBox button span:after,
.subscriptionCard button span:after,
.registerButtonContainer button span:after,
.termsButtonBox button span:after,
.addAccountToPackageInfoBox button span:after,
.termsAgreementBox button span:after,
.payrollEmployeesListValuesButton button span:after,
.settingsAddLocation button span:after,
.daysOffHistoryDetailsValues button span:after,
.addDoctorCertificate button span:after {
  content: "\21e2";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.addFilesBox button:hover span,
.addNotificationBox button:hover span,
.addJobOpeningBox button:hover span,
.newEmployeeInfo button:hover span,
.jobValueButton button:hover span,
.dayOffRequestBox button:hover span,
.addHolidaySettingsBox button:hover span,
.addTrainingInfoBox button:hover span,
.addAssetsInfoBox button:hover span,
.peopleAddAssetBox button:hover span,
.peopleAddBenefitBox button:hover span,
.statusManage button:hover span,
.ticketView button:hover span,
.popOver button:hover span,
.addSupportTicket button:hover span,
.assetDateReturnedBox button:hover span,
.addAccountInfoBox button:hover span,
.trainingsSettingsDetailsValuesBox button:hover span,
.allCelebrationsButtonBox button:hover span,
.allLeaveRequestsButtonBox button:hover span,
.addCandidate button:hover span,
.addCandidateReviewBox button:hover span,
.clearFilterButton button:hover span,
.viewLoansValuesBox button:hover span,
.invoicePaymentProceedBox button:hover span,
.subscriptionCard button:hover span,
.registerButtonContainer button:hover span,
.termsButtonBox button:hover span,
.addAccountToPackageInfoBox button:hover span,
.termsAgreementBox button:hover span,
.payrollEmployeesListValuesButton button:hover span,
.settingsAddLocation button:hover span,
.daysOffHistoryDetailsValues button:hover span,
.addDoctorCertificate button:hover span {
  padding-right: 20px;
}

.addFilesBox button:hover span:after,
.addNotificationBox button:hover span:after,
.addJobOpeningBox button:hover span:after,
.newEmployeeInfo button:hover span:after,
.jobValueButton button:hover span:after,
.dayOffRequestBox button:hover span:after,
.addHolidaySettingsBox button:hover span:after,
.addTrainingInfoBox button:hover span:after,
.addAssetsInfoBox button:hover span:after,
.peopleAddAssetBox button:hover span:after,
.peopleAddBenefitBox button:hover span:after,
.statusManage button:hover span:after,
.ticketView button:hover span:after,
.popOver button:hover span:after,
.addSupportTicket button:hover span:after,
.assetDateReturnedBox button:hover span:after,
.addAccountInfoBox button:hover span:after,
.trainingsSettingsDetailsValuesBox button:hover span:after,
.allCelebrationsButtonBox button:hover span:after,
.allLeaveRequestsButtonBox button:hover span:after,
.addCandidate button:hover span:after,
.addCandidateReviewBox button:hover span:after,
.clearFilterButton button:hover span:after,
.viewLoansValuesBox button:hover span:after,
.invoicePaymentProceedBox button:hover span:after,
.subscriptionCard button:hover span:after,
.registerButtonContainer button:hover span:after,
.termsButtonBox button:hover span:after,
.addAccountToPackageInfoBox button:hover span:after,
.termsAgreementBox button:hover span:after,
.payrollEmployeesListValuesButton button:hover span:after,
.settingsAddLocation button:hover span:after,
.daysOffHistoryDetailsValues button:hover span:after,
.addDoctorCertificate button:hover span:after {
  opacity: 1;
  right: 0;
}

.viewCompanyFilesBox {
  padding: 2rem 1rem 2rem 1rem;
}

.companyFilesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
}

.employeeFilesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

.companyFilesBox h2 {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: rgb(41, 41, 41);
  text-align: center;
}

.filesHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 2fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.employeeFilesHeaderBox {
  display: grid;
  grid-template: 1fr / 1.5fr 0.7fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.filesHeaderBox h5,
.employeeFilesHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.filesValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 2fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
}

.employeeFilesValuesBox {
  display: grid;
  grid-template: 1fr / 1.5fr 0.7fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
}

.filesValuesBox h4,
.employeeFilesValuesBox h4 {
  text-align: center;
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  padding-top: 1rem;
  color: rgb(41, 41, 41);
}

.filesValuesBox p,
.employeeFilesValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding-top: 1rem;
}

/* -----------------------------------NOTIFICATION---------------------------------- */
.notificationContainer {
  padding-bottom: 2rem;
}

.notificationManageBox {
  width: 50%;
  margin: 0 auto;
  padding-top: 0.5rem;
}

.addNotificationBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

/* add notification grid-areas */
.addNotyTitle {
  grid-area: addNotyTitle;
}
.addNotyTextArea {
  grid-area: addNotyTitle;
}
.addNotyDate {
  grid-area: addNotyDate;
}

.addNotificationInfoBox {
  display: grid;
  grid-template-areas:
    "addNotyTitle addNotyTitle"
    "addNotyTextArea addNotyDate";
  grid-gap: 0.2rem;
}

.addNotificationInfoBox > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.notificationViewBox {
  width: 80%;
  margin: 0 auto;
  margin-top: 0.5rem;
  background-color: rgb(235, 235, 235);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.viewNotificationCard {

}

/* ------------------------------------HIRING------------------------------------------- */
.hiringContainer {
  padding-bottom: 2rem;
}

.childHeaderBarBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #f5f5f5;
  background-color: transparent;

}

.childHeaderBarBox h3 {
  font-family: ramblaBold, sans-serif;
  padding: 0 0 0.4rem 0.1rem;
}

/* .hiringHeaderBox h2 {
  font-family: avenirBlack, sans-serif;
  color: #f5f5f5;
  font-size: 1rem;
  margin-left: 0.4rem;
} */

.manageJobOpeningsBox {
  width: 50%;
  margin: 0 auto;
  padding-top: 0.5rem;
}

.addJobOpeningBox p {
  color: #000000;
  font-family: ramblaBold, sans-serif;
}

.addJobOpeningBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.addCandidateReviewBox {
  width: 60%;
  margin: 1rem auto;
}

.candidateName {
  grid-area: candidateName;
}
.candidateEmail {
  grid-area: candidateEmail;
}
.candidatePhone {
  grid-area: candidatePhone;
}
.candidateAddress {
  grid-area: candidateAddress;
}
.candidateSource {
  grid-area: candidateSource;
}

.addCandidateInfoBox {
  display: grid;
  grid-template-areas:
    "candidateName candidateName"
    "candidateEmail candidatePhone"
    "candidateAddress candidateSource";
  grid-gap: 0.3rem;
}

.addCandidateInfoBox > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.candidatesHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.candidatesHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.candidatesValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 0.01rem solid lightgray;
  padding: 0.1rem;
}

.candidatesValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
  padding-top: 1rem;
}

.candidateListBox {
  margin: 1rem;
}

.candidateListBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.addCandidateReviewBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.jobPostStatusContainer{
  display: flex;

}

.jobPostDeptContainer{
  display: flex;

}

/* add job post grid areas */
.jobPostTitle {
  grid-area: jobPostTitle;
}
.jobPostStatus {
  grid-area: jobPostStatus;
}
.jobPostDept {
  grid-area: jobPostDept;
}
.jobPostEmpType {
  grid-area: jobPostEmpType;
}
.jobPostExperience {
  grid-area: jobPostExperience;
}
.jobPostDescription {
  grid-area: jobPostDescription;
}
.jobPostAddress {
  grid-area: jobPostAddress;
}
.jobPostCompensation {
  grid-area: jobPostCompensation;
}

.addJobOpeningInfoBox {
  display: grid;
  grid-template-areas:
    "jobPostTitle jobPostTitle"
    "jobPostStatus jobPostDept"
    "jobPostEmpType jobPostExperience"
    "jobPostDescription jobPostDescription"
    "jobPostAddress jobPostAddress"
    "jobPostCompensation jobPostCompensation";
  grid-gap: 0.2rem;
}

.addJobOpeningInfoBox > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
}

.container1{
  display: flex;
}
.container2{
  display: flex;
}
.container3{
align-items: center;
justify-content: center;
}
.container4{
text-align: center;

}
.viewJobOpeningsBox {
  margin-top: 1rem;
}

.viewJobOpeningListBox {
  padding: 1rem;
}

.viewJobOpeningListBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.jobOpeningListHeaderBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
  justify-content: center;
}

.jobOpeningListHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.jobOpeningListValuesBox {
  display: grid;
  grid-template: 2.2rem / 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 0.01rem solid lightgray;
  padding-top: 0.3rem;
}

.jobOpeningListValuesBox p {
  font-family: ramblaRegular, ramblaRegular, sans-serif;
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
}

.characterLimit {
  margin: 0 0.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

/*.jobOpeningListValuesBox button {*/
/*width: 70%;*/
/*height: 1.8rem;*/
/*padding: 0.1rem;*/
/*border-radius: 0.1rem;*/
/*background: transparent;*/
/*border: none;*/
/*color: white;*/
/*margin-left: 1.5rem;*/
/*text-decoration: none;*/
/*display: inline-block;*/
/*outline: none;*/
/*font-size: 0.9rem;*/
/*font-family: avenirBlack, ramblaRegular, sans-serif;*/
/*}*/

/*.jobOpeningListValuesBox button:hover {*/
/*cursor: pointer;*/
/*background-color: #e2e3ff;*/
/*transition: all 0.5s;*/
/*color: #2d7cbd;*/
/*}*/

/*.jobOpeningListValuesBox button span {*/
/*cursor: pointer;*/
/*display: inline-block;*/
/*position: relative;*/
/*transition: all 0.5s;*/
/*}*/

/*.jobOpeningListValuesBox button span:after {*/
/*content: '\21e2';*/
/*position: absolute;*/
/*opacity: 0;*/
/*top: 0;*/
/*right: -20px;*/
/*transition: 0.5s;*/
/*}*/

/*.jobOpeningListValuesBox button:hover span {*/
/*padding-right: 20px;*/
/*}*/

/*.jobOpeningListValuesBox button:hover span:after{*/
/*opacity: 1;*/
/*right: 0;*/
/*}*/

/* -----------------------------------------------------------SETTINGS ----------------------------------------------- */
.settingsContainer {
  padding-bottom: 2rem;
}

.settingsHeaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f5f5;
  background-color: transparent;
}

.addTrainingSettingsBox,
.addHolidaySettingsBox,
.settingsAssetsBox,
.addAccountInfoBox,
.settingsSubscriptionBox,
.settingsLocationBox {
  width: 80%;
  margin: 0 auto;
  padding-top: 1rem;
}

.settingsAssetsBox{
  align-items: center;
  justify-content: center;
}

.settingsTrainingBox {
  margin-top: 1rem;
}

.settingsTrainingBox h4,
.settingsEnquiriesContainer h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.addHolidaySettingsBox h4,
.addTrainingSettingsBox h4,
.addAssetsSettingsBox h4,
.chooseSubscriptionBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.addTrainingSettingsBox h3,
.addAssetsSettingsBox h3 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  text-align: center;
}

.addTrainingSettingsBox,
.addAssetsSettingsBox {
  /* text-align: center; */
  margin-top: 1rem;
}

.addTrainingInfoBox > div:nth-child(-n + 3),
.addAssetsInfoBox > div:nth-child(-n + 3),
.addAccountInfoBox > div:nth-child(-n + 8),
.settingsAddLocation > div:nth-child(-n+2){
  padding: 0.5rem;
  border-radius: 0.1rem;
  margin: 0.3rem 0 0.3rem 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.addAssetsInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
 
}


.viewHolidaysBox,
.viewTrainingsBox {
  margin: 1rem 0.5rem 1rem 0;
}

.viewHolidayBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

.viewHolidaysBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: #1d2329;
  text-align: center;
}

.holidayValuesBox {
  position: relative;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  padding: 0.5rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
}

.holidayValuesBox p {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: #8b9db1;
  text-align: center;
}

.trashIcon {
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  color: #ea3224;
}

.trashIcon2 {
  text-align: center;
  color: #ea3224;
}

.trashIcon:hover,
.trashIcon2:hover {
  cursor: pointer;
  -webkit-animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  -o-animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.editIcon {
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  color: #ffffff;
}

.editIcon2 {
  text-align: center;
  color: #ffffff;
}

.editIcon:hover,
.editIcon2:hover {
  cursor: pointer;
  -webkit-animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  -o-animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  animation: shake 0.52s cubic-bezier(0.3, 0.04, 0.19, 0.9) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/*  Account info starts here */
.settingsAccountInfoBox {
  margin-top: 1rem;
}

.settingsAccountInfoBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.settingsEnquiriesContainer {
  width: 98%;
  margin: 1rem auto;
}

.enqName { grid-area: enqName;}
.enqPhone { grid-area: enqPhone;}
.enqEmail { grid-area: enqEmail;}
.enqCoName { grid-area: enqCoName;}
.enqTitle { grid-area: enqTitle;}
.enqNotes { grid-area: enqNotes;}

.enquiryBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
}

.viewHolidaysBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1rem;
  color: #1d2329;
  text-align: center;
}

.enquiryValuesBox {
    display: grid;
    grid-template-areas:
        'enqName enqName'
        'enqPhone enqEmail'
        'enqCoName enqTitle'
        'enqNotes enqNotes'
;
  /*position: relative;*/
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  padding: 0.2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
}

.enquiryValuesBox > div:first-child {
    background-color: #2f3adb;
    margin-bottom: 0.5rem;
}

.enquiryValuesBox > div:last-child {
    background-color: rgba(187, 184, 219, 0.5);
    padding: 0.3rem;
}

.enquiryValuesBox > div:first-child p {
    margin-top: 1rem;
    font-family: avenirBlack, sans-serif;
    color: #ffffff;
}

.enquiryValuesBox p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #343434;
  text-align: center;
}

#enqHeader {
    font-family: avenirBlack, sans-serif;
    text-decoration: underline;
}

/*------LOCATION---------*/
.settingsAddLocation {
    text-align: center;
}

.addLocationHeader h2 {
    font-family: avenirBlack, sans-serif;
    text-align: center;
    font-size: 1.2rem;
}

.settingsAddLocation select {
    padding: 0.5rem;
    border: 0.1rem solid darkgray;
    -webkit-border-radius: 0.2rem;
    -moz-border-radius: 0.2rem;
    border-radius: 0.2rem;
    font-family: ramblaRegular, sans-serif;
    font-size: 1rem;
}


.viewLocationsBox {
    margin-top: 1rem;
}

.viewLocationsBox > div:first-child h4 {
    font-family: avenirBlack, sans-serif;
    font-size: 1.2rem;
    text-align: center;
}

.branchLocationsHeaderBox {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    background-color: #dadada;
    border-radius: 0.2rem;
}

.branchLocationsHeaderBox > div:nth-child(-n + 3),
.branchLocationsValuesBox > div:nth-child(-n + 3) {
    border-right: 0.1rem solid #8b91ff;
}

.branchLocationsValuesBox {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    border-bottom: 0.1rem solid lightgray;
    background-color: white;
}

.branchLocationsValuesBox p {
    font-family: ramblaRegular, sans-serif;
    font-size: 1rem;
    color: #212121;
    text-align: center;
    margin: 0.5rem 0 0 0;
}

/*---------------------------------------PAYMENTS-----------------------------------*/
.paymentsContainer {
  width: 100%;
}

.paymentsBox {
  width: 50%;
  margin: 1rem auto;
}

.paymentsContainer > div:last-child {
  margin: 1rem;
}

.paymentsBox > div:first-child {
  margin-bottom: 1rem;
}

.paymentsBox h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.paymentsHeadersBox {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  background-color: #dadada;
  border-radius: 0.2rem;
}

.paymentsHeadersBox h5,
.renewalsHeaderBox h5,
.branchLocationsHeaderBox h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding-top: 0.5rem;
  text-align: center;
}

.paymentValuesBox {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
  margin-top: 0.05rem;
}

.paymentValuesBox p,
.renewalsValuesBox p {
  font-family: ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  text-align: center;
  padding-top: 0.5rem;
}

.statusManage > div:nth-child(2) {
  padding: 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  margin: 0.2rem 0 0.2rem 0;
}

.closeStatus {
  text-align: right;
}

.assignTrainingBox {
  font-family: ramblaRegular, sans-serif;
}

.renewalsBox {
    width: 90%;
    margin: 0 auto;
}

.renewalsBox h4 {
    font-family: avenirBlack, sans-serif;
    font-size: 1.2rem;
    text-align: center;
}

.renewalsBox h6 {
    font-family: ramblaRegular, sans-serif;
    font-size: 1.1rem;
    margin-top: 1rem;
}

.renewalsHeaderBox {
    display: grid;
    grid-template: 1fr / 1fr 2fr 2fr 1.5fr 1fr 1fr;
    background-color: #dadada;
    border-radius: 0.2rem;
}

.renewalsValuesBox {
    display: grid;
    grid-template: 1fr / 1fr 2fr 2fr 1.5fr 1fr 1fr;
    background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ebecff);
    margin-top: 0.05rem;
    border-bottom: 0.1rem solid lightgray;
}

.renewalsValuesBox > div:last-child {
    background-color: rgba(255, 204, 0, 0.85);
}

/*-------------------------------------------TICKETS--------------------------------------------*/
.ticketsContainer {
  width: 100%;
}

.ticketsContainer > div:last-child {
  padding: 1rem;
}

.ticketBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

.ticketBox > div {
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.ticketHeaderName {
  grid-area: ticketHeaderName;
}
.ticketServicePriority {
  grid-area: ticketServicePriority;
}
.ticketOpenStatus {
  grid-area: ticketOpenStatus;
}
.ticketCloseTime {
  grid-area: ticketCloseTime;
}
.ticketViewManage {
  grid-area: ticketViewManage;
}

.ticketInfoBox {
  text-align: center;
}

.ticketHeaderName p {
  font-family: avenirBlack, sans-serif;
  font-size: 1.1rem;
  color: #8b9db1;
}

.ticketServicePriority p {
  font-family: avenirBlack, sans-serif;
  font-size: 0.9rem;
  color: #cc3300;
  text-transform: uppercase;
}

.ticketOpenStatus p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #202734;
}

.ticketInfoBox p:nth-child(4),
.ticketInfoBox p:nth-child(5) {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  color: #6c7e92;
}

.ticketModalDetails {
  padding: 0.3rem;
  display: flex;
  border-radius: 0.05rem;
  justify-content: center;
}

.ticketModalDetails h2 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  color: #40485a;
}

.ticketInfoBox > div:last-child {
  margin-top: 1rem;
}

.ticketPriorityHigh {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.3rem;
  color: #cc3300;
  text-transform: capitalize;
  text-decoration: underline;
}

.ticketPriorityMedium {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.3rem;
  color: #ffcc00;
  text-transform: capitalize;
  text-decoration: underline;
}

.ticketPriorityLow {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.3rem;
  color: #339900;
  text-transform: capitalize;
  text-decoration: underline;
}

.mySupportTicketsBox {
  margin: 1rem;
}

.reportsTicketsBox {
  margin: 1.5rem;
}

.openTicketBox {
  width: 60%;
  margin: 1rem auto;
}

.mySupportTicketsBox > div:first-child h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

/*---------------------------------------MOBILE UNAVAILABLE CONTAINER --------------------------------*/
.mobileUnavailableContainer {
  background-image: url(./assets/images/transparent/blue-flower.png);
  background-repeat: no-repeat;
  background-position: top;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgba(47, 58, 219, 0.51);
  overflow: hidden;
  padding: 1rem;
}

.mobileUnavailableLogoArea img {
  width: 5rem;
  object-fit: contain;
  margin-bottom: 2rem;

  -webkit-animation: alternate 3s infinite beat;
  -moz-animation: alternate 3s infinite beat;
  -o-animation: alternate 3s infinite beat;
  animation: alternate 3s infinite beat;
  animation-delay: 0.5s;
}

.mobileUnavailableContainer h4 {
  background: rgba(250, 250, 250, 0.66);
  padding: 0.2rem;
  -webkit-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  border-radius: 0.1rem;
  font-family: avenirBlack, sans-serif;
  font-size: 0.9rem;
  color: #ff2a28;
  text-align: center;
}

.mobileUnavailableContainer p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #282828;
  text-align: center;
}

.mobileUnavailableContainer > div:last-child {
  text-align: center;
}

@keyframes beat {
  0% {
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
    transform: scale(0.85);
  }
  100% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/*--------------------------------------------PAYROLL----------------------------------------------*/
.payrollGatewayButton {
  padding: 0.5rem 2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  border: 0.01rem solid #ea3224;
  color: #ffffff;
  background: -webkit-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -o-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -ms-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: -moz-linear-gradient(
    338deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.59) 0%,
    rgba(50, 148, 255, 0.85) 1%,
    rgba(76, 110, 254, 0.85) 100%
  );
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.payrollGatewayButton:hover {
  color: #171717;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* payroll summary tab starts here */

.payrollEmployeeQuantityBox {
  display: grid;
  place-items: center;
}

.payrollEmployeeQuantityBox > div:first-child {
  display: grid;
  place-items: center;
  width: 180px;
  height: 180px;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 1rem;
  margin-top: 1rem;
  position: relative;
}

.payrollEmployeeQuantityBox > div:first-child h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.8rem;
  /*text-align: center;*/
  color: #373737;
}

.payrollEmployeeQuantityBox > div:first-child p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  color: #242424;
}

.payrollEmployeeQuantityBox > div:first-child > div {
  text-align: center;
}

.payrollEmployeeQuantityBox > div > div:last-child {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /*border: 0.15rem solid #3294ff;*/
  position: absolute;
  bottom: 0;
  right: -2rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  display: grid;
  place-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.payrollSocialBox > div:last-child:hover,
.payrollTaxesBox > div:last-child:hover,
.payrollBenefitsBox > div:last-child:hover {
  cursor: pointer;
  border: 0.1rem solid #3294ff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background: linear-gradient(
    112deg,
    rgba(47, 145, 255, 0.9) 0%,
    rgba(50, 148, 255, 0.15) 1%,
    rgba(76, 110, 254, 0.25) 100%
  );
}

.payrollSummaryDeductionsBox,
.payrollDeductionsBox {
  display: flex;
  justify-content: space-between;
  margin: 3rem 1rem 0 1rem;
  padding-bottom: 1rem;
}

.payrollSummaryDeductionsBox > div,
.payrollDeductionsBox > div {
  width: 40%;
  height: auto;
  margin: 1rem;
  padding: 0.5rem;
}

.payrollSummaryDeductionsBox > div > div:first-child,
.payrollDeductionsBox > div > div:first-child {
  /*background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);*/
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.2rem;
}

.payrollSummaryDeductionsBox > div > div:nth-child(2),
.payrollDeductionsBox > div > div:nth-child(2) {
  border-top: 0.1rem solid #8b91ff;
  padding-top: 1rem;
}

.payrollSummarySocialBox,
.payrollSummaryTaxesBox,
.payrollSummaryBenefitsBox,
.payrollSocialBox,
.payrollTaxesBox,
.payrollBenefitsBox {
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  padding: 0.1rem;
  position: relative;
}

.payrollSummarySocialBox h4,
.payrollSummaryTaxesBox h4,
.payrollSummaryBenefitsBox h4,
.payrollSocialBox h4,
.payrollTaxesBox h4,
.payrollBenefitsBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  color: #545454;
  text-align: center;
  padding: 0.2rem;
}

.payrollSummaryDeductionsBox li,
.payrollDeductionsBox li {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #2f2f2f;
}

.payrollSummarySocialBox > div:last-child,
.payrollSummaryTaxesBox > div:last-child,
.payrollSummaryBenefitsBox > div:last-child {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /*border: 0.15rem solid #3294ff;*/
  position: absolute;
  top: -0.3px;
  left: 50%;
  transform: translate(-50%, -50%);
  /*background-image: linear-gradient(to bottom, #f0f0f0, #eaeaea, #e6e7f9);*/
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
  display: grid;
  place-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -5;
}

.payrollSocialBox > div:last-child,
.payrollTaxesBox > div:last-child,
.payrollBenefitsBox > div:last-child {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /*border: 0.15rem solid #3294ff;*/
  position: absolute;
  top: -0.8rem;
  right: -2rem;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(to bottom, #f0f0f0, #eaeaea, #e6e7f9);
  /*background-image: linear-gradient(to right, rgba(139, 145, 255, 0.2), rgba(139, 145, 255, 0.26), rgba(139, 145, 255, 0.31));*/
  display: grid;
  place-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* payroll Employees Tab Starts here */
.payrollEmployeesContainer,
.payrollDeductionsContainer {
  padding-top: 1rem;
}

.employeesIconBox {
  margin: 0.5rem 0 0.5rem 0;
  display: grid;
  place-items: center;
}

.payrollFilterEmployees {
  width: 30%;
  margin: 0 auto;
  padding: 0.5rem;
  text-align: center;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.payrollEmployeesListBox {
  margin: 1rem 1rem 0 0;
}

.payrollEmployeesListBox > div:first-child h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.1rem;
  text-align: center;
  color: #545454;
}

.payrollEmployeesListHeaders {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr 1fr;
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
}

.payrollEmployeeModalHeader {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr;
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
}

.payrollDeductionModalHeader {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr;
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
}

.payrollEmployeesListHeaders > div:nth-child(-n + 7),
.payrollEmployeesListValues > div:nth-child(-n + 7) {
  border-right: 0.1rem solid #8b91ff;
}

.payrollEmployeesListHeaders h5,
.payrollEmployeeModalHeader h5,
.payrollDeductionModalHeader h5 {
  font-family: avenirBlack, ramblaRegular, sans-serif;
  color: rgb(41, 41, 41);
  font-size: 0.95rem;
  padding: 0.5rem 0 0 0.3rem;
  text-align: center;
}

.payrollEmployeesListValues {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr 1fr;
  border-bottom: 0.1rem solid lightgray;
  background-color: white;
}

.payrollDeductionModalValues {
  display: grid;
  grid-template: 1fr / 1fr 1fr 0.5fr;
  border-bottom: 0.1rem solid lightgray;
}

.payrollEmployeeModalValues {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr 1fr;
  border-bottom: 0.1rem solid lightgray;
}

.payrollEmployeesListValues p,
.payrollEmployeeModalValues p,
.payrollDeductionModalValues p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #212121;
  text-align: center;
  margin: 0.5rem 0 0 0;
}

#digitValue {
  color: green;
  font-size: 0.9rem;
  font-family: museoSlab, ramblaBold, sans-serif;
}

.payrollModalIconBox {
  text-align: center;
}

.payrollModalIconBox h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  color: #212121;
  margin: 0.2rem;
}

.payrollModalInfo > div {
  padding: 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(
    to right,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.26),
    rgba(139, 145, 255, 0.31)
  );
  margin: 0.2rem;
}

.payrollDeductionsIconBox {
  text-align: center;
  margin: 1rem;
}

.payrollDeductionsIconBox h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  color: #545454;
}

.deductionCardBox {
  display: flex;
  justify-content: space-between;
  background-color: #1D2A5E;
  margin: 0 1rem 0.3rem 1rem;
  padding: 0 1rem;
  align-items: center;
}

.deductionCardBox p {
  margin: 0.5rem;
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #ffffff;
}

.deductionCardBox > div {
  border-bottom: 0.01rem solid lightgray;
}

.payrollBenefitCard {
  background-color: #1D2A5E;
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0.3rem 1rem;
  padding: 0 1rem;
  align-items: center;
}

.benefitNameContainer {
  background-color: #E55A5F;
  justify-content: center;
  align-items: center;
}

.payrollBenefitCard p {
  margin: 0.5rem;
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  color: #ffffff;
}

.payrollBenefitCard h5,
.deductionCardBox h5 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
}

/*-------------------------------------INVOICE ---------------------------------------------*/
.invoiceContainer {
  position: relative;
  height: 100vh;
}

.invoiceWrapperBox {
  width: 60%;
  margin: 2rem auto;
  background-image: linear-gradient(
    to bottom,
    rgba(139, 145, 255, 0.2),
    rgba(139, 145, 255, 0.16),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.9)
  );
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.034),
    0 1px 2px rgba(24, 24, 24, 0.034), 0 2px 2px rgba(10, 10, 10, 0.06),
    0 1px 2px rgba(19, 19, 19, 0.072), 0 1px 2px rgba(31, 31, 31, 0.086),
    0 2px 2px rgba(56, 56, 56, 0.12);
}

.invoiceHeaderBox {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.invoiceHeaderBox > div:first-child h4 {
  font-family: "economicaBold", sans-serif;
  font-size: 2.2rem;
  color: #9c3664;
}

.invoiceHeaderBox > div:first-child p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #404040;
}

.invoiceHeaderBox > div:last-child h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #404040;
  text-align: right;
}

.invoiceLogoBox {
  background-image: linear-gradient(
    to right,
    rgba(156, 54, 100, 0.1),
    rgba(156, 54, 100, 0.2),
    rgba(156, 54, 100, 0.1)
  );
  height: 20vh;
  display: grid;
  place-items: center;
}

.invoiceLogoBox img {
  width: 8rem;
  object-fit: contain;
}

.invoiceDetailsBox {
  display: grid;
  grid-template: 1fr / 3fr 1fr;
  grid-gap: 0.3rem;
  padding: 1rem;
}

.invoiceDetailsLeft {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  grid-gap: 0.1rem;
}

.invoiceDetailsLeft > div {
  border-right: 0.01rem dashed darkgray;
  padding: 0.5rem;
}

.invoiceDetailsLeft > div h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 0.9rem;
  text-decoration: underline;
  text-align: center;
  color: #404040;
}

.invoiceDetailsLeft > div p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  text-align: center;
  color: #404040;
}

.invoiceDetailsRight {
  text-align: right;
}

.invoiceDetailsRight > div h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 0.95rem;
  text-decoration: underline;
  color: #404040;
}

.invoiceDetailsRight > div p {
  font-family: ramblaRegular, sans-serif;
  font-size: 0.9rem;
  color: #404040;
}

.invoicePrintBox {
  position: absolute;
  top: 6.5rem;
  right: 6rem;
  z-index: 1;
}

.invoicePaymentProceedBox {
  position: absolute;
  bottom: 0;
  right: 6rem;
}

/* print invoice tooltip feature */

.invoicePrintBox [tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
.invoicePrintBox [tooltip]::before,
.invoicePrintBox [tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

.invoicePrintBox [tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

.invoicePrintBox [tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: ramblaRegular, sans-serif;
  text-align: center;

  /*
      Let the content set the size of the tooltips
      but this will also keep them from being obnoxious
      */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #858aeb;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
.invoicePrintBox [tooltip]:hover::before,
.invoicePrintBox [tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
.invoicePrintBox [tooltip=""]::before,
.invoicePrintBox [tooltip=""]::after {
  display: none !important;
}

/* FLOW: DOWN */
.invoicePrintBox [tooltip][flow^="down"]::after {
  top: calc(100% + 10px);
}

.invoicePrintBox [tooltip][flow^="down"]::before,
.invoicePrintBox [tooltip][flow^="down"]::after {
  left: 55%;
  transform: translate(-30%, 0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 40%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(40%, -50%);
  }
}

/* FX All The Things */
.invoicePrintBox [tooltip]:not([flow]):hover::before,
.invoicePrintBox [tooltip]:not([flow]):hover::after,
.invoicePrintBox [tooltip][flow^="down"]:hover::before,
.invoicePrintBox [tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}
/*--------------------------------------------------------------- SUBSCRIPTION ------------------------------------------------------------------------*/

.chooseSubscriptionBox > div:last-child {

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.chooseSubscriptionBox > div:last-child p {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
}

.subscriptionContainer {
  min-height: 60vh;
}

.subscriptionBody {
  width: 100%;
}

.form-group {
  margin: 0.1rem 0 0 0;
}

.topHeader h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 0.5px;
}

.topHeader h5 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.choosePackageInputBox {
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.cardsContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  margin: 1rem 0;
  background-color: #2b2b2b;
}

.cardsContainer p {
  font-family: ramblaBold, sans-serif;
  text-align: center;
  font-size: 1rem;
  color: #f0f0f0;
}

.cardsContainer h3 {
  font-family: "Rockwell", ramblaBold, sans-serif;
  font-size: 1.5rem;
  text-align: center;
  color: #ecedff;
}

.galaxy {
  background-color: #6d6bfd;
}

.universe {
  background-color: #ff2c72;
}

.subscriptionCard {
  display: grid;
  grid-template: 1fr 2fr 1fr 0.5fr/ 1fr;
  padding: 2rem;
}

.subscriptionCard .title {
  font-family: avenirBlack, sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  color: #fff;
  margin: 2rem 0;
  text-align: center;
}

#subImage {
  width: 6rem;
  height: 6rem;
  align-self: center;
  justify-self: center;
}

.subscriptionCard h1 {
  font-family: Rockwell, sans-serif;
}

.subscribeBtnContainer {
  display: grid;
  place-items: center;
  width: 100%;
}

.policy {

}

.policyHeader {
  background-color: black;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 3rem;
}

.policy img {
  height: 3rem;
  width: 3rem;
}

.viewTermsAndConditions {
  margin-top: 2rem;
}

.viewTermsAndConditions h4 {
  font-family: ramblaBold, sans-serif;
  text-align: center;
  font-size: 1rem;
  color: #373737;
}

.viewTermsAndConditions ol {
  list-style: none;
  counter-reset: li;
  list-style-position: inside;
  font-family: ramblaBold, sans-serif;
  font-size: 0.9rem;
  text-align: center;
}

.viewTermsAndConditions ol li::before {
  content: counter(li);
  color: #f87757;
  display: inline-block;
  margin-top: 1rem;
}

.viewTermsAndConditions ol li {
  counter-increment: li;
}

.termList {
  display: grid;
  grid-template: 1fr / 0.5fr 2fr 1.5fr 0.5fr;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  margin: 0.2rem auto;
  width: 80%;
}

.updatedTermListBox {
  width: 70%;
  margin: 1rem auto;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
}

.updatedTermList {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.termList p,
.updatedTermList p {
  margin-top: 1rem;
  font-family: ramblaBold, sans-serif;
  font-size: 0.95rem;
  text-align: center;
}

/*add account to package*/
.addAccountToPackageHeaderBox h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2rem;
}

.addAccountToPackageInfoBox > div:first-child {
  width: 80%;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ecedff);
}

.addAccountToPackageInfoBox > div:last-child {
  width: 80%;
  margin: 2rem auto;
}

.verifyPackageContainer {
  /*display: grid;*/
  /*place-items: center;*/
  width: 100%;
  margin-top: 2rem;
}

.verifyPackageContainer h4 {
  font-family: avenirBlack, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.clientViewTermsHeaderBox h4,
.clientViewTermsInfoBox h5 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  margin-top: 2rem;
}

.clientViewTermsInfoBox h4 {
  font-family: ramblaRegular, sans-serif;
  font-size: 1rem;
  text-align: center;
  margin-top: 2rem;
}

.clientViewTermsInfoBox {
  width: 60%;
  margin: 0 auto;
}

.termsAgreementBox {
  width: 90%;
  margin: 1rem auto;
}

.termsAgreementBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.3rem;
}

.termsAgreementBox > div:first-child {
  border: 0.15rem solid #ea3224;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
}

.termsAgreementBox > div:last-child {
  border: 0.15rem solid #00d300;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
}

/* -----------------------------------LANDING PAGE CSS--------------------------------------*/

.landingContainer {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white; /* Change from #2b2b2b to white */
}

/* Navigation wrapper */
.navigation {
    height: 48px; /* Match nav height */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* Main content wrapper */
#page-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Body container */
.bodyContainer {
    flex: 1;
    background-size: contain;
    background-position: bottom right;
    transition: all 0.5s;
    background-color: white; /* Add this */
}

/* Landing page specific */
.landingPageContainer,
.joinUsContainer,
.pricingContainer,
.landingContainer,
.bodyContainer {
    background-color: white !important; /* Force white background */
}

/* Style for Sign Up button in mobile menu */
.menu-item.console-button {
    color: #ffffff !important;
}

/* Apple-style Footer */
.apple-style-footer {
    background-color: #f5f5f7 !important; /* Force footer background */
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.footer-content {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 22px;
    box-sizing: border-box; /* Add this */
}

/* Footer Description */
.footer-section {
    padding: 5px 0;
}

.footer-description {
    max-width: 670px;
    margin: 0;
    padding: 0;
    color: #6e6e73;
    font-size: 12px;
    line-height: 1.33337;
}

/* Footer Directory */
.footer-directory {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    padding: 20px 0;
}

.footer-column h3 {
    color: #1d1d1f;
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 600;
    letter-spacing: -.01em;
    margin: 0 0 0.8em;
}

.footer-column ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-column ul li {
    margin-bottom: 0.8em;
}

.footer-column ul li a {
    color: #424245;
    text-decoration: none;
    font-size: 12px;
}

.footer-column ul li a:hover {
    color: #000;
}

/* Social Links */
.social-links a {
    display: flex;
    align-items: center;
    gap: 5px;
}

.social-links svg {
    width: 14px;
    height: 14px;
}

/* Footer Bottom */
.footer-bottom {
    padding: 17px 0;
    border-top: 1px solid #d2d2d7;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-legal {
    display: flex;
    align-items: center;
    gap: 30px;
}

.footer-legal p {
    margin: 0;
    color: #6e6e73;
}

.footer-legal-links {
    display: flex;
    gap: 30px;
}

.footer-legal-links a {
    color: #424245;
    text-decoration: none;
    border-right: 1px solid #d2d2d7;
    padding-right: 10px;
}

.footer-legal-links a:last-child {
    border-right: none;
    padding-right: 0;
}

.footer-locale {
    color: #6e6e73;
}

/* Responsive Design */
@media (max-width: 833px) {
    .footer-directory {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .footer-content {
        width: 100%;
        padding: 0 16px;
        margin: 0; /* Reset margin */
    }
}

@media (max-width: 480px) {
    .apple-style-footer {
        padding: 0; /* Reset padding */
        margin: 0; /* Reset margin */
    }

    .footer-content {
        width: 100%;
        padding: 0 16px;
        margin: 0; /* Reset margin */
    }

    .footer-directory {
        grid-template-columns: 1fr;
        gap: 0;
        padding: 16px 0; /* Adjust padding */
        margin: 0; /* Reset margin */
    }

    .footer-column {
        padding: 15px 0;
        margin: 0; /* Reset margin */
        border-bottom: 1px solid rgba(210, 210, 215, 0.3);
    }

    .footer-bottom {
        padding: 17px 16px; /* Adjust padding */
        margin: 0; /* Reset margin */
    }
}

/* Apple-style Landing Page */
.apple-style-landing {
    background: linear-gradient(180deg, #000 0%, #1a1a1a 100%);
    color: #f5f5f7;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
}

/* Enhanced Hero Section */
.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 44px 0;
    margin: 0 auto;
    max-width: 2560px;
    position: relative;
}

.logo-container {
    margin-bottom: 2rem;
}

.hero-logo {
    max-height: 10rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .hero-logo {
        max-height: 6rem;
        margin-bottom: 1rem;
    }
}

.hero-content {
    z-index: 2;
    padding: 0 20px;
    margin-bottom: 30px;
    position: relative;
}

.main-heading {
    font-size: 56px;
    line-height: 1.07143;
    font-weight: 600;
    letter-spacing: -.005em;
    margin: 0 0 8px;
    background: linear-gradient(90deg, #fff 0%, #f5f5f7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub-heading {
    font-size: 28px;
    line-height: 1.10722;
    font-weight: 400;
    letter-spacing: .004em;
    margin: 0 0 20px;
    color: #86868b;
}

.highlight {
    color: #2997ff;
    font-family: "ramblaBold";
}

/* Enhanced Image Container */
.hero-image-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
}

.image-wrapper {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0,0,0,0.3);
}

.main-image {
    width: 100%;
    height: auto;
    border-radius: 24px;
    transform: translateZ(0);
    backface-visibility: hidden;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
    pointer-events: none;
}

/* Enhanced Get Started Button */
.get-started-button {
    background: #ffb400;
    color: #000;
    padding: 15px 25px;
    border: none;
    border-radius: 980px;
    font-size: 1.5rem;
    font-family: "ramblaBold";
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
    .get-started-button {
        padding: 10px 20px;
        font-size: 1.2rem;
    }
}

/* Enhanced Features Grid */
.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.feature-tile.glass-effect {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 24px;
    padding: 40px 30px;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.feature-tile.glass-effect:hover {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-tile h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
}

.feature-tile p {
    font-size: 17px;
    line-height: 1.47059;
    color: #86868b;
}

/* Responsive Design Enhancements */
@media only screen and (max-width: 834px) {
    .main-heading {
        font-size: 40px;
    }

    .sub-heading {
        font-size: 24px;
    }

    .features-grid {
        grid-template-columns: repeat(2, 1fr);
        padding: 40px 20px;
    }
}

@media only screen and (max-width: 640px) {
    .main-heading {
        font-size: 32px;
    }

    .sub-heading {
        font-size: 19px;
    }

    .features-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .get-started-button {
        width: 100%;
        max-width: 300px;
    }
}

/* Update the hero section layout */
.hero-section-split {
    display: grid;
    grid-template-columns: 45% 55%;
    height: 100vh;
    max-height: 900px;
    align-items: center;
    padding: 0 40px;
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
}

.hero-left {
    padding-right: 40px;
}

.hero-logo {
    max-height: 60px;
    margin-bottom: 2rem;
}

.main-heading {
    font-size: 3.5rem;
    line-height: 1.1;
    margin-bottom: 1rem;
    background: linear-gradient(90deg, #fff 0%, #f5f5f7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.get-started-button {
    margin-top: 2rem;
    padding: 15px 30px;
}

/* Right side interactive container */
.interactive-container {
    position: relative;
    height: 80vh;
    max-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.platform-image-container {
    position: relative;
    width: 90%;
    z-index: 2;
}

.main-platform-image {
    width: 100%;
    height: auto;
    border-radius: 24px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.3);
}

.illustration-top {
    position: absolute;
    top: 5%;
    left: 0;
    z-index: 3;
    transform: translateX(-20%);
}

.illustration-bottom {
    position: absolute;
    bottom: 5%;
    right: 0;
    z-index: 3;
    transform: translateX(20%);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero-section-split {
        grid-template-columns: 1fr;
        height: auto;
        padding: 20px;
        text-align: center;
    }

    .hero-left {
        padding-right: 0;
        margin-bottom: 40px;
    }

    .interactive-container {
        height: 60vh;
    }

    .illustration-top, .illustration-bottom {
        transform: scale(0.8);
    }
}

@media (max-width: 768px) {
    .main-heading {
        font-size: 2.5rem;
    }

    .interactive-container {
        height: 50vh;
    }

    .illustration-top, .illustration-bottom {
        transform: scale(0.6);
    }
}

/* Hero Container */
.hero-container {
    display: grid;
    grid-template-columns: 45% 55%;
    min-height: calc(100vh - 80px); /* Adjust for nav bar */
    align-items: center;
    padding: 100px 40px 40px 40px; /* Add top padding for nav bar */
    max-width: 1440px;
    margin: 0 auto;
  overflow: clip;
}

/* Left Side */
.hero-left {
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
}

.hero-logo {
    height: 50px;
    margin-bottom: 2rem;
    align-self: flex-start; /* Align logo to the left */
}

.hero-content {
    text-align: left; /* Align text to the left */
    width: 100%;
}

.main-heading {
    font-size: 3.2rem;
    line-height: 1.1;
    margin-bottom: 1rem;
    color: #fff;
    text-align: left;
    max-width: 540px; /* Control line length for better readability */
}

.sub-heading {
    font-size: 1.5rem;
    color: #86868b;
    margin-bottom: 2rem;
    text-align: left;
}

.cta-container {
    display: flex;
    justify-content: flex-start; /* Align button to the left */
}

/* Right Side */
.floating-illustrations {
    position: relative;
    height: 450px; /* Reduced height */
    display: flex;
    align-items: center;
    justify-content: center;
}

.platform-image-wrapper {
    width: 85%; /* Slightly smaller */
    max-width: 550px;
    position: relative;
    z-index: 1;
}

.floating-recruiter {
    position: absolute;
    top: 5%;
    left: -5%;
    z-index: 2;
}

.floating-jobseeker {
    position: absolute;
    bottom: 5%;
    right: -5%;
    z-index: 2;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero-container {
        grid-template-columns: 1fr;
        padding: 120px 20px 40px 20px;
        gap: 2rem;
      overflow: clip;
    }

    .hero-left {
        padding-right: 0;
        align-items: center;
    }

    .hero-content {
        text-align: center;
    }

    .main-heading {
        text-align: center;
        max-width: 100%;
    }

    .sub-heading {
        text-align: center;
    }

    .cta-container {
        justify-content: center;
    }

    .floating-illustrations {
        height: 350px;
    }
}

@media (max-width: 768px) {
    .hero-container {
        padding: 10px 20px 30px 20px;
      overflow: clip;
    }

    .floating-illustrations {
        height: 300px;
    }

    .platform-image-wrapper {
        width: 90%;
    }

    .floating-recruiter, .floating-jobseeker {
        transform: scale(0.8);
    }
}

/* Update logo size */
.hero-logo {
    height: 80px; /* Increased from 50px */
    margin-bottom: 3rem;
    align-self: flex-start;
}

/* Computer Frame Container */
.platform-image-wrapper {
    width: 85%;
    max-width: 800px;
    position: relative;
    z-index: 1;
    padding: 20px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 20px;
    box-shadow: 
        0 20px 40px rgba(0,0,0,0.4),
        inset 0 2px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Screen Bezel */
.platform-image-wrapper::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 12px;
    pointer-events: none;
}

/* Camera Dot */
.platform-image-wrapper::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
}

.platform-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    display: block;
}

/* Enhance floating illustrations */
.floating-recruiter, .floating-jobseeker {
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.3));
}

.floating-recruiter {
    top: 0;
    left: -10%;
}

.floating-jobseeker {
    bottom: 0;
    right: -10%;
}

/* Add glow effect to illustrations */
.floating-illustrations svg {
    filter: drop-shadow(0 0 10px rgba(255,180,0,0.2));
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero-logo {
        height: 70px;
    }
    
    .platform-image-wrapper {
        width: 90%;
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .hero-logo {
        height: 60px;
    }
    
    .platform-image-wrapper {
        padding: 10px;
    }
}

/* Larger Logo */
.hero-logo {
    height: 100px; /* Increased size */
    margin-bottom: 3rem;
    align-self: flex-start;
}

/* Enhanced 3D Computer Frame */
.platform-image-wrapper {
    width: 85%;
    max-width: 800px;
    position: relative;
    z-index: 1;
    padding: 25px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 20px;
    box-shadow: 
        0 20px 40px rgba(0,0,0,0.4),
        inset 0 2px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Enhanced Screen Bezel */
.platform-image-wrapper::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border: 2px solid rgba(255,255,255,0.15);
    border-radius: 12px;
    box-shadow: 
        inset 0 0 15px rgba(0,0,0,0.5),
        0 0 8px rgba(255,255,255,0.1);
    pointer-events: none;
}

/* Enhanced Camera Module */
.platform-image-wrapper::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background: linear-gradient(145deg, #1a1a1a, #2b2b2b);
    border-radius: 50%;
    box-shadow: 
        inset 0 1px 3px rgba(0,0,0,0.8),
        0 0 2px rgba(255,255,255,0.2);
}

/* Add Screen Stand */
.platform-image-wrapper .stand {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 40px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 0 0 10px 10px;
    box-shadow: 
        0 10px 20px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.1);
}

.platform-image-wrapper .stand::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 5px;
    background: #1a1a1a;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

/* Screen Power LED */
.platform-image-wrapper .power-led {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4px;
    height: 4px;
    background: #00ff00;
    border-radius: 50%;
    box-shadow: 0 0 5px #00ff00;
    animation: blink 2s infinite;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

/* Screen Reflection */
.platform-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    display: block;
    position: relative;
}

.platform-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(255,255,255,0.1) 0%,
        rgba(255,255,255,0) 20%
    );
    border-radius: 12px;
    pointer-events: none;
}

/* Enhance floating illustrations positioning */
.floating-recruiter {
    top: -10%;
    left: -15%;
    transform: rotate(-5deg);
}

.floating-jobseeker {
    bottom: -10%;
    right: -15%;
    transform: rotate(5deg);
}

/* Add more dramatic shadows */
.floating-illustrations svg {
    filter: 
        drop-shadow(0 10px 20px rgba(0,0,0,0.4))
        drop-shadow(0 0 15px rgba(255,180,0,0.3));
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero-logo {
        height: 80px;
    }
}

@media (max-width: 768px) {
    .hero-logo {
        height: 70px;
    }
}

/* Enhanced Logo Styling */
.hero-logo {
    height: 120px; /* Significantly larger */
    margin-bottom: 3rem;
    align-self: flex-start;
    filter: drop-shadow(0 0 20px rgba(255,180,0,0.2));
    transition: all 0.3s ease;
}

.hero-logo:hover {
    transform: scale(1.02);
    filter: drop-shadow(0 0 30px rgba(255,180,0,0.3));
}

/* Adjust illustration positioning for better balance */
.floating-illustrations {
    position: relative;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
}

.floating-recruiter {
    top: -5%;
    left: -20%;
    transform: rotate(-8deg) translateZ(50px);
}

.floating-jobseeker {
    bottom: -5%;
    right: -20%;
    transform: rotate(8deg) translateZ(50px);
}

/* Enhanced glow effects */
.floating-illustrations svg {
    filter: 
        drop-shadow(0 20px 30px rgba(0,0,0,0.5))
        drop-shadow(0 0 20px rgba(255,180,0,0.4))
        drop-shadow(0 0 40px rgba(41,151,255,0.3));
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero-logo {
        height: 100px;
    }
}

@media (max-width: 768px) {
    .hero-logo {
        height: 80px;
    }
}

/* Modern Mac-style Frame */
.platform-image-wrapper {
    width: 85%;
    max-width: 800px;
    position: relative;
    z-index: 1;
    padding: 20px 20px 25px 20px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 20px;
    box-shadow: 
        0 20px 40px rgba(0,0,0,0.4),
        inset 0 1px 0 rgba(255,255,255,0.1);
    transform-style: preserve-3d;
    perspective: 1000px;
}

/* Notch Design */
.platform-image-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 20px;
    background: #1a1a1a;
    border-radius: 0 0 12px 12px;
    box-shadow: 
        inset 0 -1px 0 rgba(255,255,255,0.1),
        0 1px 2px rgba(0,0,0,0.2);
    z-index: 3;
}

/* Camera and Sensors */
.platform-image-wrapper::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background: linear-gradient(145deg, #1a1a1a, #2b2b2b);
    border-radius: 50%;
    box-shadow: 
        inset 0 1px 3px rgba(0,0,0,0.8),
        0 0 2px rgba(255,255,255,0.2);
    z-index: 4;
}

/* Additional Sensors */
.platform-image-wrapper .sensors {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-25px);
    width: 50px;
    height: 4px;
    display: flex;
    gap: 6px;
    z-index: 4;
}

.platform-image-wrapper .sensors::before,
.platform-image-wrapper .sensors::after {
    content: '';
    width: 4px;
    height: 4px;
    background: #2b2b2b;
    border-radius: 50%;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.8);
}

/* Screen Border */
.screen-border {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 25px;
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.01) 100%);
    box-shadow: 
        inset 0 0 15px rgba(0,0,0,0.5),
        0 0 1px rgba(255,255,255,0.1);
    pointer-events: none;
}

/* Enhanced Stand */
.platform-image-wrapper .stand {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 60px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    box-shadow: 
        0 10px 20px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.1);
}

/* Stand Base */
.platform-image-wrapper .stand::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 4px;
    background: linear-gradient(90deg, #1a1a1a, #2b2b2b, #1a1a1a);
    border-radius: 2px;
    box-shadow: 
        0 2px 4px rgba(0,0,0,0.2),
        inset 0 1px 0 rgba(255,255,255,0.1);
}

/* Power Button */
.power-button {
    position: absolute;
    top: -2px;
    right: 20px;
    width: 30px;
    height: 4px;
    background: #2b2b2b;
    border-radius: 2px;
    box-shadow: 
        inset 0 1px 2px rgba(0,0,0,0.8),
        0 1px 0 rgba(255,255,255,0.1);
}

/* Power LED */
.power-led {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 4px;
    height: 4px;
    background: #00ff00;
    border-radius: 50%;
    box-shadow: 0 0 5px #00ff00;
    animation: breathe 2s infinite;
}

@keyframes breathe {
    0%, 100% { opacity: 1; box-shadow: 0 0 5px #00ff00; }
    50% { opacity: 0.7; box-shadow: 0 0 8px #00ff00; }
}

/* Screen Content */
.platform-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
    position: relative;
}

/* Screen Reflection */
.screen-reflection {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(255,255,255,0.08) 0%,
        rgba(255,255,255,0) 20%
    );
    border-radius: 8px;
    pointer-events: none;
}

/* Adjusted Logo Styling */
.nafasi-hero-logo {
    height: 195px; /* Increased by 50% from 130px */
    margin-bottom: 2.5rem;
    align-self: flex-start;
    filter: drop-shadow(0 0 15px rgba(255,180,0,0.15));
    transition: all 0.3s ease;
}

.nafasi-hero-logo:hover {
    transform: scale(1.02);
    filter: drop-shadow(0 0 20px rgba(255,180,0,0.25));
}

/* Responsive adjustments for logo */
@media (max-width: 1024px) {
    .nafasi-hero-logo {
        height: 165px; /* Increased by 50% from 110px */
    }
}

@media (max-width: 768px) {
    .nafasi-hero-logo {
        height: 135px; /* Increased by 50% from 90px */
        margin-bottom: 0.5rem;
    }
}

/* Adjusted Platform Image Container */
.platform-image-wrapper {
    width: 68%; /* Reduced from 85% by 20% */
    max-width: 640px; /* Reduced from 800px by 20% */
    position: relative;
    z-index: 1;
    padding: 20px 20px 25px 20px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 20px;
    box-shadow: 
        0 20px 40px rgba(0,0,0,0.4),
        inset 0 1px 0 rgba(255,255,255,0.1);
    transform-style: preserve-3d;
    perspective: 1000px;
}

/* Adjust floating illustrations position for smaller main image */
.floating-illustrations {
    position: relative;
    height: 400px; /* Reduced from 500px */
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
}

/* Adjust responsive behavior */
@media (max-width: 1024px) {
    .platform-image-wrapper {
        width: 75%;
    }
}

@media (max-width: 768px) {
    .platform-image-wrapper {
        width: 85%;
    }
    
    .floating-illustrations {
        height: 350px;
    }
}

/* Enhanced Mac-style Frame with more details */
.platform-image-wrapper {
    width: 68%;
    max-width: 640px;
    position: relative;
    z-index: 1;
    padding: 20px 20px 25px 20px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    border-radius: 20px;
    box-shadow: 
        0 25px 50px rgba(0,0,0,0.5),
        0 10px 20px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
    transform-style: preserve-3d;
    perspective: 1000px;
}

/* Enhanced Notch Design */
.platform-image-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 20px;
    background: linear-gradient(180deg, #1a1a1a 0%, #2b2b2b 100%);
    border-radius: 0 0 12px 12px;
    box-shadow: 
        inset 0 -1px 0 rgba(255,255,255,0.1),
        inset 0 1px 2px rgba(0,0,0,0.5),
        0 1px 2px rgba(0,0,0,0.3);
    z-index: 3;
}

/* Enhanced Camera Module */
.platform-image-wrapper::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background: linear-gradient(145deg, #1a1a1a, #2b2b2b);
    border-radius: 50%;
    box-shadow: 
        inset 0 1px 3px rgba(0,0,0,0.8),
        inset 0 -1px 2px rgba(255,255,255,0.1),
        0 0 2px rgba(255,255,255,0.2),
        0 0 4px rgba(0,0,0,0.5);
    z-index: 4;
}

/* Enhanced Screen Border with Lighting */
.screen-border {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 25px;
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 8px;
    background: linear-gradient(
        180deg, 
        rgba(255,255,255,0.05) 0%, 
        rgba(255,255,255,0.02) 50%,
        rgba(0,0,0,0.05) 100%
    );
    box-shadow: 
        inset 0 0 20px rgba(0,0,0,0.5),
        inset 0 0 5px rgba(255,255,255,0.1),
        0 0 1px rgba(255,255,255,0.1);
    pointer-events: none;
}

/* Enhanced Stand with 3D Effect */
.platform-image-wrapper .stand {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 60px;
    background: linear-gradient(145deg, #2b2b2b, #1a1a1a);
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    box-shadow: 
        0 15px 25px rgba(0,0,0,0.4),
        inset 0 1px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Enhanced Stand Base */
.platform-image-wrapper .stand::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 4px;
    background: linear-gradient(
        90deg, 
        rgba(26,26,26,0.8),
        rgba(43,43,43,1),
        rgba(26,26,26,0.8)
    );
    border-radius: 2px;
    box-shadow: 
        0 2px 6px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Enhanced Screen Reflection */
.screen-reflection {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(255,255,255,0.12) 0%,
        rgba(255,255,255,0.05) 15%,
        rgba(255,255,255,0) 30%
    );
    border-radius: 8px;
    pointer-events: none;
}

/* Enhanced Floating Illustrations */
.floating-illustrations svg {
    filter: 
        drop-shadow(0 20px 30px rgba(0,0,0,0.5))
        drop-shadow(0 0 20px rgba(255,180,0,0.4))
        drop-shadow(0 0 40px rgba(41,151,255,0.3))
        drop-shadow(0 10px 15px rgba(0,0,0,0.3));
}

.floating-recruiter,
.floating-jobseeker {
    transition: all 0.3s ease;
}

.floating-recruiter:hover,
.floating-jobseeker:hover {
    filter: brightness(1.2);
    transform: scale(1.05) translateZ(60px);
}

/* Enhanced Mac-style Frame with premium details */
.platform-image-wrapper {
    width: 68%;
    max-width: 640px;
    position: relative;
    z-index: 1;
    padding: 20px 20px 25px 20px;
    background: linear-gradient(
        145deg,
        #2b2b2b 0%,
        #1a1a1a 50%,
        #242424 100%
    );
    border-radius: 20px;
    box-shadow: 
        0 25px 50px rgba(0,0,0,0.5),
        0 10px 20px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.12),
        inset 0 -1px 0 rgba(0,0,0,0.3);
    transform-style: preserve-3d;
    perspective: 1000px;
    border: 1px solid rgba(255,255,255,0.05);
}

/* Metallic Texture Overlay */
.platform-image-wrapper::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
        linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.03) 50%, transparent 100%),
        repeating-linear-gradient(
            0deg,
            transparent 0px,
            transparent 2px,
            rgba(255,255,255,0.01) 3px,
            transparent 4px
        );
    border-radius: 20px;
    pointer-events: none;
}

/* Premium Notch Design */
.platform-image-wrapper .notch {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 20px;
    background: linear-gradient(180deg, #1a1a1a 0%, #2b2b2b 100%);
    border-radius: 0 0 12px 12px;
    box-shadow: 
        inset 0 -1px 0 rgba(255,255,255,0.1),
        inset 0 1px 2px rgba(0,0,0,0.5),
        0 1px 2px rgba(0,0,0,0.3);
    z-index: 3;
    overflow: hidden;
}

/* Notch Texture */
.platform-image-wrapper .notch::after {
    content: '';
    position: absolute;
    inset: 0;
    background: 
        linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.02) 50%, transparent 100%),
        repeating-linear-gradient(
            90deg,
            transparent 0px,
            transparent 4px,
            rgba(0,0,0,0.1) 5px,
            transparent 6px
        );
}

/* Enhanced Camera Module */
.platform-image-wrapper .camera {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    background: radial-gradient(circle at center, #1a1a1a 0%, #2b2b2b 100%);
    border-radius: 50%;
    box-shadow: 
        inset 0 1px 3px rgba(0,0,0,0.8),
        inset 0 -1px 2px rgba(255,255,255,0.1),
        0 0 2px rgba(255,255,255,0.2),
        0 0 4px rgba(0,0,0,0.5);
    z-index: 4;
}

/* Camera Lens Effect */
.platform-image-wrapper .camera::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    background: radial-gradient(circle at center, rgba(0,0,0,0.8) 0%, transparent 100%);
    border-radius: 50%;
}

/* Premium Stand Design */
.platform-image-wrapper .stand {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 60px;
    background: linear-gradient(
        145deg,
        #2b2b2b 0%,
        #1a1a1a 50%,
        #242424 100%
    );
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    box-shadow: 
        0 15px 25px rgba(0,0,0,0.4),
        inset 0 1px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Stand Texture */
.platform-image-wrapper .stand::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
        linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.02) 50%, transparent 100%),
        repeating-linear-gradient(
            45deg,
            transparent 0px,
            transparent 3px,
            rgba(255,255,255,0.01) 4px,
            transparent 5px
        );
}

/* Enhanced Stand Base */
.platform-image-wrapper .stand-base {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 4px;
    background: linear-gradient(
        90deg,
        rgba(26,26,26,0.8) 0%,
        rgba(43,43,43,1) 50%,
        rgba(26,26,26,0.8) 100%
    );
    border-radius: 2px;
    box-shadow: 
        0 2px 6px rgba(0,0,0,0.3),
        inset 0 1px 0 rgba(255,255,255,0.1),
        inset 0 -1px 0 rgba(0,0,0,0.3);
}

/* Screen Border with Premium Effect */
.screen-border {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 25px;
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 8px;
    background: linear-gradient(
        180deg,
        rgba(255,255,255,0.05) 0%,
        rgba(255,255,255,0.02) 50%,
        rgba(0,0,0,0.05) 100%
    );
    box-shadow: 
        inset 0 0 20px rgba(0,0,0,0.5),
        inset 0 0 5px rgba(255,255,255,0.1),
        0 0 1px rgba(255,255,255,0.1);
    pointer-events: none;
    overflow: hidden;
}

/* Screen Border Texture */
.screen-border::after {
    content: '';
    position: absolute;
    inset: 0;
    background: 
        linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.02) 50%, transparent 100%),
        repeating-linear-gradient(
            0deg,
            transparent 0px,
            transparent 1px,
            rgba(255,255,255,0.01) 2px,
            transparent 3px
        );
}

/* Enhanced Power Button */
.power-button {
    position: absolute;
    top: -2px;
    right: 20px;
    width: 35px;
    height: 4px;
    background: linear-gradient(90deg, #242424 0%, #2b2b2b 50%, #242424 100%);
    border-radius: 2px;
    box-shadow: 
        inset 0 1px 2px rgba(0,0,0,0.8),
        0 1px 0 rgba(255,255,255,0.1);
}

/* Power Button Texture */
.power-button::after {
    content: '';
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
        90deg,
        transparent 0px,
        transparent 2px,
        rgba(255,255,255,0.02) 3px,
        transparent 4px
    );
    border-radius: 2px;
}
