.hiringContainer {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.topBarBox {
    margin-bottom: 1rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.childHeaderBarBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.childHeaderBarBox h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
    letter-spacing: -0.02em;
}

.manageJobOpeningsBox {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

.manageJobOpeningsBox:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.viewJobOpeningsBox {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

.viewJobOpeningsBox:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

/* Animation for content loading */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.manageJobOpeningsBox,
.viewJobOpeningsBox {
    animation: fadeIn 0.5s ease forwards;
} 