.nafasi-analytics {
    background: #ffffff;
    min-height: 100vh;
    padding: 20px;
}

.nafasi-analytics__header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    padding: 0 12px;
}

.nafasi-analytics__icon {
    color: #147CE5;
}

.nafasi-analytics__title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1f;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-analytics__content {
    padding: 24px;
    background: linear-gradient(145deg, #f5f5f7 0%, #ffffff 100%);
    border-radius: 20px;
    min-height: calc(100vh - 140px);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
}

.nafasi-analytics__tabs {
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-analytics__tabs .ant-tabs-nav {
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e7;
}

.nafasi-analytics__tab {
    font-size: 15px;
    font-weight: bold;
    font-family: 'ramblaBold';
    color: #1d1d1f;
    padding: 8px 16px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.nafasi-analytics__unauthorized {
    padding: 24px;
    text-align: center;
    color: #1d1d1f;
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
}

/* Responsive Design */
@media (max-width: 1068px) {
    .nafasi-analytics {
        padding: 16px;
    }

    .nafasi-analytics__content {
        padding: 20px;
        min-height: calc(100vh - 120px);
    }
}

@media (max-width: 734px) {
    .nafasi-analytics {
        padding: 12px;
    }

    .nafasi-analytics__header {
        gap: 8px;
        margin-bottom: 20px;
    }

    .nafasi-analytics__title {
        font-size: 20px;
    }

    .nafasi-analytics__content {
        padding: 16px;
        border-radius: 16px;
    }

    .nafasi-analytics__tab {
        font-size: 14px;
        padding: 6px 12px;
    }
} 