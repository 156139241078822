.nafasi-job-posts {
    width: 100%;
    overflow-x: auto;
    padding: 1.5rem;
    background: #f8f9fa;
}

.nafasi-job-posts__table {
    width: 100%;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.nafasi-job-posts__table .ant-table {
    width: 100%;
}

.nafasi-job-posts__table .ant-table-container {
    border-radius: 16px;
    overflow: hidden;
}

.nafasi-job-posts__table .ant-table-content {
    width: 100%;
}

/* Table Header Styles */
.nafasi-job-posts__table .ant-table-thead > tr > th {
    background-color: #f8f9fa !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
    font-size: 0.9rem !important;
    padding: 16px !important;
    border-bottom: 2px solid #eaeaea !important;
    transition: background-color 0.2s ease;
}

.nafasi-job-posts__table .ant-table-thead > tr > th:hover {
    background-color: #f0f1f2 !important;
}

/* Table Cell Styles */
.nafasi-job-posts__table .ant-table-tbody > tr > td {
    padding: 16px !important;
    border-bottom: 1px solid #f0f0f0 !important;
    transition: all 0.2s ease;
}

.nafasi-job-posts__cell {
    font-family: 'ramblaRegular', sans-serif !important;
    color: #2c3e50 !important;
    font-size: 0.9rem !important;
    line-height: 1.5;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Table row hover effect */
.nafasi-job-posts__table .ant-table-tbody > tr:hover > td {
    background-color: #f8f9fa !important;
    cursor: default;
}

/* Status styles */
.nafasi-job-posts__status {
    padding: 6px 12px;
    border-radius: 20px;
    text-align: center;
    font-weight: 500;
    display: inline-block;
    min-width: 100px;
    font-size: 0.8rem;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-family: 'ramblaRegular', sans-serif !important;
}

.nafasi-job-posts__status--active,
.nafasi-job-posts__status--open {
    background-color: #e6f7ed;
    color: #389e0d !important;
}

.nafasi-job-posts__status--pending {
    background-color: #fff7e6;
    color: #d46b08 !important;
}

.nafasi-job-posts__status--closed {
    background-color: #fff1f0;
    color: #cf1322 !important;
}

.nafasi-job-posts__status--draft {
    background-color: #f5f5f5;
    color: #595959 !important;
}

.nafasi-job-posts__switch.ant-switch-checked {
    background-color: #389e0d !important;
}

/* Description button styles */
.nafasi-job-posts__description {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nafasi-job-posts__description button {
    border-radius: 6px;
    transition: all 0.2s ease;
}

.nafasi-job-posts__description button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Responsive Design */
@media screen and (max-width: 1068px) {
    .nafasi-job-posts {
        padding: 0.75rem;
    }

    .nafasi-job-posts__cell {
        font-size: 0.85rem !important;
        padding: 10px 12px !important;
    }
    
    .nafasi-job-posts__header {
        font-size: 0.85rem !important;
        padding: 10px 12px !important;
    }

    .nafasi-job-posts__cell--company,
    .nafasi-job-posts__cell--title,
    .nafasi-job-posts__cell--deadline,
    .nafasi-job-posts__cell--type,
    .nafasi-job-posts__cell--status,
    .nafasi-job-posts__cell--location,
    .nafasi-job-posts__cell--description,
    .nafasi-job-posts__cell--graphic {
        max-width: none;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-job-posts {
        padding: 0.5rem;
    }

    .nafasi-job-posts__cell {
        font-size: 0.8rem !important;
        padding: 8px 10px !important;
    }
    
    .nafasi-job-posts__header {
        font-size: 0.8rem !important;
        padding: 8px 10px !important;
    }
} 