.nafasi-assessment {
    width: 100%;
    overflow-x: auto;
    padding: 0rem;
    background: #f8f9fa;
}

.nafasi-assessment__table {
    width: 100%;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.nafasi-assessment__table .ant-table {
    width: 100%;
}

.nafasi-assessment__table .ant-table-container {
    border-radius: 16px;
    overflow: hidden;
}

.nafasi-assessment__table .ant-table-content {
    width: 100%;
}

/* Table Header Styles */
.nafasi-assessment__table .ant-table-thead > tr > th {
    background-color: #f8f9fa !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
    font-size: 0.9rem !important;
    padding: 16px !important;
    border-bottom: 2px solid #eaeaea !important;
    transition: background-color 0.2s ease;
}

.nafasi-assessment__table .ant-table-thead > tr > th:hover {
    background-color: #f0f1f2 !important;
}

/* Table Cell Styles */
.nafasi-assessment__table .ant-table-tbody > tr > td {
    padding: 16px !important;
    border-bottom: 1px solid #f0f0f0 !important;
    transition: all 0.2s ease;
}

.nafasi-assessment__cell {
    font-family: 'ramblaRegular', sans-serif !important;
    color: #2c3e50 !important;
    font-size: 0.9rem !important;
    line-height: 1.5;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Table row hover effect */
.nafasi-assessment__table .ant-table-tbody > tr:hover > td {
    background-color: #f8f9fa !important;
    cursor: default;
}

/* Attendance Status */
.nafasi-assessment__attendance {
    padding: 6px 12px;
    border-radius: 20px;
    text-align: center;
    font-weight: 500;
    display: inline-block;
    min-width: 80px;
    font-size: 0.8rem;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-family: 'ramblaRegular', sans-serif !important;
}

.nafasi-assessment__attendance--yes {
    background-color: #e6f7ed;
    color: #389e0d !important;
}

.nafasi-assessment__attendance--no {
    background-color: #fff1f0;
    color: #cf1322 !important;
}

/* Description button styles */
.nafasi-assessment__description {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nafasi-assessment__description button {
    border-radius: 6px;
    transition: all 0.2s ease;
}

.nafasi-assessment__description button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Responsive Design */
@media screen and (max-width: 1068px) {
    .nafasi-assessment {
        padding: 0rem;
    }

    .nafasi-assessment__cell {
        font-size: 0.85rem !important;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-assessment {
        padding: 0rem;
    }

    .nafasi-assessment__cell {
        font-size: 0.8rem !important;
    }
} 