/* Reset default margins and padding */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.landing-wrapper {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    position: relative;
}

/* Desktop styles */
@media screen and (min-width: 768px) {
    #page-wrap {
        flex: 1 1;
        display: flex;
        flex-direction: column;
    }
}

/* Mobile styles */
@media screen and (max-width: 767px) {
    #page-wrap {
        flex: 1 1;
        margin-top: 0;  /* No margin on mobile */
        display: flex;
        flex-direction: column;
    }
}

.landing-content {
    margin: 0;
    padding: 0;
    width: 100%;
}

.landing-body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.landing-nav {
    margin: 0;
    padding: 0;
    width: 100%;
}

/* Add specific mobile nav spacing if needed */
@media screen and (max-width: 767px) {
    .landing-body {

    }
} 