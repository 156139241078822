.jobOpeningReviewContainer {
  background: linear-gradient(145deg, #f5f5f7 0%, #ffffff 100%);
  border-radius: 30px;
  min-height: calc(100vh - 180px);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.topBarBox {
  background: transparent;
  border-bottom: 1px solid #e5e5e7;
  border-radius: 0;
  padding: 0 0 32px 0;
  margin-bottom: 32px;
  box-shadow: none;
}

.childHeaderBarBox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.childHeaderBarBox h2 {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  font-family: 'ramblaBold', sans-serif;
  color: #1d1d1f;
  letter-spacing: -0.025em;
}

.candidateListBox {
  background: #ffffff;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  overflow-x: auto;
  width: 100%;
}

.candidateListBox h4 {
  font-size: 17px;
  font-weight: bold;
  font-family: 'ramblaBold', sans-serif;
  margin-bottom: 1.5rem;
  color: #1d1d1f;
  letter-spacing: -0.025em;
}

/* Table Styles */
.candidateListBox .ant-table {
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.candidateListBox .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.candidateListBox .ant-table-thead > tr > th {
  background: transparent;
  border-bottom: 1px solid #e5e5e7;
  color: #1d1d1f;
  font-weight: 600;
  padding: 16px;
  font-size: 14px;
  transition: background 0.2s ease;
}

.candidateListBox .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f5f5f7;
  padding: 16px;
  font-size: 14px;
  transition: background 0.2s ease;
}

.candidateListBox .ant-table-tbody > tr:hover > td {
  background: #f5f5f7;
}

.candidateListBox .ant-table-cell {
  white-space: nowrap;
}

.candidateListBox .ant-table-row {
  cursor: default;
}

/* Action buttons */
.action-button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #f5f5f7;
}

.action-button.edit {
  color: #0066cc;
}

.action-button.delete {
  color: #ff3b30;
}

/* Interview date link */
.interview-date {
  color: #0066cc;
  cursor: pointer;
  transition: color 0.2s ease;
}

.interview-date:hover {
  color: #004499;
}

/* Status indicators */
.status-indicator {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
}

.status-done {
  background: #e8f5e9;
  color: #1b5e20;
}

.status-pending {
  background: #fff3e0;
  color: #e65100;
}

/* Modal styles */
.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.ant-modal-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.ant-modal-title {
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #1d1d1f;
}

.adminAlert {
  text-align: center;
  color: #86868b;
  font-size: 17px;
  line-height: 1.5;
  padding: 2rem;
  background: #f5f5f7;
  border-radius: 12px;
  margin: 1rem 0;
  font-family: 'ramblaBold', sans-serif;
}

.adminAlert span {
  color: #0066cc;
  transition: color 0.2s ease;
  cursor: pointer;
}

.adminAlert span:hover {
  color: #004499;
}

/* Animation for messages */
.ant-message {
  font-family: 'ramblaBold', sans-serif;
}

.ant-message-notice-content {
  border-radius: 12px;
  padding: 12px 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  font-size: 15px;
}

/* Responsive design */
@media (max-width: 1200px) {
  .candidateListBox {
    padding: 1.5rem;
  }
  
  .candidateListBox .ant-table-thead > tr > th,
  .candidateListBox .ant-table-tbody > tr > td {
    padding: 12px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .jobOpeningReviewContainer {
    min-height: calc(100vh - 120px);
  }
  
  .topBarBox {
    padding: 0 0 24px 0;
    margin-bottom: 24px;
  }
  
  .candidateListBox {
    padding: 1rem;
  }
  
  .childHeaderBarBox h2,
  .candidateListBox h4 {
    font-size: 15px;
  }

  .candidateListBox .ant-table-thead > tr > th,
  .candidateListBox .ant-table-tbody > tr > td {
    padding: 8px;
    font-size: 12px;
  }
} 