.nafasi-extracts__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
    border-radius: 12px;
}

.nafasi-extracts {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-extracts__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-extracts__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-extracts__icon {
    color: #0066cc;
}

.nafasi-extracts__title {
    margin: 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
}

.nafasi-extracts__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 24px;
}

.nafasi-extracts__form {
    width: 100%;
}

.nafasi-extracts__select-container {
    width: 100%;
}

.nafasi-extracts__select {
    width: 100% !important;
    font-family: 'ramblaRegular', sans-serif !important;
}

.nafasi-extracts__switches {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.nafasi-extracts__switch-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.nafasi-extracts__switch-label {
    font-family: 'ramblaRegular', sans-serif !important;
    color: #1d1d1f;
}

.nafasi-extracts__actions {
    display: flex;
    justify-content: flex-end;
}

.nafasi-extracts__button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'ramblaRegular', sans-serif !important;
    background-color: #0066cc !important;
    border-color: #0066cc !important;
}

.nafasi-extracts__button:hover {
    background-color: #0077ed !important;
    border-color: #0077ed !important;
}

/* Responsive Design */
@media screen and (max-width: 1068px) {
    .nafasi-extracts__container {
        padding: 1.5rem;
    }
    
    .nafasi-extracts__content {
        padding: 1.25rem;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-extracts__container {
        padding: 1rem;
    }
    
    .nafasi-extracts__title {
        font-size: 1.1rem !important;
    }
    
    .nafasi-extracts__content {
        padding: 1rem;
    }
    
    .nafasi-extracts__switches {
        flex-direction: column;
        gap: 12px;
    }
} 