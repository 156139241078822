/* Updated SearchPage.css */
.searchContainer {
    padding: 24px;
    background: #ffffff;
    font-family: 'ramblaRegular', sans-serif;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Search Header */
.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e7eb;
}

.search-header-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.search-header-right {
    display: flex;
    gap: 8px;
}

/* Search Bar Section */
.search-bar-section {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}

.search-bar-container {
    padding: 24px;
    border-bottom: 1px solid #e5e7eb;
}

/* Main search bar */
.search-bar-container .ant-input-search {
    max-width: 800px;
    margin: 0 auto;
}

/* Filter search inputs */
.filter-search-input.ant-input-search {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
    width: 100%;
}

.filter-search-input.ant-input-search .ant-input {
    height: auto;
    padding: 8px 12px;
}

/* Remove global styles */
/* Delete or comment out these global styles:
.ant-input-search {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-input-search .ant-input {
    height: auto !important;
    padding: 8px 12px !important;
}
*/

.variable-section p {
    font-size: 13px;
}

/* Filter Groups */
.filter-groups {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 20px;
    background: #f9fafb;
    border-radius: 0 0 8px 8px;
    justify-content: center;
}

.filter-group {
    position: relative;
    display: flex;
    justify-content: center;
}

.filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 160px;
    text-align: center;
    font-family: 'ramblaRegular', sans-serif;
}

.filter-button.active {
    border-color: #4f46e5;
    color: #4f46e5;
    background: #f5f3ff;
}

.filter-button.has-selection {
    background: #f5f3ff;
    border-color: #4f46e5;
    color: #4f46e5;
}

.filter-button.has-selection::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    background: #4f46e5;
    border-radius: 50%;
}

/* Filter Popover */
.filter-popover {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e5e7eb;
    width: 420px;
    max-height: 600px;
    overflow-y: auto;
    z-index: 1000;
}

.filter-popover.show {
    display: block;
}

/* Filter sections */
.filter-section {
    padding: 20px;
    border-bottom: 1px solid #e5e7eb;
}

.filter-section:last-child {
    border-bottom: none;
}

/* Add some spacing between filters within sections */
.filter-section > * {
    margin-bottom: 16px;
}

.filter-section > *:last-child {
    margin-bottom: 0;
}

.filter-section-title {
    font-size: 14px;
    font-weight: 600;
    color: #374151;
    margin-bottom: 12px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.95rem;
}

/* Close button */
.filter-close {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 4px;
    cursor: pointer;
    border: none;
    background: transparent;
    color: #6b7280;
}

/* Search Actions */
.search-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    margin: 24px 0;
    padding: 0 24px;
}

/* Results Section */
.results-section {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.search-results-container {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
}

.search-results-wrapper {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
    min-width: 0;
}

.result-card {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: auto;
    max-height: 180px;
    overflow: hidden;
}

.results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.results-header h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.results-header span {
    color: #6b7280;
    font-size: 14px;
    padding: 4px 12px;
    background: #f3f4f6;
    border-radius: 16px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
}

.results-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.no-results-message {
    padding: 24px;
    text-align: center;
    color: #6B7280;
}

/* Table Styles */
.ant-table {
    background: transparent !important;
}

.ant-table-thead > tr > th {
    padding: 10px 16px !important;
    background: #f9fafb !important;
    color: #4b5563 !important;
    font-weight: 600 !important;
    border-bottom: 2px solid #e5e7eb !important;
    font-family: 'ramblaBold', sans-serif !important;
    font-size: 0.9rem !important;
    line-height: 1.3 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-tbody > tr > td {
    padding: 8px 16px !important;
    border-bottom: 1px solid #e5e7eb !important;
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
    line-height: 1.3 !important;
}

.ant-table-tbody > tr:hover > td {
    background: #f5f3ff !important;
}

/* Candidate Card */
.candidate-card {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 0;
}

.candidate-info {
    flex: 1;
    min-width: 0;
    line-height: 1.2;
}

.candidate-name {
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 2px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.95rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    cursor: pointer;
    position: relative;
}

.candidate-name:hover::after {
    content: attr(data-full-name);
    position: absolute;
    top: 100%;
    left: 0;
    background: #1f2937;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85rem;
    z-index: 1000;
    white-space: normal;
    max-width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.candidate-highlight {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    background: #e0e7ff;
    color: #4f46e5;
    display: inline-block;
}

/* Stats Badge */
.stats-badge {
    display: inline-block;
    padding: 2px 2px;
    border-radius: 12px;
    background: #f3f4f6;
    color: #6b7280;
    font-size: 0.85rem;
    font-family: 'ramblaBold', sans-serif;
    min-width: 40px;
    text-align: center;
}

.stats-badge.high {
    background: #dcfce7;
    color: #15803d;
}

.stats-badge.medium {
    background: #fef9c3;
    color: #854d0e;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 1px;
    justify-content: center;
    min-width: 65px;
}

.action-button {
    padding: 3px;
    min-width: 22px;
    height: 22px;
}

/* Truncated Text */
.truncated-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2px;
    font-size: 0.85rem;
    font-family: 'ramblaRegular', sans-serif;
}

.truncated-text-tooltip {
    max-width: 300px;
    white-space: normal;
}

/* Loading States */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-content {
    text-align: center;
}

.loading-spinner {
    margin-bottom: 16px;
}

/* Buttons */
.button-primary {
    background: #4f46e5;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.button-primary:hover {
    background: #4338ca;
}

.button-secondary {
    background: #ffffff;
    color: #4f46e5;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid #4f46e5;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.button-secondary:hover {
    background: #f5f3ff;
}

/* Selected filter styles */
.filter-option.selected {
    background: #f5f3ff;
    border-color: #4f46e5;
}

/* Make sure subcomponents don't trigger popover close */
.filter-section .ant-select,
.filter-section .ant-input,
.filter-section .ant-checkbox-wrapper,
.filter-section .ant-radio-group,
.filter-section .ant-picker,
.filter-section button {
    pointer-events: all;
    position: relative;
    z-index: 1;
}

/* Ensure all form elements are clickable */
.filter-section .ant-select,
.filter-section .ant-input,
.filter-section .ant-checkbox-wrapper,
.filter-section .ant-radio-group,
.filter-section .ant-picker,
.filter-section .ant-select-dropdown {
    z-index: 1001; /* Higher than popover */
}

/* Ensure dropdowns appear above popover */
.ant-select-dropdown {
    z-index: 1002 !important;
}

/* Dev Tools Accordion */
.dev-tools-container {
    position: relative;
    margin: 0 0 24px 0;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dev-tools-accordion {
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
}

.dev-tools-header {
    width: 100%;
    padding: 12px 16px;
    background: #f9fafb;
    border: none;
    text-align: left;
    font-weight: 600;
    color: #374151;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.9rem;
}

.dev-tools-header:hover {
    background: #f3f4f6;
}

.dev-tools-header.active {
    border-bottom: 1px solid #e5e7eb;
}

.dev-tools-content {
    padding: 16px;
    background: #ffffff;
    max-height: 400px;
    overflow-y: auto;
}

.dev-tools-content h4 {
    margin-bottom: 12px;
    color: #4f46e5;
    font-weight: 600;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.95rem;
}

.dev-tools-content pre {
    background: #f9fafb;
    padding: 16px;
    border-radius: 6px;
    overflow: auto;
    font-size: 0.85rem;
    line-height: 1.5;
    color: #1f2937;
    font-family: monospace;
}

/* Truncated Link */
.truncated-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #4f46e5;
    cursor: pointer;
    transition: all 0.2s;
}

.truncated-link:hover {
    color: #4338ca;
}

.more-indicator {
    font-size: 12px;
    color: #6b7280;
    margin-left: 4px;
}

/* Experience Modal */
.experience-modal .modal-title,
.industries-modal .modal-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    color: #1f2937;
}

.experience-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.experience-item {
    padding: 16px;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
}

.experience-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.experience-header h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    font-family: 'ramblaBold', sans-serif;
}

.experience-duration {
    font-size: 14px;
    color: #6b7280;
}

.experience-company {
    font-size: 15px;
    color: #4b5563;
    font-weight: 500;
    margin-bottom: 4px;
}

.experience-location {
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 8px;
}

.experience-description {
    font-size: 14px;
    color: #4b5563;
    line-height: 1.5;
    margin: 0;
}

/* Industries Modal */
.industries-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
}

.industry-item {
    padding: 12px;
    background: #f9fafb;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #4b5563;
}

/* Modal Overrides */
.ant-modal-content {
    border-radius: 12px !important;
    overflow: hidden;
}

.ant-modal-header {
    padding: 20px 24px !important;
    border-bottom: 1px solid #e5e7eb !important;
    margin-bottom: 0 !important;
}

.ant-modal-body {
    padding: 24px !important;
    max-height: 70vh;
    overflow-y: auto;
}

.ant-modal-close {
    top: 20px !important;
    right: 20px !important;
}

/* Experience Position Details */
.experience-positions {
    margin-top: 12px;
    padding-left: 16px;
    border-left: 2px solid #e5e7eb;
}

.experience-position {
    margin-bottom: 8px;
    padding: 8px;
    background: #ffffff;
    border-radius: 4px;
}

.position-title {
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 4px;
}

.position-duration {
    font-size: 13px;
    color: #6b7280;
}

/* Search Sub Components */
.ant-select {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-select-item {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-select-selection-item {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-input {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-checkbox-wrapper {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-radio-wrapper {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

/* Search Buttons */
.button-primary,
.button-secondary {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
    padding: 8px 16px !important;
    height: auto !important;
    line-height: 1.5 !important;
}

.button-primary {
    background: #4f46e5 !important;
    color: white !important;
    border: none !important;
    border-radius: 6px !important;
    transition: all 0.2s !important;
}

.button-primary:hover {
    background: #4338ca !important;
}

.button-primary:disabled {
    background: #9ca3af !important;
    cursor: not-allowed !important;
}

.button-secondary {
    background: white !important;
    color: #4b5563 !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 6px !important;
}

.button-secondary:hover {
    background: #f9fafb !important;
    border-color: #d1d5db !important;
}

/* Filter Labels and Text */
.filter-section label,
.filter-section .ant-form-item-label > label {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
    color: #4b5563 !important;
}

/* Dropdown Menus */
.ant-select-dropdown {
    font-family: 'ramblaRegular', sans-serif !important;
}

.ant-select-item-option-content {
    font-size: 0.9rem !important;
}

/* Date Picker */
.ant-picker {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-picker-dropdown {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

/* Search Input */
.ant-input-search {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.ant-input-search .ant-input {
    height: auto !important;
    padding: 8px 12px !important;
}

/* Placeholders */
::placeholder {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
}

.candidate-details {
    font-size: 0.85rem;
    color: #6b7280;
    margin-top: 1px;
    font-family: 'ramblaRegular', sans-serif;
    line-height: 1.2;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .search-results-container {
        padding: 12px;
    }

    .result-card {
        max-height: 160px;
    }
}

@media (max-width: 768px) {
    .search-results-wrapper {
        grid-template-columns: 1fr;
    }
    
    .result-card {
        max-height: 150px;
    }
}

/* Ensure table cells maintain their width */
.ant-table-cell {
    white-space: nowrap;
}

/* Add/update these styles */
.results-container {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
}

/* Force table to take full width */
.ant-table-wrapper {
    width: 100% !important;
    display: block !important;
}

.ant-table {
    width: 100% !important;
}

/* Ensure the table content container takes full width */
.ant-table-container {
    width: 100% !important;
    display: block !important;
}

/* Make the table content stretch */
.ant-table-content {
    width: 100% !important;
    display: block !important;
}

/* Ensure rows take full width */
.ant-table-tbody > tr {
    width: 100% !important;
}

/* Remove any max-width constraints */
.ant-table-wrapper, 
.ant-table, 
.ant-table-container,
.ant-table-content {
    max-width: none !important;
}

/* Adjust cell behavior */
.ant-table-cell {
    position: relative !important;
    left: auto !important;
    white-space: nowrap;
}

/* Ensure header row takes full width */
.ant-table-thead > tr {
    width: 100% !important;
}

/* Update location styles */
.location-icon {
    font-size: 12px;
    margin-right: 2px;
    color: #6B7280;
}

/* Make location text more compact */
.truncated-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2px;
    font-size: 0.85rem;
}

/* Adjust action buttons to be more compact */
.action-buttons {
    display: flex;
    gap: 1px;
    justify-content: center;
    min-width: 65px;
}

.action-button {
    padding: 3px;
    min-width: 22px;
    height: 22px;
}

/* Center the icon and text within the button */
.filter-button svg {
    margin-right: 4px;
}

/* Add these styles for the Popconfirm */
.ant-popover {
    max-width: 400px;
}

.ant-popover-message {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
    padding: 12px 16px;
}

.ant-popover-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px 16px;
}

/* Update action button styles */
.action-buttons {
    display: flex;
    gap: 4px;
    justify-content: center;
    min-width: 70px;
}

.action-button {
    padding: 4px;
    min-width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.action-button:hover {
    background: #f3f4f6;
    border-radius: 4px;
}

