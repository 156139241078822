.apple-footer {
    background-color: #f5f5f7;
    color: #1d1d1f;
    padding: 0 20px;
    font-size: 12px;
    line-height: 1.33337;
    font-family: 'RamblaRegular', sans-serif;
}

.footer-about {
    max-width: 980px;
    margin: 0 auto;
    padding: 35px 0 5px 0;
    border-bottom: 1px solid #d2d2d7;
}

.footer-about h4 {
    text-align: center;
    color: #1d1d1f;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: 'RamblaBold', sans-serif;
}

.footer-about p {
    color: #515154;
    line-height: 1.5;
    font-size: 12px;
    font-family: 'RamblaRegular', sans-serif;
}

.footer-content {
    max-width: 980px;
    margin: 0 auto;
    padding: 15px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    border-bottom: 1px solid #d2d2d7;
}

.footer-section h6 {
    margin: 0 0 0.8em;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: #1d1d1f;
}

.footer-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul li {
    margin-bottom: 0.7em;
}

.footer-section ul li a {
    color: #515154;
    text-decoration: none;
    font-size: 12px;
    font-family: 'RamblaRegular', sans-serif;
}

.footer-section ul li a:hover {
    color: #000;
    text-decoration: underline;
}

.footer-mini {
    max-width: 980px;
    margin: 0 auto;
    padding: 17px 0;
    color: #86868b;
}

.footer-legal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.footer-legal p {
    margin: 0 0 7px;
    color: #86868b;
    font-family: 'ramblaRegular', sans-serif;
}

.footer-legal ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 20px;
}

.footer-legal ul li a {
    color: #515154;
    text-decoration: none;
    font-size: 12px;
}

.footer-legal ul li a:hover {
    color: #000;
    text-decoration: underline;
}

/* Responsive Design */
@media only screen and (max-width: 833px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 475px) {
    .footer-content {
        grid-template-columns: 1fr;
    }
    
    .footer-legal ul {
        flex-direction: column;
        gap: 10px;
    }
}

/* FontAwesome icon styling */
.footer-section .svg-inline--fa {
    margin-right: 5px;
    font-size: 14px;
}

/* Add styles for locale */
.footer-locale {
    margin-bottom: 10px;
}

.footer-locale p {
    color: #515154;
    margin: 0;
}

/* Add responsive padding adjustments */
@media screen and (max-width: 768px) {
    .footer-legal {
        padding: 0 10px;
    }

    .apple-footer {
        padding: 20px 10px;
    }

    .footer-content {
        padding: 0 10px;
    }

    .footer-about {
        padding: 0 10px;
    }
}

/* For very small screens */
@media screen and (max-width: 480px) {
    .footer-legal {
        padding: 0 5px;
    }

    .apple-footer {
        padding: 15px 5px;
    }

    .footer-content {
        padding: 0 5px;
    }

    .footer-about {
        padding: 0 5px;
    }
} 