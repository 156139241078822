.nafasi-assessment-modal {
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-assessment-modal__trigger {
    color: #0066cc;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.nafasi-assessment-modal__trigger:hover {
    opacity: 0.7;
}

/* Modal Overrides */
.nafasi-assessment-modal .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
}

.nafasi-assessment-modal .ant-modal-header {
    padding: 24px;
    border-bottom: none;
    background: #f5f5f7;
}

.nafasi-assessment-modal .ant-modal-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 20px;
    color: #1d1d1f;
}

.nafasi-assessment-modal .ant-modal-body {
    padding: 0;
    background: #f5f5f7;
}

.nafasi-assessment-modal .ant-modal-footer {
    padding: 16px 24px;
    border-top: none;
    background: #f5f5f7;
}

/* Content Styles */
.nafasi-assessment-modal__section {
    background: #ffffff;
    margin: 8px 16px;
    padding: 16px 24px;
    border-radius: 12px;
    transition: transform 0.2s ease;
}

.nafasi-assessment-modal__section:hover {
    transform: translateY(-1px);
}

.nafasi-assessment-modal__label {
    font-family: 'ramblaBold', sans-serif;
    color: #86868b;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
}

.nafasi-assessment-modal__value {
    font-family: 'ramblaRegular', sans-serif;
    color: #1d1d1f;
    font-size: 16px;
    line-height: 1.4;
}

.nafasi-assessment-modal__cv {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0066cc;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.nafasi-assessment-modal__cv:hover {
    opacity: 0.7;
}

.nafasi-assessment-modal__cv-icon {
    font-size: 16px;
} 