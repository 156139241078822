.roadmap {
  position: relative;
  padding: 15px 20px;
  max-width: 1000px;
  margin: 0 auto;
  perspective: 1000px;
  overflow: hidden;
  min-height: auto;
  background: white;
}

/* Remove all decorative elements and keep only essential styles */
.roadmap::before,
.roadmap::after,
.floating-illustration,
.background-decoration,
.decoration-grid {
  display: none;
}

.roadmap-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, 
    rgba(150, 150, 150, 0.1),
    rgba(100, 100, 100, 0.8),
    rgba(150, 150, 150, 0.1)
  );
  transform: translateX(-50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.point {
  position: relative;
  margin: 15px 0;
  opacity: 0;
  transform: translateX(-100px) rotateY(-10deg);
  transition: all 0.8s ease-out;
  display: flex;
  align-items: center;
}

.point.right {
  transform: translateX(100px) rotateY(10deg);
  flex-direction: row-reverse;
}

.point-visible {
  opacity: 1;
  transform: translateX(0) rotateY(0) !important;
}

.point-card {
  position: relative;
  width: 42%;
  padding: 12px;
  background: rgba(30, 30, 30, 0.95);
  border-radius: 10px;
  box-shadow: 
    0 3px 10px rgba(0,0,0,0.2),
    inset 0 0 20px rgba(255,215,0,0.1);
  backdrop-filter: blur(5px);
  transform-style: preserve-3d;
  transition: transform 0.2s ease-out, box-shadow 0.3s ease;
  cursor: pointer;
}

.point-card:hover {
  transform: translateZ(30px);
  box-shadow: 
    0 15px 30px rgba(0,0,0,0.4),
    inset 0 0 60px rgba(255,215,0,0.2);
}

.point-index {
  width: 45px;
  height: 45px;
  background: rgba(20, 20, 20, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 
    0 3px 10px rgba(0,0,0,0.2),
    inset 0 0 10px rgba(255,215,0,0.3);
  z-index: 1;
  margin: 0 15px;
}

.point-content {
  transform-style: preserve-3d;
}

.point-label {
  font-size: 1em;
  font-weight: bold;
  color: gold;
  margin-bottom: 6px;
  transform: translateZ(10px);
  font-family: "ramblaBold", sans-serif;
}

.point-text {
  font-size: 0.85em;
  color: #fff;
  line-height: 1.3;
  transform: translateZ(5px);
  font-family: "ramblaRegular", sans-serif;
}

/* Add glowing dots along the timeline */
.roadmap-line::before,
.roadmap-line::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.roadmap-line::before {
  top: 0;
}

.roadmap-line::after {
  bottom: 0;
}

@media (max-width: 768px) {
  .point {
    margin: 20px 0;
    justify-content: flex-end;
  }

  .point.right {
    justify-content: flex-end;
  }
  
  .point-card {
    width: 80%;
    padding: 10px;
  }
  
  .roadmap-line {
    left: 25px;
  }
  
  .point-index {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}