.nafasi-description-modal__trigger {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
}

.nafasi-description-modal__trigger:hover {
    background-color: rgba(0, 102, 204, 0.05);
}

.nafasi-description-modal__icon {
    color: #0066cc;
}

.nafasi-description-modal__text {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
    color: #0066cc;
}

.nafasi-description-modal__modal {
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-description-modal__modal .ant-modal-content {
    border-radius: 12px;
    padding: 0;
}

.nafasi-description-modal__modal .ant-modal-header {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    margin: 0;
}

.nafasi-description-modal__title {
    margin: 0;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
    color: #1d1d1f;
}

.nafasi-description-modal__content {
    padding: 24px;
}

.nafasi-description-modal__section {
    margin-bottom: 20px;
}

.nafasi-description-modal__section:last-child {
    margin-bottom: 0;
}

.nafasi-description-modal__label {
    margin: 0 0 4px 0;
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.9rem;
    color: #86868b;
}

.nafasi-description-modal__value {
    margin: 0;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 1rem;
    color: #1d1d1f;
}

.nafasi-description-modal__modal .ant-modal-footer {
    margin: 0;
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-description-modal__button {
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

/* Responsive styles */
@media screen and (max-width: 734px) {
    .nafasi-description-modal__content {
        padding: 16px;
    }

    .nafasi-description-modal__title {
        font-size: 1rem;
    }

    .nafasi-description-modal__value {
        font-size: 0.9rem;
    }
} 