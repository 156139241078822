.nafasi-edit-profile {
    padding: 32px;
    background: #fff;
    min-height: auto;
}

.nafasi-edit-profile__container {
    max-width: 800px;
    margin: 0 auto;
}

.nafasi-edit-profile__title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 24px;
    color: #1d1d1f;
    margin: 0 0 4px;
}

.nafasi-edit-profile__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nafasi-edit-profile__section {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.nafasi-edit-profile__section:last-child {
    margin-bottom: 0;
}

.nafasi-edit-profile__section-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 17px;
    color: #1d1d1f;
    margin: 0 0 20px;
}

.nafasi-edit-profile__form-group {
    margin-bottom: 4px;
}

.nafasi-edit-profile__form-group:last-child {
    margin-bottom: 0;
}

.nafasi-edit-profile__input {
    width: 100%;
    height: 36px;
    padding: 8px 12px;
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    background: #fff;
    transition: all 0.2s ease;
}

.nafasi-edit-profile__input:focus {
    outline: none;
    border-color: #0071e3;
    box-shadow: 0 0 0 4px rgba(0, 113, 227, 0.1);
}

.nafasi-edit-profile__input::placeholder {
    color: #86868b;
}

.nafasi-edit-profile__location-text {
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    margin: 0 0 12px;
}

.nafasi-edit-profile__upload-group {
    margin-bottom: 24px;
}

.nafasi-edit-profile__upload-group:last-child {
    margin-bottom: 0;
}

.nafasi-edit-profile__upload-label {
    display: block;
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    margin-bottom: 8px;
}

.nafasi-edit-profile__upload-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
}

.nafasi-edit-profile__upload {
    flex: 1;
    max-width: 300px;
}

.nafasi-edit-profile__preview {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
}

.nafasi-edit-profile__error {
    padding: 12px 16px;
    background: #fff2f2;
    border-radius: 8px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 14px;
    color: #ff3b30;
}

.nafasi-edit-profile__actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}

.nafasi-edit-profile__button {
    background: #0071e3;
    color: #fff;
    border: none;
    border-radius: 980px;
    padding: 12px 24px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.nafasi-edit-profile__button:hover {
    background: #0077ed;
}

.nafasi-edit-profile__button:active {
    background: #0062c1;
    transform: translateY(0);
}

.nafasi-edit-profile__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
}

/* Document Upload Styles */
.nafasi-edit-profile__upload-dragger.ant-upload-drag {
    border: 2px dashed #d2d2d7 !important;
    border-radius: 12px !important;
    background: #fff !important;
    transition: all 0.2s ease !important;
}

.nafasi-edit-profile__upload-dragger.ant-upload-drag:hover {
    border-color: #0071e3 !important;
    background: rgba(0, 113, 227, 0.02) !important;
}

.nafasi-edit-profile__upload-dragger .ant-upload-drag-icon {
    color: #0071e3;
    font-size: 32px;
    margin-bottom: 16px;
}

.nafasi-edit-profile__upload-dragger .ant-upload-text {
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    margin-bottom: 8px;
}

.nafasi-edit-profile__upload-dragger .ant-upload-hint {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 13px;
    color: #86868b;
}

.nafasi-edit-profile__document-preview {
    background: #f5f5f7;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;
}

.nafasi-edit-profile__document-icon {
    font-size: 24px;
    color: #0071e3;
    flex-shrink: 0;
}

.nafasi-edit-profile__document-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 0; /* Prevent flex item from overflowing */
}

.nafasi-edit-profile__document-name {
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nafasi-edit-profile__document-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
}

.nafasi-edit-profile__document-view {
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #0071e3;
    text-decoration: none;
    padding: 4px 12px;
    border-radius: 980px;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.nafasi-edit-profile__document-view:hover {
    background: rgba(0, 113, 227, 0.1);
    color: #0077ed;
}

.nafasi-edit-profile__document-remove {
    background: transparent;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    color: #86868b;
    flex-shrink: 0;
}

.nafasi-edit-profile__document-remove:hover {
    background: rgba(255, 59, 48, 0.1);
    color: #ff3b30;
}

/* Responsive */
@media (max-width: 734px) {
    .nafasi-edit-profile {
        padding: 16px;
    }

    .nafasi-edit-profile__container {
        max-width: none;
    }

    .nafasi-edit-profile__title {
        font-size: 20px;
        margin-bottom: 4px;
    }

    .nafasi-edit-profile__section {
        padding: 16px;
    }

    .nafasi-edit-profile__section-title {
        font-size: 15px;
        margin-bottom: 16px;
    }

    .nafasi-edit-profile__form-group {
        margin-bottom: 12px;
    }

    .nafasi-edit-profile__input {
        height: 32px;
        font-size: 13px;
    }

    .nafasi-edit-profile__upload-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .nafasi-edit-profile__preview {
        width: 40px;
        height: 40px;
    }

    .nafasi-edit-profile__upload-dragger {
        padding: 16px;
    }

    .nafasi-edit-profile__upload-dragger .ant-upload-text {
        font-size: 14px;
    }

    .nafasi-edit-profile__upload-dragger .ant-upload-hint {
        font-size: 12px;
    }

    .nafasi-edit-profile__document-preview {
        padding: 12px;
        gap: 12px;
    }

    .nafasi-edit-profile__document-icon {
        font-size: 24px;
    }

    .nafasi-edit-profile__document-name {
        font-size: 13px;
    }
}

.nafasi-edit-profile__upload-area {
    margin-top: 12px;
}

.nafasi-edit-profile__upload-box {
    position: relative;
    padding: 24px;
    background: #f5f5f7;
    border: 2px dashed #d2d2d7;
    border-radius: 12px;
    text-align: center;
    transition: all 0.2s ease;
    cursor: pointer;
}

.nafasi-edit-profile__upload-box:hover {
    background: #fafafa;
    border-color: #86868b;
}

.nafasi-edit-profile__upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.nafasi-edit-profile__upload-icon {
    font-size: 32px;
    color: #86868b;
    margin-bottom: 12px;
}

.nafasi-edit-profile__upload-text {
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    margin: 0 0 4px;
}

.nafasi-edit-profile__upload-hint {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 13px;
    color: #86868b;
    margin: 0;
}

.nafasi-edit-profile__pdf-preview {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
    padding: 12px;
    background: #f5f5f7;
    border-radius: 8px;
}

.nafasi-edit-profile__pdf-icon {
    font-size: 24px;
    color: #ff3b30;
}

.nafasi-edit-profile__pdf-name {
    flex: 1;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-edit-profile__pdf-view {
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #0071e3;
    text-decoration: none;
    padding: 4px 12px;
    border-radius: 980px;
    transition: all 0.2s ease;
}

.nafasi-edit-profile__pdf-view:hover {
    background: rgba(0, 113, 227, 0.1);
    color: #0077ed;
}

/* Update responsive styles */
@media (max-width: 734px) {
    .nafasi-edit-profile__upload-box {
        padding: 16px;
    }

    .nafasi-edit-profile__upload-icon {
        font-size: 28px;
        margin-bottom: 8px;
    }

    .nafasi-edit-profile__upload-text {
        font-size: 13px;
    }

    .nafasi-edit-profile__upload-hint {
        font-size: 12px;
    }

    .nafasi-edit-profile__pdf-preview {
        padding: 8px;
        gap: 8px;
    }

    .nafasi-edit-profile__pdf-icon {
        font-size: 20px;
    }

    .nafasi-edit-profile__pdf-name {
        font-size: 13px;
    }

    .nafasi-edit-profile__pdf-view {
        font-size: 12px;
        padding: 4px 8px;
    }
}

/* Logo preview container */
.nafasi-edit-profile__logo-preview {
    position: relative;
    display: inline-block;
}

.nafasi-edit-profile__preview {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
}

/* Logo delete button */
.nafasi-edit-profile__logo-remove {
    position: absolute;
    top: -8px;
    right: -8px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nafasi-edit-profile__logo-remove:hover {
    background: rgba(255, 59, 48, 0.1);
    color: #ff3b30;
} 