@import url('https://fonts.googleapis.com/css2?family=Rambla:wght@400;700&display=swap');

.nafasi-blog-editor__container {
    width: 100%;
    min-height: 100vh;
    padding: 0.5rem;
    background-color: #f5f5f7;
}

.nafasi-blog-editor {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-blog-editor__header-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.nafasi-blog-editor__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-blog-editor__icon {
    color: #1890ff;
}

.nafasi-blog-editor__title {
    margin: 0 !important;
    font-family: ramblaBold, sans-serif;
}

.nafasi-blog-editor__content {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    font-family: 'ramblaRegular', sans-serif !important;
}

.nafasi-blog-editor__form {
    width: 100%;
}

.nafasi-blog-editor__form-item {
    margin-bottom: 24px;
}

.nafasi-blog-editor__wysiwyg {
    margin-bottom: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.nafasi-blog-editor__wrapper {
    border: none !important;
}

.nafasi-blog-editor__editor {
    font-family: 'ramblaRegular', sans-serif !important;
    font-weight: 400;
    min-height: 300px;
    padding: 10px;
}

.nafasi-blog-editor__editor strong,
.nafasi-blog-editor__editor b {
    font-family: 'ramblaBold', sans-serif !important;
    font-weight: 700;
}

.nafasi-blog-editor__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.nafasi-blog-editor__save-btn {
    min-width: 120px;
    font-family: ramblaRegular, sans-serif;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .nafasi-blog-editor__container {
        padding: 16px;
    }

    .nafasi-blog-editor__content {
        padding: 16px;
    }

    .nafasi-blog-editor__editor {
        min-height: 300px;
    }
}

@media screen and (max-width: 480px) {
    .nafasi-blog-editor__container {
        padding: 12px;
    }

    .nafasi-blog-editor__content {
        padding: 12px;
    }

    .nafasi-blog-editor__form-item {
        margin-bottom: 16px;
    }

    .nafasi-blog-editor__editor {
        min-height: 250px;
    }
}

/* Style the toolbar to match */
.rdw-editor-toolbar {
    font-family: 'Rambla', sans-serif !important;
    font-weight: 400;
}

/* Define font-face for local usage if needed */
@font-face {
    font-family: 'ramblaRegular';
    src: url('../../../assets/fonts/Rambla-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ramblaBold';
    src: url('../../../assets/fonts/Rambla-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
} 