.post-card {
    background: white;
    border-radius: 14px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease;
}

.post-card-content {
    padding: 20px;
    flex: 1;
}

.post-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
}

.post-title {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
    line-height: 1.3;
}

.status-badge {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 980px;
    white-space: nowrap;
}

.status-active {
    background-color: #e4f8ef;
    color: #1d7d51;
}

.status-upcoming {
    background-color: #fff2e6;
    color: #bf4800;
}

.status-closed {
    background-color: #f5f5f7;
    color: #86868b;
}

.post-company {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.company-icon {
    color: #06c;
    font-size: 14px;
}

.company-name {
    color: #06c;
    font-size: 15px;
    font-weight: 500;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.post-meta {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.meta-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #86868b;
    font-size: 13px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.meta-icon {
    font-size: 14px;
    flex-shrink: 0;
}

.post-card-footer {
    padding: 16px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background: #f5f5f7;
}

.apply-button {
    width: 100%;
    background: #06c;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 980px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.apply-button:hover {
    background: #004999;
}

@media (max-width: 640px) {
    .post-card-content {
        padding: 16px;
    }

    .post-card-footer {
        padding: 12px 16px;
    }
} 