.nafasi-notifications {
    width: 100%;
    min-height: calc(100vh - 64px);
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.nafasi-notifications__header {
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: sticky;
    top: 0;
    z-index: 100;
}

.nafasi-notifications__header-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-notifications__icon-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: rgba(0, 113, 227, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
}

.nafasi-notifications__icon {
    font-size: 16px;
    color: #0071e3;
}

.nafasi-notifications__title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: .009em;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-notifications__content {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px 32px;
}

.nafasi-notifications__tabs {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 12px;
}

.nafasi-notifications__tab {
    background: transparent;
    border: none;
    padding: 6px 16px;
    border-radius: 980px;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    line-height: 1.42859;
    font-weight: 400;
    color: #1d1d1f;
    cursor: pointer;
    transition: all 0.2s ease;
}

.nafasi-notifications__tab:hover {
    background: rgba(0, 0, 0, 0.03);
}

.nafasi-notifications__tab--active {
    background: #f5f5f7;
    color: #0071e3;
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
}

.nafasi-notifications__manage {
    margin-bottom: 16px;
}

.nafasi-notifications__list {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
    overflow: hidden;
}

.nafasi-notifications__empty {
    padding: 48px 24px;
    text-align: center;
}

.nafasi-notifications__empty-text {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    color: #86868b;
    font-size: 15px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.016em;
}

.nafasi-notifications__loading {
    display: flex;
    justify-content: center;
    padding: 24px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.nafasi-notifications__load-more {
    padding: 16px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-notifications__load-more-btn {
    background: transparent !important;
    border: 1px solid #0071e3 !important;
    color: #0071e3 !important;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42859 !important;
    font-weight: 400 !important;
    letter-spacing: -.016em !important;
    padding: 6px 16px !important;
    height: auto !important;
    border-radius: 980px !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 6px !important;
    transition: all 0.2s ease !important;
}

.nafasi-notifications__load-more-btn:hover {
    background: rgba(0, 113, 227, 0.04) !important;
    transform: translateY(-1px);
}

.nafasi-notifications__load-more-btn:active {
    transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 1068px) {
    .nafasi-notifications__content {
        padding: 20px;
    }

    .nafasi-notifications__header {
        padding: 20px;
    }
}

@media (max-width: 734px) {
    .nafasi-notifications {
        border-radius: 0;
    }

    .nafasi-notifications__content {
        padding: 16px;
    }

    .nafasi-notifications__header {
        padding: 16px;
    }

    .nafasi-notifications__title {
        font-size: 20px;
    }

    .nafasi-notifications__tabs {
        gap: 6px;
        margin-bottom: 16px;
        padding-bottom: 10px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .nafasi-notifications__tabs::-webkit-scrollbar {
        display: none;
    }

    .nafasi-notifications__tab {
        padding: 5px 14px;
        font-size: 13px;
        white-space: nowrap;
    }
} 