/* Font Declarations */
@font-face {
    font-family: 'ramblaBold';
    src: url('../../assets/fonts/Rambla-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'ramblaRegular';
    src: url('../../assets/fonts/Rambla-Regular.ttf') format('truetype');
}

/* Base Styles */
.seeker-profile {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    background: #f8fafc;
    min-height: 100vh;
    font-family: 'ramblaRegular', sans-serif;
}

/* Compact Tabs */
.compact-tabs .ant-tabs-nav {
    margin: 0;
    background: #fff;
    padding: 8px 8px 0;
    border-radius: 8px 8px 0 0;
}

.compact-tabs .ant-tabs-tab {
    padding: 8px 16px !important;
    margin: 0 4px !important;
    font-family: 'ramblaBold', sans-serif;
}

.tab-label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
}

.tab-icon {
    font-size: 0.9rem;
}

/* Section Styles */
.info-section {
    background: white;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

.info-section h4 {
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
    margin-bottom: 16px;
    color: #056899;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
}

.info-item {
    padding: 8px;
    background: #f8fafc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 60px;
}

.info-item .info-icon {
    color: #ecb81f;
    width: 16px;
    flex-shrink: 0;
}

.info-item div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.info-item label {
    font-family: 'ramblaBold', sans-serif;
    font-size: 0.8rem;
    margin: 0;
    color: #666;
}

.info-item span {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.8rem;
}

.info-item .status-badge {
    font-family: 'ramblaBold', sans-serif;
    background-color: #cad2d5;
    margin-top: 0.5rem;
}

.info-item .status-badge.status-yes {
    background-color: #52c41a;
}

.info-item .status-badge.status-no {
    background-color: #d9d9d9;
}

/* Table Styles */
.details-table {
    font-family: 'ramblaRegular', sans-serif;
}

.ant-table-wrapper .ant-table-thead > tr > th {
    font-family: 'ramblaBold', sans-serif;
    padding: 12px;
    font-size: 0.9rem;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px 12px;
    font-size: 0.9rem;
}

/* Stats Cards */
.stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

.stat-card {
    padding: 12px;
}

.stat-number {
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.5rem;
}

/* Tags */
.tag {
    font-size: 0.8rem;
    padding: 2px 8px;
}

/* Button */
.interview-button {
    font-family: 'ramblaBold', sans-serif;
    height: 32px;
    padding: 0 16px;
}

/* Modal */
.ant-modal-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

/* Responsive */
@media (max-width: 768px) {
    .seeker-profile {
        padding: 8px;
    }

    .info-section {
        padding: 12px;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .compact-tabs .ant-tabs-tab {
        padding: 6px 12px !important;
    }
}

.profile-header-section {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-bottom: 24px;
}

.profile-avatar {
    position: relative;
    top: 0.8rem;
    flex-shrink: 0;
    width: 100px;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.online-status {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.status-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid white;
}

.status-dot.online { background-color: #52c41a; }
.status-dot.offline { background-color: #d9d9d9; }

.name-section {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.verified-icon {
    color: #1890ff;
    font-size: 1.2em;
}

.experience-badge {
    background: #e6f7ff;
    color: #1890ff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    margin-left: 8px;
}

.profile-tags {
    margin-top: 8px;
    display: flex;
    gap: 8px;
}

.skill-tag {
    background: #f5f5f5;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    color: #595959;
}

.action-buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
}

.profile-stats {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.stat {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #8c8c8c;
}

.profile-header-left {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.profile-basic-info {
    padding-top: 8px;
}

.profile-location {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1px;
}

.profile-location svg {
    width: 16px;
    flex-shrink: 0;
} 

.profile-header-right {
    margin-left: 4.5rem;
}

.tags-container span {
    background-color: #a5d3fd;
    border-radius: 0.2rem;
}

.seeker-profile-container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

.profile-section {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 24px;
}

.profile-content {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

/* Add responsive handling */
@media (max-width: 1200px) {
    .profile-section {
        padding: 16px;
    }
}