.edit-job-post-container {
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica Neue', sans-serif;
}

.edit-icon {
  cursor: pointer;
  transition: opacity 0.2s ease;
  color: #0066cc;
}

.edit-icon:hover {
  opacity: 0.7;
}

.edit-job-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-section {
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e6e6e6;
}

.form-section-title {
  font-size: 1rem;
  font-weight: 500;
  color: #1d1d1f;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e6e6e6;
}

.input-group {
  margin-bottom: 0.75rem;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-label {
  display: block;
  font-size: 0.85rem;
  font-weight: 500;
  color: #1d1d1f;
  margin-bottom: 0.3rem;
}

.text-input,
.select-input,
.textarea-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d2d2d7;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  background-color: #ffffff;
  min-height: 36px;
}

.text-input:focus,
.select-input:focus,
.textarea-input:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
}

.input-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.input-row:last-child {
  margin-bottom: 0;
}

.file-upload {
  border: 1px dashed #d2d2d7;
  border-radius: 6px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #f9f9f9;
}

.file-upload:hover {
  border-color: #0066cc;
  background-color: rgba(0, 102, 204, 0.05);
}

.file-upload input[type="file"] {
  font-size: 0.85rem;
}

.date-picker {
  width: 100% !important;
}

.date-picker input {
  min-height: 36px;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
}

.current-location {
  background: #f9f9f9;
  padding: 0.6rem 0.75rem;
  border-radius: 6px;
  margin-bottom: 0.75rem;
  border: 1px solid #e6e6e6;
}

.current-location p {
  margin: 0;
  color: #1d1d1f;
  font-size: 0.85rem;
  line-height: 1.4;
}

.current-location p:first-child {
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  padding: 0.75rem 1.5rem;
  background: #ffffff;
  border-top: 1px solid #e6e6e6;
}

.save-button {
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 36px;
}

.save-button:hover {
  background-color: #004499;
}

.cancel-button {
  background-color: transparent;
  color: #1d1d1f;
  border: 1px solid #d2d2d7;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 36px;
}

.cancel-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  min-height: 36px;
}

/* Ant Design Modal Overrides */
.ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ant-modal-header {
  padding: 1rem 1.5rem;
  background: #ffffff;
  border-bottom: 1px solid #e6e6e6;
}

.ant-modal-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1d1d1f;
}

.ant-modal-body {
  padding: 1.5rem;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.ant-modal-close {
  color: #1d1d1f;
}

.ant-modal-close:hover {
  color: #0066cc;
}

/* Message styles */
.success-message {
  color: #00a94f;
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

.error-message {
  color: #ff3b30;
  margin-top: 0.5rem;
  font-size: 0.85rem;
} 