.nafasi-subscriptions__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 0.5rem;
    border-radius: 12px;
}

.nafasi-subscriptions {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-subscriptions__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-subscriptions__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-subscriptions__icon {
    color: #0066cc;
}

.nafasi-subscriptions__title {
    margin: 0;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.25rem;
    color: #1d1d1f;
}

.nafasi-subscriptions__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-subscriptions__packages {
    padding: 1rem 0;
}

/* Responsive styles */
@media screen and (max-width: 1068px) {
    .nafasi-subscriptions__container {
        padding: 1.5rem;
    }
    
    .nafasi-subscriptions__packages {
        padding: 0.75rem 0;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-subscriptions__container {
        padding: 1rem;
    }
    
    .nafasi-subscriptions__title {
        font-size: 1.1rem;
    }
    
    .nafasi-subscriptions__packages {
        padding: 0.5rem 0;
    }
} 