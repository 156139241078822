.nafasi-settings {
    padding: 0;
    background: #ffffff;
    min-height: calc(100vh - 64px);
    border-radius: 12px;
}

.nafasi-settings__header {
    padding: 24px 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    position: sticky;
    top: 0;
    z-index: 10;
    border-radius: 12px 12px 0 0;
}

.nafasi-settings__header-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-settings__icon {
    color: #1d1d1f;
    opacity: 0.9;
}

.nafasi-settings__title {
    margin: 0;
    font-family: 'ramblaBold', sans-serif;
    font-size: 20px;
    color: #1d1d1f;
    letter-spacing: -0.01em;
}

.nafasi-settings__content {
    padding: 0 32px;
}

/* Tabs Styling */
.nafasi-settings__tabs {
    margin-top: 16px;
}

.nafasi-settings__tabs .ant-tabs-nav {
    margin: 0;
}

.nafasi-settings__tabs .ant-tabs-nav::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-settings__tab-label {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    padding: 8px 16px;
    transition: all 0.2s ease;
}

.nafasi-settings__tabs .ant-tabs-tab {
    padding: 8px 0;
    margin: 0 16px 0 0;
}

.nafasi-settings__tabs .ant-tabs-tab:hover .nafasi-settings__tab-label {
    color: #0071e3;
}

.nafasi-settings__tabs .ant-tabs-tab.ant-tabs-tab-active .nafasi-settings__tab-label {
    color: #0071e3;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-settings__tabs .ant-tabs-ink-bar {
    background: #0071e3;
    height: 2px;
}

/* Content Areas */
.nafasi-settings__blogs {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.nafasi-settings__footer {
    padding: 24px 32px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    margin-top: 48px;
}

.nafasi-settings__version {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 12px;
    color: #86868b;
}

/* Media Queries */
@media screen and (max-width: 1068px) {
    .nafasi-settings__header {
        padding: 20px 24px;
    }

    .nafasi-settings__content {
        padding: 0 24px;
    }

    .nafasi-settings__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-settings__header {
        padding: 16px 20px;
    }

    .nafasi-settings__content {
        padding: 0 20px;
    }

    .nafasi-settings__title {
        font-size: 17px;
    }

    .nafasi-settings__tab-label {
        font-size: 13px;
        padding: 6px 12px;
    }
} 