.nafasi-profile {
    min-height: calc(100vh - 64px);
    background: #fff;
    padding: 24px;
}

.nafasi-profile__container {
    max-width: 980px;
    margin: 0 auto;
    display: grid;
    gap: 24px;
}

/* Company Card */
.nafasi-profile__company-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    overflow: hidden;
}

.nafasi-profile__company-header {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-profile__company-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 17px;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-profile__company-body {
    padding: 20px;
}

.nafasi-profile__company-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    align-items: start;
}

.nafasi-profile__info-container {
    display: flex;
    flex-direction: column;
}

.nafasi-profile__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.nafasi-profile__logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 8px;
}

/* Form Group */
.nafasi-profile__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.nafasi-profile__label {
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #86868b;
}

/* Input with Icon */
.nafasi-profile__input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
}

.nafasi-profile__input {
    width: 100%;
    height: 36px;
    padding: 8px 12px 8px 36px;
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
    background: #fff;
    transition: all 0.2s ease;
}

.nafasi-profile__input:disabled {
    background: #f5f5f7;
    color: #86868b;
}

.nafasi-profile__icon {
    position: absolute;
    left: 12px;
    color: #0071e3;
    display: flex;
    align-items: center;
}

/* Admin Card */
.nafasi-profile__admin-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    overflow: hidden;
}

.nafasi-profile__admin-header {
    height: 80px;
    background: #0071e3;
    position: relative;
}

.nafasi-profile__admin-avatar {
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.nafasi-profile__admin-body {
    padding: 48px 20px 20px;
}

.nafasi-profile__admin-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 17px;
    color: #1d1d1f;
    text-align: center;
    margin-bottom: 24px;
}

/* Footer Actions */
.nafasi-profile__actions {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-profile__button {
    height: 32px;
    padding: 0 16px;
    border-radius: 980px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;
}

.nafasi-profile__button--primary {
    background: #0071e3;
    color: #fff;
    border: none;
}

.nafasi-profile__button--primary:hover {
    background: #0077ed;
    transform: translateY(-1px);
}

.nafasi-profile__button--secondary {
    background: #f5f5f7;
    color: #1d1d1f;
    border: none;
}

.nafasi-profile__button--secondary:hover {
    background: #e5e5e7;
    transform: translateY(-1px);
}

/* Unauthorized */
.nafasi-profile__unauthorized {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 64px);
    padding: 24px;
    background: #f5f5f7;
}

.nafasi-profile__unauthorized-message {
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    text-align: center;
}

/* Responsive */
@media (max-width: 1068px) {
    .nafasi-profile__container {
        max-width: 692px;
    }
}

@media (max-width: 734px) {
    .nafasi-profile {
        padding: 16px;
    }

    .nafasi-profile__company-grid {
        grid-template-columns: 1fr;
    }

    .nafasi-profile__actions {
        flex-direction: column;
    }

    .nafasi-profile__button {
        width: 100%;
    }
}

.nafasi-profile__documents {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-profile__documents-title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    margin: 0 0 16px;
}

.nafasi-profile__document-preview {
    background: #f5f5f7;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
}

.nafasi-profile__document-preview:last-child {
    margin-bottom: 0;
}

.nafasi-profile__document-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nafasi-profile__document-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nafasi-profile__document-name {
    font-family: 'ramblaBold', sans-serif;
    font-size: 14px;
    color: #1d1d1f;
}

.nafasi-profile__document-view {
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #0071e3;
    text-decoration: none;
    padding: 4px 12px;
    border-radius: 980px;
    transition: all 0.2s ease;
}

.nafasi-profile__document-view:hover {
    background: rgba(0, 113, 227, 0.1);
    color: #0077ed;
}

@media (max-width: 734px) {
    .nafasi-profile__documents {
        margin-top: 20px;
        padding-top: 20px;
    }

    .nafasi-profile__documents-title {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .nafasi-profile__document-preview {
        padding: 12px;
        gap: 12px;
    }

    .nafasi-profile__document-name {
        font-size: 13px;
    }

    .nafasi-profile__document-view {
        font-size: 12px;
        padding: 4px 8px;
    }
}
  