.nafasi-profile-modal .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.nafasi-profile-modal__content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.nafasi-profile-modal__icon {
    margin-bottom: 24px;
}

.nafasi-profile-modal__title {
    font-family: 'ramblaBold', sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: #1d1d1f;
    margin: 0 0 12px;
}

.nafasi-profile-modal__text {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 15px;
    line-height: 1.4;
    color: #86868b;
    margin: 0 0 32px;
    max-width: 320px;
}

.nafasi-profile-modal__button {
    height: 32px;
    padding: 0 20px;
    border-radius: 980px;
    background: #0071e3;
    color: #fff;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.nafasi-profile-modal__button:hover {
    background: #0077ed;
    transform: translateY(-1px);
}

.nafasi-profile-modal__button:active {
    background: #0062c1;
    transform: translateY(0);
}

/* Responsive */
@media (max-width: 734px) {
    .nafasi-profile-modal__content {
        padding: 24px 16px;
    }

    .nafasi-profile-modal__title {
        font-size: 20px;
    }

    .nafasi-profile-modal__text {
        font-size: 14px;
        margin-bottom: 24px;
    }
} 