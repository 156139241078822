.nafasi-feedback-card {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-feedback-card__table {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Style the table header */
.nafasi-feedback-card__table :global(.ant-table-thead > tr > th) {
    background: #f5f5f7;
    font-size: 13px;
    font-weight: 600;
    color: #1d1d1f;
    padding: 12px 16px;
    border-bottom: 1px solid #e5e5e5;
}

/* Style the table cells */
.nafasi-feedback-card__table :global(.ant-table-tbody > tr > td) {
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s ease;
}

/* Style the table rows */
.nafasi-feedback-card__table :global(.ant-table-tbody > tr) {
    transition: all 0.2s ease;
}

.nafasi-feedback-card__table :global(.ant-table-tbody > tr:hover) {
    background-color: #f5f5f7;
}

/* Style the cell content */
.nafasi-feedback-card__cell {
    font-size: 14px;
    color: #1d1d1f;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-card__cell--rating {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 500;
    background: #f5f5f7;
    font-family: 'ramblaRegular', sans-serif;
}

/* Excellent - Deep Green */
.nafasi-feedback-card__cell--rating.excellent {
    color: #248a3d;
    background: rgba(36, 138, 61, 0.1);
}

/* Good - Light Green */
.nafasi-feedback-card__cell--rating.good {
    color: #28cd41;
    background: rgba(40, 205, 65, 0.1);
}

/* Neutral - Blue */
.nafasi-feedback-card__cell--rating.neutral {
    color: #0066cc;
    background: rgba(0, 102, 204, 0.1);
}

/* Poor - Orange */
.nafasi-feedback-card__cell--rating.poor {
    color: #ff9f0a;
    background: rgba(255, 159, 10, 0.1);
}

/* Very Poor - Red */
.nafasi-feedback-card__cell--rating.very-poor {
    color: #ff3b30;
    background: rgba(255, 59, 48, 0.1);
}

.nafasi-feedback-card__cell--time {
    color: #86868b;
    font-size: 13px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nafasi-feedback-card__table :global(.ant-table-thead > tr > th),
    .nafasi-feedback-card__table :global(.ant-table-tbody > tr > td) {
        padding: 10px 12px;
    }

    .nafasi-feedback-card__cell {
        font-size: 13px;
    }
} 