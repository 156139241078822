.nafasi-blog__container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 3.5rem 0.5rem;
    border-radius: 12px;
}

.nafasi-blog {
    max-width: 800px;
    margin: 0 auto;
}

.nafasi-blog__header-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-blog__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-blog__icon {
    color: #0066cc;
}

.nafasi-blog__title {
    margin: 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
}

.nafasi-blog__content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.nafasi-blog__skeleton {
    padding: 1rem;
}

.nafasi-blog__post-header {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-blog__post-title {
    margin: 0 0 0.5rem 0 !important;
    font-family: 'ramblaBold', sans-serif !important;
    color: #1d1d1f !important;
    font-size: 2rem !important;
    line-height: 1.3 !important;
}

.nafasi-blog__post-date {
    font-family: 'ramblaRegular', sans-serif !important;
    font-size: 0.9rem !important;
    color: #86868b !important;
}

.nafasi-blog__post-content {
    padding: 1.5rem;
    font-family: 'ramblaRegular', sans-serif;
    color: #1d1d1f;
    line-height: 1.6;
}

.nafasi-blog__post-content p {
    margin-bottom: 1.5rem;
}

.nafasi-blog__post-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1.5rem 0;
}

.nafasi-blog__post-content h1,
.nafasi-blog__post-content h2,
.nafasi-blog__post-content h3,
.nafasi-blog__post-content h4,
.nafasi-blog__post-content h5,
.nafasi-blog__post-content h6 {
    font-family: 'ramblaBold', sans-serif;
    color: #1d1d1f;
    margin: 2rem 0 1rem;
}

.nafasi-blog__post-content a {
    color: #0066cc;
    text-decoration: none;
    transition: color 0.2s ease;
}

.nafasi-blog__post-content a:hover {
    color: #0077ed;
}

.nafasi-blog__post-content blockquote {
    margin: 1.5rem 0;
    padding: 1rem 1.5rem;
    border-left: 4px solid #0066cc;
    background-color: rgba(0, 102, 204, 0.05);
    font-style: italic;
}

.nafasi-blog__post-content code {
    background-color: #f5f5f7;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9em;
}

.nafasi-blog__post-content pre {
    background-color: #f5f5f7;
    padding: 1rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 1.5rem 0;
}

.nafasi-blog__post-content pre code {
    background-color: transparent;
    padding: 0;
}

/* Responsive styles */
@media screen and (max-width: 1068px) {
    .nafasi-blog__container {
        padding: 1.5rem;
    }
    
    .nafasi-blog__post-header,
    .nafasi-blog__post-content {
        padding: 1.25rem;
    }
    
    .nafasi-blog__post-title {
        font-size: 1.75rem !important;
    }
}

@media screen and (max-width: 734px) {
    .nafasi-blog__container {
        padding: 1rem;
    }
    
    .nafasi-blog__title {
        font-size: 1.1rem !important;
    }
    
    .nafasi-blog__post-header,
    .nafasi-blog__post-content {
        padding: 1rem;
    }
    
    .nafasi-blog__post-title {
        font-size: 1.5rem !important;
    }
    
    .nafasi-blog__post-content {
        font-size: 0.95rem;
    }
} 