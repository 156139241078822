.truncated-link {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 0;
    font-family: 'ramblaRegular';
}

.truncated-link p {
    margin: 0;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

.truncated-link svg {
    flex-shrink: 0;
}

.more-indicator {
    color: #666;
    font-size: 0.85em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

/* Modal Styles */
.experience-modal .experience-list,
.industries-modal .industries-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'ramblaBold';
}

.modal-title svg {
    flex-shrink: 0;
}

/* Experience specific styles */
.experience-item {
    padding: 12px;
    border-radius: 6px;
    background-color: #f8f9fa;
}

.experience-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.experience-header h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    font-family: 'ramblaBold';
    transform: translateY(-1px);
}

.experience-duration {
    font-size: 12px;
    color: #666;
    line-height: 1;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

.experience-positions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.experience-position {
    padding-left: 16px;
    border-left: 2px solid #e9ecef;
}

.position-title {
    font-size: 13px;
    margin-bottom: 2px;
    line-height: 1;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

.position-duration {
    font-size: 12px;
    color: #666;
    line-height: 1;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

/* Industries specific styles */
.industry-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #f8f9fa;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1;
    font-family: 'ramblaRegular';
}

.industry-item span {
    transform: translateY(-1px);
}

.industry-item svg {
    flex-shrink: 0;
} 