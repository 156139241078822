/* Left Side */
.left-side {
    width: 250px;
    height: 250px;
    background: #ddd;
    display: inline-block;
    float: left;
    position: sticky;
    /* top: 50px; */
}

/* Body */
body {
    margin: 0;
}

/* Section */
.section {
    height: 250px;
    background: #bbb;
    text-align: center;
    padding: 20px 0;
}

/* Main Section */
.main-section {
    width: 1100px;
    margin: 0px auto;
    clear: both;
}

.main-section:before,
.main-section:after {
    content: '';
    display: table;
    clear: both;
}

/* Right Side */
.right-side {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
}

.right-side div {
    /* background: #999; */
    height: 250px;
}

.right-side div + div {
    margin-top: 50px;
}

/* Box */
.box {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.box span:nth-child(2) {
    animation-delay: -0.2s;
}

.box span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}




.employeeSummaryContainerr {
    margin-top: 0rem;
    background-color: #ffffff;
    border-top-left-radius: 1rem;
    box-shadow: 0 1px 1px rgba(24, 24, 24, 0.034),
      0 1px 2px rgba(24, 24, 24, 0.034), 0 2px 2px rgba(10, 10, 10, 0.06),
      0 1px 2px rgba(19, 19, 19, 0.072), 0 1px 2px rgba(31, 31, 31, 0.086),
      0 2px 2px rgba(56, 56, 56, 0.12);
  }

  .employeeInfoBoxx {
    /* border: 0.01rem solid lightgray; */
    border-top-left-radius: 0rem;
  }

  .employeeNameBoxx {
    display: flex;
    background-color: #0793d9;
    padding: 0.7rem 1rem 0.1rem 1rem;
    /* border: 0.1rem solid lightgray; */
  }


  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.item {
    width: 300px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.item:nth-child(even) {
    background-color: #f9f9f9;
}

.item:last-child {
    margin-bottom: 0;
}

.item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.item-description {
    font-size: 0.9rem;
    color: #666;
}

.nice-list {
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .list-item {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    transition: background-color 0.3s;
  }
  
  .list-item:hover {
    background-color: #f0f0f0;
  }
  
.recent-searches-container {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    border-radius: 18px;
    padding: 24px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    max-width: 800px;
    margin: 0 auto;
}

.recent-searches-header h2 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 20px;
}

.recent-searches-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    color: #86868b;
}

.recent-searches-empty .search-icon {
    margin-bottom: 12px;
    color: #86868b;
}

.recent-searches-empty p {
    font-size: 15px;
    font-weight: 400;
}

.recent-searches-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.search-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 12px;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.search-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.search-item-icon {
    color: #86868b;
    margin-right: 16px;
}

.search-item-content {
    flex: 1;
}

.search-item-primary {
    color: #1d1d1f;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 4px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.search-item-secondary {
    color: #86868b;
    font-size: 13px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.chevron-icon {
    color: #86868b;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.search-item:hover .chevron-icon {
    opacity: 1;
}
  