.nafasi-legal-add {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-legal-add__trigger {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    color: #06c;
    font-size: 15px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-legal-add__trigger:hover {
    background: #f5f5f7;
    border-color: #d1d1d6;
}

.nafasi-legal-add__container {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-legal-add__container :global(.ant-modal-content) {
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-legal-add__container :global(.ant-modal-header) {
    padding: 20px 24px;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f7;
    margin-bottom: 0;
}

.nafasi-legal-add__container :global(.ant-modal-title) {
    font-size: 20px;
    font-weight: 600;
    color: #1d1d1f;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-legal-add__container :global(.ant-modal-body) {
    padding: 24px;
}

.nafasi-legal-add__content {
    max-width: 600px;
    margin: 0 auto;
}

.nafasi-legal-add__title {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 24px;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-legal-add__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.nafasi-legal-add__input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nafasi-legal-add__label {
    font-size: 14px;
    color: #1d1d1f;
    font-weight: 500;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-legal-add__input {
    width: 100%;
    padding: 12px;
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    font-size: 15px;
    color: #1d1d1f;
    transition: all 0.2s ease;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-legal-add__input:focus {
    outline: none;
    border-color: #0071e3;
    box-shadow: 0 0 0 4px rgba(0, 113, 227, 0.1);
}

.nafasi-legal-add__file-input {
    margin: 20px 0;
}

.nafasi-legal-add__file-input input {
    display: none;
}

.nafasi-legal-add__file-label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    background: #f5f5f7;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-legal-add__file-label:hover {
    background: #e5e5ea;
}

.nafasi-legal-add__file-name {
    font-size: 14px;
    color: #1d1d1f;
    margin-left: 8px;
}

.nafasi-legal-add__actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.nafasi-legal-add__button {
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-legal-add__button--primary {
    background: #0071e3;
    color: #fff;
    border: none;
}

.nafasi-legal-add__button--primary:hover {
    background: #0077ed;
}

.nafasi-legal-add__button--loading {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nafasi-legal-add__content {
        max-width: 100%;
    }
    
    .nafasi-legal-add__container :global(.ant-modal-body) {
        padding: 16px;
    }
} 