.posts-container {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 20px;
    padding: 28px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.posts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.posts-title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.posts-title h2 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
}

.header-icon {
    color: #06c;
}

.view-all-link {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #06c;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 980px;
    transition: background-color 0.2s ease;
}

.view-all-link:hover {
    background-color: rgba(0, 102, 204, 0.1);
}

.posts-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    text-align: center;
}

.posts-empty .news-icon {
    background: rgba(0, 102, 204, 0.1);
    color: #06c;
    padding: 16px;
    border-radius: 50%;
    margin-bottom: 16px;
}

.posts-empty h3 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #1d1d1f;
    margin: 0 0 8px 0;
    font-size: 20px;
}

.posts-empty p {
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #86868b;
    font-size: 15px;
    margin: 0;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.post-item {
    background: white;
    border-radius: 14px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.post-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
    .posts-container {
        padding: 20px;
        border-radius: 16px;
    }

    .posts-title h2 {
        font-size: 24px;
    }

    .posts-grid {
        grid-template-columns: 1fr;
    }
} 