.links-container {
    font-family: 'ramblaRegular', sans-serif;
    background: #f8fafc;
    min-height: 200px;
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 0;
}

.link-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
}

.link-card:hover {
    transform: translateY(-2px);
}

.link-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.link-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
}

.link-info {
    flex: 1;
}

.link-info h4 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
}

.link-type {
    color: #6B7280;
    font-size: 0.85rem;
    display: block;
    margin-top: 2px;
    text-transform: capitalize;
    font-family: "ramblaRegular";
}

.link-description {
    color: #4B5563;
    font-size: 0.9rem;
    margin: 8px 0;
    line-height: 1.5;
    font-family: "ramblaRegular";
}

.visit-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    text-decoration: none;
    padding: 6px 12px;
    border-radius: 6px;
    margin-top: auto;
    transition: opacity 0.2s;
    color: #056899;
    font-family: "ramblaRegular";
}

.visit-link:hover {
    opacity: 0.9;
    text-decoration: none;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.section-header h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.section-icon {
    color: #ecb81f;
}

.section-summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.total-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.links-timeline {
    position: relative;
    padding: 20px 0;
}

@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .section-summary {
        width: 100%;
        justify-content: flex-start;
    }

    .links-grid {
        grid-template-columns: 1fr;
    }
} 