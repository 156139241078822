.languages-container {
    padding: 16px 0;
}

.languages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
}

.language-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.language-card:hover {
    transform: translateY(-2px);
}

.language-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.language-icon {
    width: 32px;
    height: 32px;
    background: #EFF6FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3B82F6;
}

.language-header h4 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
}

.proficiency-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.language-description {
    color: #4B5563;
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.5;
}

.no-data-message {
    text-align: center;
    padding: 48px 0;
    color: #6B7280;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #D1D5DB;
}

@media (max-width: 768px) {
    .languages-grid {
        grid-template-columns: 1fr;
    }
} 