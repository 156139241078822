@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaRegular";
    src: url("../../assets/fonts/Rambla-Regular.ttf");
}

@font-face {
    /*Myriad Set Pro Text*/
    font-family: "ramblaBold";
    src: url("../../assets/fonts/Rambla-Bold.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.signup-container {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 15px;
    position: relative;
    overflow: hidden;
}

/* Add animated background elements */
.signup-container::before,
.signup-container::after {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: rgba(7, 147, 217, 0.05);
    animation: float 8s infinite ease-in-out;
    opacity: 0.7;
}

.signup-container::before {
    top: -100px;
    right: -100px;
}

.signup-container::after {
    bottom: -100px;
    left: -100px;
    animation-delay: -4s;
}

@keyframes float {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(-20px, 20px); }
}

.signup-form {
    background: white;
    margin-top: 25px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 360px;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.95);
}

.signup-form img {
    display: block;
    margin: 0 auto 16px;
    max-width: 140px;
}

.signup-form .ant-input,
.signup-form .ant-input-password {
    height: 36px;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 13px;
    padding: 0 11px;
}

.signup-form .ant-form-item-label > label {
    font-size: 12px;
    font-family: 'ramblaBold', sans-serif;
    color: #666;
    height: 20px;
}

.signup-form .ant-btn {
    height: 36px;
    border-radius: 6px;
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    width: 100%;
    margin-bottom: 6px;
    transition: all 0.3s ease;
}

.signup-form .ant-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(7, 147, 217, 0.2);
}

.signup-form .ant-btn-primary {
    background: #0793d9;
    border-color: #0793d9;
}

.signup-form .ant-btn-default {
    border-color: #0793d9;
    color: #0793d9;
}

.section-title {
    font-family: 'ramblaBold', sans-serif;
    color: #333;
    margin-bottom: 16px;
    font-size: 18px;
}

.signup-divider {
    display: flex;
    align-items: center;
    margin: 12px 0;
    color: #666;
    font-family: 'ramblaRegular', sans-serif;
    font-size: 12px;
}

.signup-divider::before,
.signup-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e8e8e8;
}

.signup-divider span {
    padding: 0 8px;
}

.app-badges {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
}

.app-badges img {
    height: 36px;
    width: auto;
    transition: transform 0.3s ease;
}

.app-badges img:hover {
    transform: scale(1.05);
}

/* Decorative elements */
.background-decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
}

.decoration-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(7, 147, 217, 0.2);
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 0.6; }
    50% { transform: scale(1.5); opacity: 0.3; }
    100% { transform: scale(1); opacity: 0.6; }
}

/* Add compact styling for password rules */
.password-rules {
    font-size: 11px;
    color: #666;
    margin-bottom: 6px;
    line-height: 1.2;
    margin-top: -4px;
}

/* Adjust the "Register here" link spacing */
.register-link {
    font-size: 12px;
    margin: 4px 0;
    text-align: center;
    color: #666;
}

/* Mobile view adjustments */
.mobile-view {
    text-align: center;
    padding: 20px;
    background: #1a1a1a;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-view .section-title {
    font-size: 16px;
    margin-bottom: 12px;
}

/* Add these styles to reduce gaps between form items */
.signup-form .ant-form-item {
    margin-bottom: 8px;
}

/* Adjust spacing for the last form item */
.signup-form .ant-form-item:last-child {
    margin-bottom: 4px;
}

/* Reduce space between form items and other elements */
.signup-form .ant-form-item-explain {
    min-height: 16px;
    font-size: 11px;
    margin-top: 2px;
}

/* Adjust error message spacing */
.signup-form .ant-form-item-has-error .ant-form-item-explain {
    margin-bottom: 4px;
}

/* Adjust ForgotPassword component spacing */
.forgot-password {
    margin: 4px 0 8px;
    font-size: 12px;
}

/* Adjust spacing for buttons container */
.signup-form .ant-form-item:has(button) {
    margin-top: 8px;
    margin-bottom: 4px;
}

/* Sign in link styling */
.signup-link {
    text-align: center;
    color: #64748b;
    margin: 0.75rem 0;
    font-size: 13px;
}

.signup-link a {
    color: #0793d9;
    font-weight: 500;
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}

/* Section divider styling */
.section-divider {
    text-align: center;
    margin: 1rem 0;
    position: relative;
}

.section-divider::before,
.section-divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 35%;
    height: 1px;
    background: #e5e7eb;
}

.section-divider::before {
    left: 0;
}

.section-divider::after {
    right: 0;
}

.section-divider span {
    background: white;
    padding: 0 0.5rem;
    color: #64748b;
    font-size: 13px;
    font-weight: 500;
}

/* App badges styling */
.app-badges {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
}

.signin-link {
    font-size: 14px;
}
.app-badges img {
    height: 32px;
    transition: transform 0.3s ease;
}

.app-badges img:hover {
    transform: translateY(-2px);
}

/* Background decoration styling */
.background-decoration {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    overflow: hidden;
}

.decoration-dot {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(7, 147, 217, 0.15);
    animation: float 4s infinite ease-in-out;
}

@keyframes float {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-10px, 10px);
    }
}

/* Mobile view styling */
.mobile-view {
    min-height: 100vh;
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, #0793d9, #05618c);
    color: white;
}

.mobile-view .section-title {
    color: white;
    margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .signup-form {
        padding: 1.25rem;
        margin: 0.5rem;
        max-width: 100%;
    }

    .background-decoration {
        display: none;
    }
}

/* Checkbox styling */
.ant-checkbox-wrapper {
    color: #64748b;
    font-size: 12px;
    font-family: "ramblaRegular", sans-serif;
}

.ant-checkbox-wrapper span {
    font-family: "ramblaRegular", sans-serif;
    font-size: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0793d9;
    border-color: #0793d9;
}

.regular {
    font-family: "ramblaRegular", sans-serif;
}

/* Form spacing */
.ant-form-item {
    margin-bottom: 12px;
}

/* Add subtle background pattern */
.signup-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #f5f7fa 25%, transparent 25%),
                linear-gradient(-45deg, #f5f7fa 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, #f5f7fa 75%),
                linear-gradient(-45deg, transparent 75%, #f5f7fa 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    opacity: 0.3;
    z-index: -1;
}

/* Form labels */
.ant-form-item-label > label {
    font-family: "ramblaRegular", sans-serif;
    font-size: 13px;
    color: #64748b;
}

/* Placeholder text */
.ant-input::placeholder,
.ant-input-password input::placeholder {
    color: #a0aec0;
    font-family: "ramblaRegular", sans-serif;
    font-size: 13px;
}

/* Password input icon */
.ant-input-password-icon {
    color: #64748b;
    font-size: 14px;
}

/* Password Input Styles */
.nafasi-password-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.nafasi-password-input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.2s;
    padding-right: 40px; /* Reduced padding to prevent overlap */
}

.nafasi-password-input:focus {
    border-color: #4f46e5;
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
    outline: none;
}

.nafasi-password-toggle {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 4px 8px;
    color: #64748b;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.nafasi-password-toggle:hover {
    color: #0793d9;
}

.nafasi-password-toggle:focus {
    outline: none;
}

.nafasi-password-toggle .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
}

/* Override any Ant Design styles that might interfere */
.nafasi-password-wrapper .ant-input-suffix {
    display: none;
}

.nafasi-password-wrapper .ant-input-affix-wrapper {
    padding: 0;
    border: none;
    box-shadow: none;
}

.nafasi-password-wrapper .ant-input-affix-wrapper:hover,
.nafasi-password-wrapper .ant-input-affix-wrapper:focus {
    border: none;
    box-shadow: none;
}
