.announcements-container {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    border-radius: 18px;
    padding: 24px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    max-width: 800px;
    margin: 0 auto;
}

.announcements-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
}

.announcements-header h2 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 0;
}

.notification-count {
    background: #06c;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 12px;
    min-width: 24px;
    text-align: center;
}

.announcements-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    color: #86868b;
}

.announcements-empty .bullhorn-icon {
    margin-bottom: 12px;
    color: #86868b;
}

.announcements-empty p {
    font-size: 15px;
    font-weight: 400;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.announcements-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.announcement-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    transition: all 0.2s ease;
    cursor: pointer;
}

.announcement-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.announcement-item-icon {
    color: #06c;
    margin-right: 16px;
}

.announcement-item-content {
    flex: 1;
    min-width: 0;
}

.announcement-item-primary {
    color: #1d1d1f;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.new-badge {
    background-color: #bf4800;
    color: white;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
}

.announcement-item-secondary {
    color: #86868b;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.announcement-item-secondary span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dot {
    font-size: 8px;
    line-height: 1;
    flex-shrink: 0;
}

.chevron-icon {
    color: #86868b;
    opacity: 0;
    transition: opacity 0.2s ease;
    flex-shrink: 0;
}

.announcement-item:hover .chevron-icon {
    opacity: 1;
}

.announcement-popover-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

.announcement-popover {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    width: 100%;
    max-width: 560px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    animation: popoverEnter 0.3s ease-out;
}

.announcement-popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.announcement-popover-title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.announcement-popover-title h3 {
    font-family: "ramblaBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: #1d1d1f;
    margin: 0;
}

.popover-icon {
    color: #06c;
}

.close-button {
    background: none;
    border: none;
    color: #86868b;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #1d1d1f;
}

.announcement-popover-content {
    padding: 24px;
}

.announcement-message {
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #1d1d1f;
    margin: 0 0 20px 0;
}

.announcement-meta {
    color: #86868b;
    font-size: 13px;
    font-family: "ramblaRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@keyframes popoverEnter {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Add responsive styles */
@media (max-width: 640px) {
    .announcement-popover {
        margin: 0;
        max-height: 90vh;
        overflow-y: auto;
    }
} 