@media only screen and (max-width: 720px){
    /*NAVBAR*/

    .split-landing img {
        height: 20rem;
        width: 20rem;
        margin: 0;
    }
    /*FOOTER*/
    .footer h4 {
        font-size: 1.2rem
    }

    .footer a {
        font-size: 0.8rem;
        color: #424245;
    }

        /*lANDING PAGE*/
    .landingPageLogo {
        padding: 0.8rem 0 0 1.2rem;
    }
    .landingPageLogo img {
        width: 10%;
        object-fit: contain;
    }
    .landingIntroContainer {
        border-bottom-right-radius: unset;
    }
    .landingIntroBox {
        width: 90%;
    }

    #benefits {
        background-color: #ffffff;
        width: 100%;
        padding: 1rem 0 2rem 0;
        margin: 1rem 0 0 0;
        border-radius: 0.3rem;
    }

    .landingIntroBox h4 {
        font-size: 1.3rem;
        width: 100%;
    }
    .landingIntroBox p {
        font-size: 1rem;
        width: 90%;
        margin-top: 1.5rem;
        z-index: 1;
    }
    .osportArtwork {
        width: 70%;
        bottom: 13%;
        right: 15%;
    }

    /*FAQ*/
    .faqIntroContainer {
        border-bottom-right-radius: unset;
    }
    .faqIntroBox {
        width: 90%;
    }
    .faqIntroBox h4 {
        font-size: 1.2rem;
    }
    .faqQnBox {
        width: 90%;
    }
    .faqAnswerBox {
        font-size: 1rem;
    }
    .faqMoreContact {
        width: 90%;
    }
    .faqMoreContact h5 {
        font-size: 1.1rem;
    }

    /*CONTACT US*/
    .contactUsIntroContainer {
        border-bottom-right-radius: unset;
    }
    .contactUsIntro {
        width: 90%;
    }
    .contactUsIntro h4 {
        font-size: 1.3rem;
        width: 100%;
    }
    .contactUsIntro p {
        font-size: 1rem;
        width: 90%;
        margin-top: 1rem;
        z-index: 1;
        text-align: center;
    }
    .contactUsFormBox {
        width: 90%;
        display: grid;
        grid-template: 1fr / 1fr;
    }
    .contactUsDetails h4 {
        font-size: 1.2rem;
        text-align: center;
    }
    .contactUsDetails p {
        font-size: 1rem;
        text-align: justify;
    }
    .contactUsMap {
        margin-top: 0.2rem;
    }
    .contactUsMap > div:first-child h4 {
        font-size: 1.2rem;
    }

    /*PRICING*/
    .pricingIntroBox {
        width: 90%;
    }
    .pricingIntroBox h4 {
        font-size: 1.2rem;
        margin-top: 1.5rem;
    }
    .pricingIntroBox p {
        font-size: 0.95rem;
        text-align: justify;
    }
    .pricingInclusionBox {
        width: 90%;
        margin: 1.4rem auto;
    }
    .pricingInclusionBox ul {
        margin: 0;
        display: flex;
        flex-direction: column;
    }
    .pricingInclusionBox ul li {
        text-align: center;
        margin-left: -2rem;
    }
    .pricingCardsBox {
        width: 90%;
        margin: 2rem auto;
        grid-template: 1fr / 1fr;
    }

    /*JOIN US*/
    .joinUsIntroContainer {
        width: 100%;
        border-bottom-right-radius: unset;
    }
    .joinUsBox {
        width: 90%;
        display: grid;
        grid-template: 1fr / 1fr;
        padding-top: 2rem;
    }
    .joinUsIntroBox h4 {
        font-size: 1rem;
        text-align: center;
        padding: 1rem;
    }
    .joinUsFormDetails input {
        z-index: 3;
    }
    .joinUsFormBox > div:first-child h4 {
        font-size: 1rem;
    }
    .joinTermsAgree p {
        font-size: 1rem;
    }
    .ospotJoinArtwork {
        position: absolute;
        top: 0;
        left: 13%;
        width: 72%;
        object-fit: contain;
        opacity: .2;
        z-index: 2;
    }

    .landingIntroBox,
    .faqIntroBox,
    .contactUsIntro,
    .pricingIntroBox {
        padding-top: 2rem;
    }

    .reference {
        background-color: white;
        padding: 5rem 0.5rem;
    }

    /* Media Query for Mobile */
    @media (max-width: 768px) {
        .landingIntroBox {
            width: 90%;
            padding-top: 3rem;
        }

        #benefits {
            width: 90vw;
            margin-top: 1rem;
        }

        #benefits ul {
            padding: 1rem
        }

        .nafasiArtwork {
            position: static;
            width: 80%;
            margin-top: 2rem;
        }
    }
}