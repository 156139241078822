.nafasi-payment-card {
    width: 100%;
}

.nafasi-payment-card__table {
    width: 100%;
}

.nafasi-payment-card__table .ant-table {
    background: transparent;
}

.nafasi-payment-card__table .ant-table-thead > tr > th {
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px;
}

.nafasi-payment-card__table .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px;
    transition: background 0.2s ease;
}

.nafasi-payment-card__table .ant-table-tbody > tr:hover > td {
    background: rgba(0, 0, 0, 0.02);
}

.nafasi-payment-card__cell {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 0.9rem;
    color: #1d1d1f;
}

.nafasi-payment-card__cell--details {
    height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Pagination styles */
.nafasi-payment-card__table .ant-pagination {
    margin: 16px 0;
}

.nafasi-payment-card__table .ant-pagination-item {
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-payment-card__table .ant-pagination-item-active {
    border-color: #0066cc;
}

.nafasi-payment-card__table .ant-pagination-item-active a {
    color: #0066cc;
}

/* Responsive styles */
@media screen and (max-width: 734px) {
    .nafasi-payment-card__table .ant-table-thead > tr > th,
    .nafasi-payment-card__table .ant-table-tbody > tr > td {
        padding: 12px;
    }

    .nafasi-payment-card__cell {
        font-size: 0.8rem;
    }
} 