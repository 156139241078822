.certificates-container {
    font-family: 'ramblaRegular', sans-serif;
    background: #f8fafc;
    min-height: 200px;
}

.certificates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 0;
}

.certificate-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.certificate-card:hover {
    transform: translateY(-2px);
}

.certificate-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.certificate-icon {
    width: 32px;
    height: 32px;
    background: #EFF6FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3B82F6;
}

.certificate-info h4 {
    margin: 0;
    color: #1F2937;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1rem;
}

.certificate-issuer {
    color: #6B7280;
    font-size: 0.85rem;
    display: block;
    margin-top: 2px;
}

.certificate-meta {
    margin: 12px 0;
}

.status-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.date-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #6B7280;
    font-size: 0.85rem;
}

.credential-info {
    color: #6B7280;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.certificate-description {
    color: #4B5563;
    font-size: 0.9rem;
    margin: 8px 0;
    line-height: 1.5;
}

.view-link {
    display: inline-block;
    color: #2563EB;
    font-size: 0.9rem;
    text-decoration: none;
}

.view-link:hover {
    text-decoration: underline;
}

.section-container {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.section-header h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    color: #056899;
    font-family: 'ramblaBold', sans-serif;
    font-size: 1.1rem;
}

.section-icon {
    color: #ecb81f;
}

.section-summary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.total-count {
    background: #f0f9ff;
    color: #056899;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: 'ramblaBold', sans-serif;
}

.certificates-timeline {
    position: relative;
    padding: 20px 0;
}

@media (max-width: 768px) {
    .section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .section-summary {
        width: 100%;
        justify-content: flex-start;
    }

    .certificates-grid {
        grid-template-columns: 1fr;
    }
}

.certificate-dates {
    margin: 12px 0;
}

.date-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6B7280;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.date-icon {
    color: #3B82F6;
    width: 16px;
}

.expiry-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.85rem;
}

.expiry-icon {
    font-size: 0.8rem;
}

.credential-id {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6B7280;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.credential-icon {
    color: #3B82F6;
    width: 16px;
}

.credential-link {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: #2563EB;
    font-size: 0.9rem;
    text-decoration: none;
    margin-bottom: 12px;
}

.credential-link:hover {
    text-decoration: underline;
}

.certificate-details {
    padding-top: 12px;
    border-top: 1px solid #E5E7EB;
}

.certificate-meta {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 4px;
}

.meta-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6B7280;
    font-size: 0.9rem;
}

.meta-icon {
    color: #3B82F6;
    width: 16px;
}

.view-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: #2563EB;
    font-size: 0.9rem;
    text-decoration: none;
    padding: 8px 16px;
    background: #EFF6FF;
    border-radius: 6px;
    transition: background-color 0.2s;
    align-self: flex-start;
}

.view-link:hover {
    background: #DBEAFE;
    text-decoration: none;
} 