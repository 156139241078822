.disclosure-container {
    min-height: 100vh;
    background-color: #fbfbfd;
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.disclosure-intro-container {
    max-width: 980px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

.disclosure-logo {
    margin-bottom: 3.5rem;
    text-align: center;
}

.disclosure-logo img {
    max-width: 120px;
    height: auto;
    opacity: 0.8;
}

.disclosure-header {
    text-align: center;
    margin-bottom: 2.5rem;
}

.disclosure-header h4 {
    color: #1d1d1f;
    font-size: 2.5rem;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: -0.003em;
    margin: 0;
}

.disclosure-content {
    max-width: 700px;
    margin: 0 auto;
    text-align: left;
}

.disclosure-content h5 {
    color: #86868b;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    font-family: "ramblaRegular",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, 
                 Helvetica, Arial, sans-serif, "Apple Color Emoji";
    margin: 0;
    letter-spacing: 0.004em;
}

.disclosure-content a {
    color: #0066cc;
    text-decoration: none;
    transition: all 0.2s ease;
}

.disclosure-content a:hover {
    text-decoration: underline;
}

/* Animation */
.disclosure-header, .disclosure-content {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease forwards;
}

.disclosure-content {
    animation-delay: 0.2s;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
    .disclosure-container {
        background-color: #000000;
    }

    .disclosure-header h4 {
        color: #f5f5f7;
    }

    .disclosure-content h5 {
        color: #a1a1a6;
    }

    .disclosure-content a {
        color: #2997ff;
    }
}

@media (max-width: 768px) {
    .disclosure-intro-container {
        padding: 2rem 1.5rem;
    }
    
    .disclosure-header h4 {
        font-size: 2rem;
    }
    
    .disclosure-content h5 {
        font-size: 1rem;
    }
} 