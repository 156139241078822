.nafasi-blogs__container {
    width: 100%;
    min-height: 100vh;
    padding: 0.5rem;
    background-color: #f5f5f7;
}

.nafasi-blogs {
    max-width: 1200px;
    margin: 0 auto;
}

.nafasi-blogs__header-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.nafasi-blogs__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-blogs__icon {
    color: #1890ff;
}

.nafasi-blogs__title {
    margin: 0 !important;
    font-family: ramblaBold, sans-serif;
}

.nafasi-blogs__content {
    width: 100%;
}

.nafasi-blogs__loading {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 8px;
}

.nafasi-blogs__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 8px;
}

.nafasi-blogs__card {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.nafasi-blogs__card:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.nafasi-blogs__card-content {
    padding: 12px;
}

.nafasi-blogs__card-title {
    margin: 0 0 4px 0 !important;
    font-family: ramblaRegular, sans-serif;
    color: #1890ff;
    font-size: 16px !important;
    line-height: 1.4 !important;
}

.nafasi-blogs__card-date {
    display: block;
    margin-bottom: 8px;
    font-family: ramblaRegular, sans-serif;
    font-size: 12px;
}

.nafasi-blogs__card-description {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    font-family: ramblaRegular, sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
}

.nafasi-blogs__action-icon {
    color: #1890ff !important;
    font-size: 14px;
    transition: all 0.3s ease;
}

.nafasi-blogs__action-icon:hover {
    transform: scale(1.1);
}

.nafasi-blogs__action-icon--delete {
    color: #ff4d4f !important;
}

.nafasi-blogs__empty {
    text-align: center;
    padding: 32px;
}

.nafasi-blogs__empty-text {
    font-family: ramblaRegular, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .nafasi-blogs__container {
        padding: 12px;
    }

    .nafasi-blogs__grid {
        gap: 12px;
        padding: 6px;
    }

    .nafasi-blogs__card-content {
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .nafasi-blogs__container {
        padding: 8px;
    }

    .nafasi-blogs__grid {
        gap: 8px;
        padding: 4px;
    }

    .nafasi-blogs__card-content {
        padding: 8px;
    }
} 