.recruiter-detail-view {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 1000;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    overflow-y: auto;
    padding: 0;
    box-shadow: -2px 0 28px rgba(0, 0, 0, 0.1);
}

.recruiter-detail-view.visible {
    right: 0;
}

.recruiter-detail-view__header {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(20px);
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.recruiter-detail-view__back {
    font-size: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    color: #1d1d1f;
    transition: all 0.2s ease;
    margin-right: 12px;
}

.recruiter-detail-view__back:hover {
    background-color: #f5f5f7;
}

.recruiter-detail-view__header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
    letter-spacing: -0.003em;
}

.recruiter-detail-view__content {
    padding: 12px;
    max-width: 1200px;
    margin: 0 auto;
}

.recruiter-detail-view__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.recruiter-detail-view__posts {
    margin-top: 16px;
}

.recruiter-detail-view__post-card {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 10px;
    border: none;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
}

.recruiter-detail-view__post-card :global(.ant-card-head) {
    border-bottom: none;
    padding: 12px 12px 0;
    min-height: auto;
}

.recruiter-detail-view__post-card :global(.ant-card-head-title) {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
    letter-spacing: -0.003em;
    padding: 6px 0;
}

.recruiter-detail-view__post-card :global(.ant-card-body) {
    padding: 12px;
}

.recruiter-detail-view__stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px;
    margin-bottom: 12px;
}

.recruiter-detail-view__stat-card {
    background: #f5f5f7;
    padding: 8px;
    border-radius: 6px;
    text-align: center;
}

.recruiter-detail-view__stat-value {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 2px;
}

.recruiter-detail-view__stat-label {
    font-size: 11px;
    color: #86868b;
}

.recruiter-detail-view__post-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
    padding: 12px;
    background: #f5f5f7;
    border-radius: 8px;
}

.recruiter-detail-view__post-info-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.recruiter-detail-view__post-info-label {
    font-size: 11px;
    color: #86868b;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
}

.recruiter-detail-view__post-info-value {
    font-size: 13px;
    color: #1d1d1f;
    font-weight: 400;
}

.recruiter-detail-view__description-trigger {
    margin: 8px 0;
}

.recruiter-detail-view__description-trigger :global(.ant-tag) {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    background: #f5f5f7;
    border: none;
    color: #06c;
    transition: all 0.2s ease;
}

.recruiter-detail-view__description-trigger :global(.ant-tag:hover) {
    background: #e5e5e5;
}

.recruiter-detail-view__modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.recruiter-detail-view__modal-tabs {
    margin-top: -16px;
}

.recruiter-detail-view__modal-tabs :global(.ant-tabs-nav) {
    margin-bottom: 12px;
}

.recruiter-detail-view__description-text {
    font-size: 14px;
    line-height: 1.5;
    color: #1d1d1f;
    white-space: pre-wrap;
}

.recruiter-detail-view__pdf-viewer {
    width: 100%;
    height: 600px;
    border: none;
    border-radius: 4px;
}

.recruiter-detail-view__candidates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0;
}

.recruiter-detail-view__candidates-title {
    font-size: 13px;
    font-weight: 600;
    color: #1d1d1f;
    display: flex;
    align-items: center;
    gap: 6px;
}

.recruiter-detail-view__candidates-summary {
    display: flex;
    gap: 4px;
}

.recruiter-detail-view__candidates-summary :global(.ant-tag) {
    margin: 0;
    font-size: 11px;
    line-height: 1.4;
    padding: 1px 6px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.recruiter-detail-view__candidates-list {
    margin-top: 8px;
}

.recruiter-detail-view__candidate-item {
    padding: 8px !important;
    border-radius: 6px;
    background: #f5f5f7;
    margin-bottom: 6px;
    transition: all 0.2s ease;
}

.recruiter-detail-view__candidate-item:hover {
    background: #ebebeb;
}

.recruiter-detail-view__candidate-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.recruiter-detail-view__candidate-name {
    font-size: 13px;
    font-weight: 500;
    color: #1d1d1f;
}

@media (min-width: 768px) {
    .recruiter-detail-view {
        width: 85%;
        max-width: 800px;
    }

    .recruiter-detail-view__content {
        padding: 16px;
    }

    .recruiter-detail-view__post-card :global(.ant-card-head),
    .recruiter-detail-view__post-card :global(.ant-card-body) {
        padding: 16px;
    }
}

@media (min-width: 1024px) {
    .recruiter-detail-view {
        max-width: 900px;
    }
} 