.nafasi-results-container {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    font-family: 'ramblaRegular';
}

/* Table Styles */
.nafasi-results-container .ant-table {
    width: 100%;
    font-family: 'ramblaRegular';
}

.nafasi-results-container .ant-table-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}

.nafasi-results-container .ant-table-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.nafasi-results-container .ant-table-content {
    width: 100%;
    margin: 0;
    padding: 0;
}

/* Candidate Card Styles */
.nafasi-candidate-card {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nafasi-candidate-info {
    flex: 1;
    min-width: 0;
}

.nafasi-candidate-name {
    font-family: 'ramblaBold';
    font-size: 14px;
    color: #1d1d1f;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    transform: translateY(-1px);
}

.nafasi-candidate-details {
    font-size: 12px;
    color: #86868b;
    font-family: 'ramblaRegular';
    line-height: 1;
    transform: translateY(-1px);
}

.nafasi-candidate-highlight {
    font-size: 11px;
    color: #0066cc;
    margin-top: 2px;
    line-height: 1;
    font-family: 'ramblaRegular';
    transform: translateY(-1px);
}

/* Truncated Text Styles */
.nafasi-truncated-text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'ramblaRegular';
    font-size: 14px;
    color: #1d1d1f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
}

.nafasi-truncated-text span {
    transform: translateY(-1px);
}

.nafasi-truncated-text svg,
.nafasi-truncated-text .anticon {
    flex-shrink: 0;
}

.nafasi-location-icon {
    color: #86868b;
    flex-shrink: 0;
}

/* Stats Badge Styles */
.nafasi-stats-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 12px;
    font-family: 'ramblaBold';
    font-size: 12px;
    background: #f5f5f7;
    color: #86868b;
    line-height: 1;
    transform: translateY(-1px);
}

.nafasi-stats-badge.high {
    background: rgba(52, 199, 89, 0.1);
    color: #34c759;
}

.nafasi-stats-badge.medium {
    background: rgba(255, 149, 0, 0.1);
    color: #ff9500;
}

/* Action Buttons Styles */
.nafasi-action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
}

.nafasi-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: none;
    background: transparent;
    color: #0066cc;
    cursor: pointer;
    transition: all 0.2s ease;
}

.nafasi-action-button svg {
    flex-shrink: 0;
}

.nafasi-action-button:hover {
    background: rgba(0, 102, 204, 0.1);
}

.nafasi-action-button.danger {
    color: #ff3b30;
}

.nafasi-action-button.danger:hover {
    background: rgba(255, 59, 48, 0.1);
}

/* Modal Styles */
.nafasi-modal-content {
    padding: 24px 0;
}

.nafasi-modal-actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

/* Table Header and Row Styles */
.nafasi-results-container .ant-table-thead > tr > th {
    background: #f5f5f7 !important;
    border-bottom: none !important;
    color: #1d1d1f !important;
    font-family: 'ramblaBold' !important;
    padding: 16px 24px !important;
    font-size: 14px !important;
    line-height: 1 !important;
}

.nafasi-results-container .ant-table-thead > tr > th .ant-table-column-title {
    transform: translateY(-1px);
    display: inline-block;
    line-height: 1;
}

.nafasi-results-container .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f5f5f7 !important;
    padding: 16px 24px !important;
    font-size: 14px !important;
    font-family: 'ramblaRegular' !important;
    line-height: 1 !important;
}

.nafasi-results-container .ant-table-tbody > tr > td span {
    transform: translateY(-1px);
    display: inline-block;
    line-height: 1;
}

.nafasi-results-container .ant-table-tbody > tr:hover > td {
    background: rgba(0, 102, 204, 0.04) !important;
}

/* Pagination Styles */
.nafasi-results-container .ant-pagination {
    margin: 16px 24px !important;
    padding: 16px 0 !important;
    border-top: 1px solid #f5f5f7 !important;
    font-family: 'ramblaRegular' !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nafasi-results-container .ant-pagination-total-text {
    margin-right: auto;
    transform: translateY(-1px);
    line-height: 1;
}

.nafasi-results-container .ant-pagination-item {
    border-radius: 8px !important;
    font-family: 'ramblaBold' !important;
    line-height: 1 !important;
    margin: 0 4px !important;
    min-width: 32px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.nafasi-results-container .ant-pagination-item a {
    transform: translateY(-1px);
    display: inline-block;
    line-height: 1;
    padding: 0;
}

.nafasi-results-container .ant-pagination-prev,
.nafasi-results-container .ant-pagination-next {
    min-width: 32px !important;
    height: 32px !important;
    line-height: 1 !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.nafasi-results-container .ant-pagination-prev button,
.nafasi-results-container .ant-pagination-next button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.nafasi-results-container .ant-pagination-item-active {
    background: #0066cc !important;
    border-color: #0066cc !important;
}

.nafasi-results-container .ant-pagination-item-active a {
    color: #ffffff !important;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .nafasi-results-container .ant-table-thead > tr > th,
    .nafasi-results-container .ant-table-tbody > tr > td {
        padding: 12px 16px !important;
    }

    .nafasi-results-container .ant-pagination {
        margin: 12px 16px !important;
    }
}

@media screen and (max-width: 768px) {
    .nafasi-results-container {
        border-radius: 0;
        box-shadow: none;
    }

    .nafasi-results-container .ant-table-thead > tr > th,
    .nafasi-results-container .ant-table-tbody > tr > td {
        padding: 12px !important;
        font-size: 13px !important;
    }

    .nafasi-results-container .ant-pagination {
        margin: 12px !important;
        padding: 12px 0 !important;
    }
} 