.nafasi-recruiter-modal__trigger {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #06c;
    font-size: 16px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nafasi-recruiter-modal__trigger:hover {
    color: #0055b3;
    transform: scale(1.1);
}

.nafasi-recruiter-modal__container {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-recruiter-modal__container :global(.ant-modal-content) {
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-recruiter-modal__container :global(.ant-modal-header) {
    padding: 16px 24px;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f7;
    margin-bottom: 0;
}

.nafasi-recruiter-modal__header {
    margin: -6px 0;
}

.nafasi-recruiter-modal__title {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.nafasi-recruiter-modal__location {
    color: #86868b;
    font-size: 14px;
    font-weight: normal;
    margin-left: 8px;
}

.nafasi-recruiter-modal__status-row {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.nafasi-recruiter-modal__badge {
    font-size: 12px;
}

.nafasi-recruiter-modal__badge :global(.ant-badge-status-dot) {
    width: 8px;
    height: 8px;
}

.nafasi-recruiter-modal__content {
    padding: 16px 24px;
}

.nafasi-recruiter-modal__section {
    margin-bottom: 24px;
}

.nafasi-recruiter-modal__section:last-child {
    margin-bottom: 0;
}

.nafasi-recruiter-modal__section-title {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 12px;
}

.nafasi-recruiter-modal__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.nafasi-recruiter-modal__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.nafasi-recruiter-modal__label {
    font-size: 12px;
    color: #86868b;
    font-family: 'ramblaBold', sans-serif;
}

.nafasi-recruiter-modal__value {
    font-size: 14px;
    color: #1d1d1f;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-recruiter-modal__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid #f0f0f0;
}

.nafasi-recruiter-modal__footer p {
    margin: 0;
    font-weight: 500;
    color: #262626;
}

.nafasi-recruiter-modal__switch {
    min-width: 44px;
    margin-top: 10px;
}

/* Style the switch colors when checked/unchecked */
.nafasi-recruiter-modal__switch.ant-switch-checked {
    background-color: #52c41a;
}

.nafasi-recruiter-modal__switch.ant-switch:not(.ant-switch-checked) {
    background-color: #ff4d4f;
}

/* Add hover effects */
.nafasi-recruiter-modal__switch:hover {
    opacity: 0.85;
}

.nafasi-recruiter-modal__message {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
    font-size: 14px;
}

@media (max-width: 576px) {
    .nafasi-recruiter-modal__grid {
        grid-template-columns: 1fr;
    }

    .nafasi-recruiter-modal__content {
        padding: 16px;
    }

    .nafasi-recruiter-modal__status-row {
        gap: 8px;
    }
} 