.nafasi-manage-notifications {
    position: relative;
}

.nafasi-manage-notifications__add-button {
    margin: 0 0 20px;
}

.nafasi-manage-notifications__add-button button {
    background: #0071e3 !important;
    border: none !important;
    color: #fff !important;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-size: 13px !important;
    line-height: 1.42859 !important;
    font-weight: 400 !important;
    letter-spacing: -.016em !important;
    padding: 6px 16px !important;
    height: 32px !important;
    border-radius: 980px !important;
    transition: all 0.2s ease !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08) !important;
}

.nafasi-manage-notifications__add-button button:hover {
    background: #0077ed !important;
    transform: translateY(-1px);
}

.nafasi-manage-notifications__add-button button:active {
    transform: translateY(0);
}

/* Modal Styles */
.nafasi-manage-notifications .ant-modal-content {
    border-radius: 12px;
    padding: 0;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    max-width: 500px;
    margin: 0 auto;
}

.nafasi-manage-notifications .ant-modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 12px 12px 0 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.nafasi-manage-notifications .ant-modal-title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 17px;
    line-height: 1.2;
    color: #1d1d1f;
    margin: 0;
}

.nafasi-manage-notifications .ant-modal-body {
    padding: 20px;
    background: #fff;
}

.nafasi-manage-notifications__form {
    display: grid;
    gap: 16px;
}

.nafasi-manage-notifications__form-title {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 15px;
    line-height: 1.23536;
    color: #1d1d1f;
    margin: 0 0 12px;
}

.nafasi-manage-notifications__form-group {
    display: grid;
    gap: 12px;
    margin-bottom: 8px;
}

.nafasi-manage-notifications__form-group .ant-form-item {
    margin-bottom: 0 !important;
}

.nafasi-manage-notifications__form-group .ant-form-item-label {
    padding: 0 0 2px !important;
}

.nafasi-manage-notifications__form-group .ant-form-item-label > label {
    font-family: 'ramblaBold', -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-size: 12px !important;
    color: #86868b !important;
    height: auto !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
}

.nafasi-manage-notifications__form-group .ant-input,
.nafasi-manage-notifications__form-group .ant-input-textarea textarea,
.nafasi-manage-notifications__form-group .ant-picker {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 6px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    transition: all 0.2s ease !important;
    background: #f5f5f7 !important;
    box-shadow: none !important;
}

.nafasi-manage-notifications__form-group .ant-input:hover,
.nafasi-manage-notifications__form-group .ant-input-textarea textarea:hover,
.nafasi-manage-notifications__form-group .ant-picker:hover {
    border-color: rgba(0, 0, 0, 0.2) !important;
    background: #fff !important;
}

.nafasi-manage-notifications__form-group .ant-input:focus,
.nafasi-manage-notifications__form-group .ant-input-textarea textarea:focus,
.nafasi-manage-notifications__form-group .ant-picker-focused {
    border-color: #0071e3 !important;
    box-shadow: 0 0 0 2px rgba(0, 113, 227, 0.1) !important;
    background: #fff !important;
}

.nafasi-manage-notifications__form-group .ant-picker-suffix {
    color: #86868b !important;
}

.nafasi-manage-notifications__switch-group {
    background: #f5f5f7;
    border-radius: 8px;
    padding: 12px;
    margin: 4px 0;
}

.nafasi-manage-notifications__switch-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
}

.nafasi-manage-notifications__switch-container + .nafasi-manage-notifications__switch-container {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-manage-notifications__switch-label {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
    color: #1d1d1f;
}

/* Switch Styles */
.nafasi-manage-notifications .ant-switch {
    background: rgba(0, 0, 0, 0.1);
    min-width: 36px;
    height: 20px;
}

.nafasi-manage-notifications .ant-switch-checked {
    background: #0071e3;
}

.nafasi-manage-notifications .ant-switch-handle {
    width: 16px;
    height: 16px;
    top: 2px;
}

/* Save Button */
.nafasi-manage-notifications__save-button {
    margin-top: 20px;
    text-align: right;
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.nafasi-manage-notifications__save-button button {
    background: #0071e3 !important;
    border: none !important;
    color: #fff !important;
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-size: 13px !important;
    line-height: 1.42859 !important;
    font-weight: 400 !important;
    letter-spacing: -.016em !important;
    padding: 6px 16px !important;
    height: 32px !important;
    border-radius: 980px !important;
    transition: all 0.2s ease !important;
}

.nafasi-manage-notifications__save-button button:hover {
    background: #0077ed !important;
    transform: translateY(-1px);
}

.nafasi-manage-notifications__save-button button:active {
    transform: translateY(0);
}

/* Loading State */
.nafasi-manage-notifications__loading {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

/* Messages */
.nafasi-manage-notifications .ant-message {
    font-family: 'ramblaRegular', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
}

/* Responsive Design */
@media (max-width: 734px) {
    .nafasi-manage-notifications .ant-modal-content {
        border-radius: 0;
        margin: 0;
    }
    
    .nafasi-manage-notifications .ant-modal-header {
        border-radius: 0;
        padding: 14px 16px;
    }
    
    .nafasi-manage-notifications .ant-modal-body {
        padding: 16px;
    }
    
    .nafasi-manage-notifications__form {
        gap: 16px;
    }
} 