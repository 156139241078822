/* Import Rambla font */
@import url('https://fonts.googleapis.com/css2?family=Rambla:wght@400;700&display=swap');

.nafasi-subscription {
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__header {
    text-align: center;
    margin-bottom: 1rem;
}

.nafasi-subscription__title {
    font-size: 2rem;
    font-weight: 700;
    color: #1a1a1a;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__subtitle {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__steps {
    max-width: 800px;
    margin: 0 auto 3rem;
    padding: 0 1rem;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-subscription__content {
    max-width: 1400px;
    margin: 0 auto;
    min-height: 400px;
    font-family: 'ramblaRegular', sans-serif;
}

/* Package Grid */
.nafasi-subscription__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0.5rem;
}

/* Step Content */
.nafasi-subscription__step-content {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border-radius: 16px;
    padding: 2rem;
    border: 1px solid #eaeaea;
}

.nafasi-subscription__selected-package {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.nafasi-subscription__selected-package-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nafasi-subscription__selected-package-header img {
    width: 48px;
    height: 48px;
    object-fit: contain;
}

.nafasi-subscription__selected-package-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    color: #1a1a1a;
}

.nafasi-subscription__selected-package-header p {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1890ff;
    margin: 0.25rem 0 0;
}

.nafasi-subscription__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid #eaeaea;
}

.nafasi-subscription__payment-info {
    text-align: center;
    margin-bottom: 2rem;
}

.nafasi-subscription__payment-info h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1rem;
}

.nafasi-subscription__payment-info p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
}

/* Card styles */
.nafasi-subscription__card {
    width: 100%;
    background: #fff;
    border-radius: 16px;
    padding: 1.5rem;
    position: relative;
    transition: all 0.3s ease;
    border: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.nafasi-subscription__card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.nafasi-subscription__card--recommended {
    border: 2px solid #1890ff;
    box-shadow: 0 8px 24px rgba(24, 144, 255, 0.15);
}

.nafasi-subscription__card-badge {
    position: absolute;
    top: -12px;
    right: 24px;
    background: #1890ff;
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
}

.nafasi-subscription__card-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nafasi-subscription__card-icon {
    width: 48px;
    height: 48px;
    object-fit: contain;
}

.nafasi-subscription__card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__card-pricing {
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #eaeaea;
}

.nafasi-subscription__card-price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
}

.nafasi-subscription__card-currency {
    font-size: 1.2rem;
    color: #1a1a1a;
}

.nafasi-subscription__card-amount {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a1a1a;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__card-period {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
    font-family: 'Rambla', sans-serif;
}

.nafasi-subscription__card-benefits {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.nafasi-subscription__card-benefit {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 0.95rem;
    color: #4a4a4a;
}

.nafasi-subscription__card-benefit-icon {
    color: #52c41a;
    font-size: 1.1rem;
}

.nafasi-subscription__card-button {
    background: #f5f5f5;
    color: #1a1a1a;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: auto;
}

.nafasi-subscription__card-button:hover {
    background: #e8e8e8;
}

.nafasi-subscription__card-button--recommended {
    background: #1890ff;
    color: white;
}

.nafasi-subscription__card-button--recommended:hover {
    background: #096dd9;
}

.nafasi-subscription__form-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    background: #fff;
    border-radius: 16px;
    padding: 2rem;
    border: 1px solid #eaeaea;
}

.nafasi-subscription__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.nafasi-subscription__form-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.nafasi-subscription__form-title-icon {
    font-size: 1.5rem;
}

.nafasi-subscription__form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.nafasi-subscription__card-invoice-label {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: 'ramblaBold', sans-serif;
    color: #4a4a4a;
}

.nafasi-subscription__card-invoice-value {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: 'ramblaBold', sans-serif;
    color: #1f65cf;
}

.nafasi-subscription__label {
    font-size: 0.95rem;
    font-weight: 500;
    font-family: 'ramblaBold', sans-serif;
    color: #4a4a4a;
}

.nafasi-subscription__input,
.nafasi-subscription__select {
    width: 100%;
    border-radius: 8px;
}

.nafasi-subscription__input-prefix {
    color: #666;
    margin-right: 4px;
}

.nafasi-subscription__select-option {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.nafasi-subscription__select-option-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.nafasi-subscription__promo {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
}

.nafasi-subscription__promo .ant-input {
    flex: 1;
}

.nafasi-subscription__promo-success {
    color: #52c41a;
    font-size: 0.85rem;
    margin-top: 0.25rem;
    font-family: 'ramblaRegular', sans-serif;
}

/* Remove all the old promo styles that are no longer used */
.nafasi-subscription__promo-input,
.nafasi-subscription__promo-icon,
.nafasi-subscription__promo-icon--success,
.nafasi-subscription__promo-icon--error,
.nafasi-subscription__input--success,
.nafasi-subscription__input--error,
.nafasi-subscription__promo-button,
.nafasi-subscription__promo-button--loading {
    display: none;
}

/* Package-specific styles */
.nafasi-subscription__card.individual {
    background: linear-gradient(to bottom right, #ffffff, #f0f5ff);
}

.nafasi-subscription__card.startup {
    background: linear-gradient(to bottom right, #ffffff, #f6ffed);
}

.nafasi-subscription__card.business {
    background: linear-gradient(to bottom right, #ffffff, #fff7e6);
}

.nafasi-subscription__card.enterprise {
    background: linear-gradient(to bottom right, #ffffff, #fff1f0);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .nafasi-subscription__grid {
        gap: 0.5rem;
        padding: 0;
    }
}

@media (max-width: 992px) {
    .nafasi-subscription__grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .nafasi-subscription__grid {
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: 0 auto;
    }

    .nafasi-subscription__step-content {
        padding: 1.5rem;
    }

    .nafasi-subscription__actions {
        flex-direction: column;
        gap: 1rem;
    }

    .nafasi-subscription__actions button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .nafasi-subscription__grid {
        grid-template-columns: 1fr;
    }
} 