.nafasi-shortlist-wrapper {
    width: 100%;
    max-width: auto;
    margin: 0 auto;
}

.nafasi-shortlist-container {
    width: 100%;
}

.nafasi-shortlist-empty {
    width: 100%;
    padding: 48px 24px;
    background: #f5f5f7;
    border-radius: 16px;
    text-align: center;
}

.nafasi-shortlist-empty-text {
    font-family: 'ramblaRegular';
    color: #86868b;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .nafasi-shortlist-wrapper,
    .nafasi-shortlist-container {
        border-radius: 0;
        width: 100%;
    }
    
    .nafasi-shortlist-empty {
        padding: 24px 16px;
        margin: 0;
        border-radius: 0;
        width: 100%;
    }
} 