.employment-container {
    font-family: 'ramblaRegular', sans-serif;
}

.employment-timeline {
    position: relative;
    padding: 20px 0;
}

.employment-timeline::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #e5e7eb;
}

.employment-item {
    position: relative;
    margin-bottom: 32px;
    padding-left: 48px;
}

.timeline-dot {
    position: absolute;
    left: 11px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #056899;
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #056899;
}

.employment-content {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.employment-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.company-info h3 {
    font-family: 'ramblaBold', sans-serif;
    color: #1f2937;
    margin: 0 0 4px 0;
    font-size: 1.1rem;
}

.company-info h4 {
    color: #4b5563;
    margin: 0;
    font-size: 1rem;
}

.employment-duration {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6b7280;
    font-size: 0.9rem;
}

.duration-icon {
    color: #056899;
}

.current-badge {
    background: #ecfdf5;
    color: #059669;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 500;
}

.employment-details {
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
    color: #6b7280;
    font-size: 0.9rem;
}

.location, .experience-duration {
    display: flex;
    align-items: center;
    gap: 8px;
}

.job-description {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
}

.job-description h5 {
    font-family: 'ramblaBold', sans-serif;
    color: #374151;
    margin: 0 0 8px 0;
    font-size: 0.95rem;
}

.job-description p {
    color: #4b5563;
    font-size: 0.9rem;
    line-height: 1.5;
    margin: 0;
}

.experiences-section {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
}

.experiences-section h5 {
    font-family: 'ramblaBold', sans-serif;
    color: #374151;
    margin: 0 0 12px 0;
    font-size: 0.95rem;
}

.experiences-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
}

.experience-card {
    background: #f9fafb;
    padding: 12px;
    border-radius: 6px;
}

.experience-title {
    color: #4b5563;
    font-size: 0.9rem;
    margin-bottom: 4px;
}

.experience-duration {
    color: #6b7280;
    font-size: 0.8rem;
}

.no-data-message {
    text-align: center;
    padding: 48px 0;
    color: #6b7280;
}

.no-data-icon {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #d1d5db;
}

.current-status {
    display: inline-block;
    background: #10B981;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-top: 8px;
}

.positions-section {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
}

.positions-section h5 {
    font-family: 'ramblaBold', sans-serif;
    color: #374151;
    margin: 0 0 12px 0;
    font-size: 0.95rem;
}

.positions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
}

.position-card {
    background: #f8fafc;
    padding: 16px;
    border-radius: 8px;
    border-left: 3px solid #056899;
}

.position-title {
    color: #1f2937;
    font-size: 0.95rem;
    font-family: 'ramblaBold', sans-serif;
    margin-bottom: 8px;
}

.position-duration {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6b7280;
    font-size: 0.85rem;
    margin-bottom: 4px;
}

.duration-icon-sm {
    font-size: 0.8rem;
    color: #056899;
}

.position-time {
    color: #6b7280;
    font-size: 0.85rem;
    padding-left: 20px;
}

@media (max-width: 768px) {
    .employment-header {
        flex-direction: column;
        gap: 12px;
    }

    .employment-details {
        flex-direction: column;
        gap: 8px;
    }

    .experiences-grid {
        grid-template-columns: 1fr;
    }

    .positions-grid {
        grid-template-columns: 1fr;
    }
}