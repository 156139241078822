.nafasi-feedback-modal__trigger {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #06c;
    font-size: 16px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nafasi-feedback-modal__trigger:hover {
    color: #0055b3;
    transform: scale(1.1);
}

.nafasi-feedback-modal__container {
    font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", sans-serif;
}

.nafasi-feedback-modal__container :global(.ant-modal-content) {
    border-radius: 12px;
    overflow: hidden;
}

.nafasi-feedback-modal__container :global(.ant-modal-header) {
    padding: 16px 24px;
    background-color: #fff;
    border-bottom: 1px solid #f5f5f7;
    margin-bottom: 0;
}

.nafasi-feedback-modal__container :global(.ant-modal-title) {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
}

.nafasi-feedback-modal__container :global(.ant-modal-body) {
    padding: 24px;
}

.nafasi-feedback-modal__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.nafasi-feedback-modal__section {
    background: #f5f5f7;
    border-radius: 12px;
    padding: 16px;
    transition: all 0.2s ease;
}

.nafasi-feedback-modal__section:hover {
    background: #f0f0f0;
}

.nafasi-feedback-modal__section--full {
    grid-column: 1 / -1;
}

.nafasi-feedback-modal__label {
    font-family: 'ramblaBold', sans-serif;
    font-size: 13px;
    color: #86868b;
    margin-bottom: 4px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.nafasi-feedback-modal__value {
    font-family: 'ramblaRegular', sans-serif;
    font-size: 15px;
    color: #1d1d1f;
    line-height: 1.4;
    word-break: break-word;
}

.nafasi-feedback-modal__value--consent {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
}

.nafasi-feedback-modal__value--consent.allowed {
    color: #28cd41;
    background: rgba(40, 205, 65, 0.1);
}

.nafasi-feedback-modal__value--consent.denied {
    color: #ff3b30;
    background: rgba(255, 59, 48, 0.1);
}

.nafasi-feedback-modal__value--rating {
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 500;
    display: inline-block;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-modal__value--rating.excellent {
    background-color: #f6ffed;
    color: #52c41a;
    border: 1px solid #b7eb8f;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-modal__value--rating.good {
    background-color: #e6f7ff;
    color: #1890ff;
    border: 1px solid #91d5ff;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-modal__value--rating.neutral {
    background-color: #fff7e6;
    color: #fa8c16;
    border: 1px solid #ffd591;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-modal__value--rating.poor {
    background-color: #fff2e8;
    color: #fa541c;
    border: 1px solid #ffbb96;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

.nafasi-feedback-modal__value--rating.very-poor {
    background-color: #fff1f0;
    color: #f5222d;
    border: 1px solid #ffa39e;
    border-radius: 6px;
    font-family: 'ramblaRegular', sans-serif;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nafasi-feedback-modal__content {
        grid-template-columns: 1fr;
    }
    
    .nafasi-feedback-modal__container :global(.ant-modal-body) {
        padding: 16px;
    }
    
    .nafasi-feedback-modal__section {
        padding: 12px;
    }
} 